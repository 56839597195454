var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var Ao,Co,Do,Eo,Lo,Oo,Qo,Ro,So,To,Vo,Wo,Xo,Yo,$o,ap,bp,cp,dp,ep,gp,hp,op,qp,rp,sp,tp,up,vp,wp,yp,zp,Ap,Bp,Cp,Dp,Ep,Fp,Hp,Ip,Jp,Lp,Mp,Np,Op,Pp,Qp,Rp,Up,Vp,Wp,Xp,Yp,Zp,$p,aq,bq,cq,dq,fq,gq,hq,iq,kq,mq,nq,oq,pq,rq,uq,vq,yq,zq,Aq,Bq,Dq,Eq,Fq,Gq,Iq,Jq,Kq,Lq,Mq,Nq,Oq,Qq,Rq,Sq,Tq,Vq,Xq,Zq,ar,br,cr,er,gr,hr,ir,jr,kr,lr,mr,nr,or,qr,rr,sr,tr,ur,wr,xr,yr,Br,Cr,Dr,Er,Fr,Gr,Hr,Ir,Jr,Lr,Mr,Nr,Or,Pr,Qr,Rr,Sr,Tr,Ur,Vr,Wr,Xr,Yr,Zr,$r,as,bs,cs,ds,fs,hs,is,js,ks,ls,ms,ns,os,ps,qs,rs,ss,ts,us,vs,ws,As,Bs,
Fs,Gs,Hs,Js,Ls,Ms,Ns,Rs,Ss,Ts,Us,Vs,Ws,Xs,Ys,Zs,ct,dt,et,ft,gt,ht,it,mt,nt,ot,pt,qt,rt,st,tt,ut,vt,wt,xt,yt,zt,At,Dt,Et,Ft,Gt,Ht,It,Jt,Kt,Lt,Mt,Ot,Pt,Qt,Rt,St,Tt,Ut,Vt,Wt,Xt,Yt,Zt,$t,au,du,eu,fu,hu,lu,ou,pu,qu,ru,su,uu,vu,yu,zu,Bu,Cu,Du,X,Eu,Gu,Hu,Iu,Ju,Ku,Lu,Mu,Ou,Pu,Qu,Ru,Uu,Wu,Xu,Yu,Zu,$u,av,cv,ev,gv,hv,kv,lv,mv,nv,sv,tv,uv,vv,xv,zv,Av,Cv,Dv,Ev,Gv,Iv,Jv,Kv,Lv,Mv,Nv,Ov,Pv,Qv,Rv,Sv,Tv,Uv,Vv,Xv,Yv,Zv,aw,bw,dw,ew,fw,gw,hw,jw,mw,nw,ow,pw,rw,sw,tw,uw,vw,ww,yw,zw,Aw,Cw,Dw,Ew,Fw,Gw,Hw,Iw,Kw,Lw,Nw,Ow,Rw,
Uw,Vw,Ww,Yw,Zw,$w,ax,bx,cx,dx,ex,fx,ix,kx,mx,nx,ox,rx,sx,tx,ux,xx,yx,zx,Ax,Bx,Ex,Fx,Hx,Ix,Jx,Kx,Lx,Nx,Ox,Px,Qx,Rx,Sx,Tx,Vx,Wx,Xx,Zx,$x,ay,by,cy,dy,ey,fy,iy,jy,ky,ny,oy,py,ry,ty,uy,vy,wy,zy,Ay,By,Cy,Dy,Hy,Iy,Jy,Ky,Ly,Oy,Py,Qy,Ry,Sy,Ty,Uy,Vy,Wy,Yy,Zy,$y;Ao=function(a){return"number"===typeof a};$CLJS.Bo=function(a){return null!=a};
Co=function(a,b){if(null!=a&&null!=a.Cc)a=a.Cc(a,b);else{var c=Co[$CLJS.sa(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Co._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Oa("ISet.-disjoin",a);}return a};Do=function(){};Eo=function(a,b){if(null!=a&&null!=a.Lb)a=a.Lb(a,b);else{var c=Eo[$CLJS.sa(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Eo._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Oa("CoreSpec.checker",a);}return a};
$CLJS.Fo=function(a){return function(){function b(c){if(0<arguments.length)for(var d=0,e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;return a}b.J=0;b.I=function(c){$CLJS.y(c);return a};b.o=function(){return a};return b}()};$CLJS.Go=function(a,b,c){return new $CLJS.Og(a,b,c)};$CLJS.Ho=function(a,b){return $CLJS.Go(a,b,null)};$CLJS.Io=function(a){var b=new $CLJS.Ba;for(a=$CLJS.y(a);;)if(null!=a)b.append($CLJS.q.g($CLJS.A(a))),a=$CLJS.C(a),null!=a&&b.append(", ");else return b.toString()};
$CLJS.Jo=function(a){if($CLJS.dd(a))return 0===(a&1);throw Error(["Argument must be an integer: ",$CLJS.q.g(a)].join(""));};$CLJS.Ko=function(a){return $CLJS.p($CLJS.se($CLJS.xd,a))?$CLJS.ud(function(b,c){return $CLJS.Gd.h($CLJS.p(b)?b:$CLJS.ie,c)},a):null};Lo=function(a,b){var c=new $CLJS.n(null,3,[$CLJS.El,a,$CLJS.Hl,!1,$CLJS.Fl,$CLJS.vh.g($CLJS.ie)],null);return a.h?a.h(b,c):a.call(null,b,c)};
$CLJS.Mo=function(a,b){return $CLJS.qd(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.fd(a,e)?$CLJS.Cg.j(c,d,$CLJS.K.h(a,e)):c},$CLJS.be($CLJS.sh,a,$CLJS.bg(b)),b)};$CLJS.No=function(a,b){return new $CLJS.Ld(null,function(){if(0<a){var c=$CLJS.y(b);return c?$CLJS.Jd($CLJS.A(c),$CLJS.No(a-1,$CLJS.oc(c))):null}return null},null)};
Oo=function(a,b,c){return new $CLJS.Ld(null,function(){var d=$CLJS.y(c);if(d){var e=$CLJS.No(a,d);return a===$CLJS.D(e)?$CLJS.Jd(e,Oo(a,b,$CLJS.Ih(b,d))):null}return null},null)};$CLJS.Po=function(a,b){return new $CLJS.Xm(a,!1,b,null,null,null)};Qo=function(a){return new $CLJS.Hm(a,null,null,null)};Ro=function(a,b,c,d){this.tb=a;this.C=b;this.l=c;this.B=d;this.A=2230716170;this.M=139264};
So=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return So.o(0<b.length?new $CLJS.lc(b.slice(0),0,null):null)};To=function(a,b,c,d,e){this.ab=a;this.cb=b;this.C=c;this.l=d;this.B=e;this.A=2230716170;this.M=139264};Vo=function(a,b,c){return Uo.o($CLJS.G([a,b,$CLJS.Fo(!0),c]))};Wo=function(a,b,c,d){this.ib=a;this.C=b;this.l=c;this.B=d;this.A=2230716170;this.M=139264};
Xo=function(a){if(!(null!=a?a.A&32768||$CLJS.gc===a.Rd||(a.A?0:$CLJS.Ma(Do,a)):$CLJS.Ma(Do,a)))throw Error($CLJS.il("Not an IDeref: %s",$CLJS.G([a])));return new Wo(a,null,null,null)};Yo=function(a,b,c,d){this.U=a;this.C=b;this.l=c;this.B=d;this.A=2230716170;this.M=139264};$CLJS.Zo=function(a){return new Yo(a,null,null,null)};$o=function(a){return $CLJS.Ji.h($CLJS.xl,Lo(function(b,c){return Eo($CLJS.sm(b),c)},a))};
ap=function(a,b){if($CLJS.Zc(b)){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null);switch(c instanceof $CLJS.M?c.T:null){case "optional":return new $CLJS.Xm($CLJS.Zo(d),!0,a,null,null,null);case "rest":return $CLJS.Cm(d,a);default:return $CLJS.Po(b,a)}}else return $CLJS.Po(b,a)};
bp=function(a,b){return $CLJS.Ue($CLJS.Jd($CLJS.Po($CLJS.ym(a),a),function(){return function e(d){return new $CLJS.Ld(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.ad(f)){var g=$CLJS.Rb(f),l=$CLJS.D(g),k=$CLJS.Od(l);a:for(var m=0;;)if(m<l){var r=$CLJS.Pc(g,m),v=$CLJS.I(r,0,null);r=$CLJS.I(r,1,null);v=ap(v,r);k.add(v);m+=1}else{g=!0;break a}return g?$CLJS.Rd($CLJS.Td(k),e($CLJS.Sb(f))):$CLJS.Rd($CLJS.Td(k),null)}g=$CLJS.A(f);k=$CLJS.I(g,0,null);g=$CLJS.I(g,1,null);return $CLJS.Jd(ap(k,g),
e($CLJS.oc(f)))}return null}},null)}(Oo(2,2,b))}()))};cp=function(a,b){var c=$CLJS.Wc(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.Uc(a)?(b=$CLJS.A(b),a=$CLJS.ig(a),a.g?a.g(b):a.call(null,b)):$CLJS.E.h(a,$CLJS.A(b)):c:c};
dp=function(a){return $CLJS.Cm($CLJS.ae(Uo,$CLJS.ud($CLJS.th,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ui(cp,r),m],null));k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,
1,null);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ui(cp,l),f],null),d($CLJS.oc(e)))}return null}},null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.Io($CLJS.xg.h($CLJS.A,a))].join(""))};ep=function(a){return $CLJS.Em(a,$CLJS.y,"Non-empty")};gp=function(a){return $CLJS.y(a)?$CLJS.ae($CLJS.fp,a):!0};hp=function(a){"string"===typeof a?(a=Date.parse(a),a=!$CLJS.E.h(a,NaN)):a=null;return a};
$CLJS.Jl.prototype.Lb=$CLJS.ra(19,function(){var a=this;return function(b){var c=a.Y.g?a.Y.g(b):a.Y.call(null,b);return $CLJS.p(c)?c:b}});
$CLJS.Pl.prototype.Lb=$CLJS.ra(18,function(a,b){var c=this,d=this,e=$CLJS.qd(function(f,g){return $CLJS.Ol(g,b,f)},function(f){return $CLJS.vl($CLJS.ql(d,f,new $CLJS.Jg(function(){var g=$CLJS.ll(f);return c.Va.g?c.Va.g(g):c.Va.call(null,g)}),null))},$CLJS.Hd(c.options));return $CLJS.p(c.Ua)?function(f){var g=c.Y.g?c.Y.g(f):c.Y.call(null,f);if($CLJS.p(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.wl(g))return g;f=$CLJS.p($CLJS.Hl.g(b))?g:f;f=c.Ua.g?c.Ua.g(f):c.Ua.call(null,f);return $CLJS.p(f)?
f:g}:function(f){var g=c.Y.g?c.Y.g(f):c.Y.call(null,f);return $CLJS.p(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.$l.prototype.Lb=$CLJS.ra(17,function(a,b){var c=this,d=$CLJS.p($CLJS.Hl.g(b))?c.ub:function(){return null},e=$CLJS.Wl(c.elements,b,function(f,g){return g});return function(f){var g=c.Y.g?c.Y.g(f):c.Y.call(null,f);if($CLJS.p(g))return g;var l=$CLJS.vh.g($CLJS.Te);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.pb(l);var k=(k=$CLJS.y(g))?k:$CLJS.se($CLJS.wl,l);return $CLJS.p(k)?$CLJS.vl(c.Wa.j?c.Wa.j(f,l,g):c.Wa.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});
$CLJS.R.prototype.Cc=$CLJS.ra(16,function(a,b){return new $CLJS.R(this.K,$CLJS.jb(this.Ab,b),null)});var ip={},jp={},kp={},lp=function lp(a){switch(arguments.length){case 1:return lp.g(arguments[0]);case 2:return lp.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lp.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};lp.g=function(a){return a};lp.h=function(a,b){return null==a?null:Co(a,b)};
lp.o=function(a,b,c){for(;;){if(null==a)return null;a=lp.h(a,b);if($CLJS.p(c))b=$CLJS.A(c),c=$CLJS.C(c);else return a}};lp.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};lp.J=2;
var mp=function mp(a){switch(arguments.length){case 1:return mp.g(arguments[0]);case 2:return mp.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return mp.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};mp.g=function(a){return a};mp.h=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.qd(function(d,e){return function(f,g){return $CLJS.fd(e,g)?f:lp.h(f,g)}}(a,b),a,a)};
mp.o=function(a,b,c){a=$CLJS.wi(function(d){return-$CLJS.D(d)},$CLJS.Gd.o(c,b,$CLJS.G([a])));return $CLJS.qd(mp,$CLJS.A(a),$CLJS.oc(a))};mp.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};mp.J=2;$CLJS.h=Ro.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "vs":return this.tb;default:return $CLJS.K.j(this.l,b,c)}};
$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nn,this.tb],null)],null),this.l))};
$CLJS.h.ia=function(){return new $CLJS.kf(this,1,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nn],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};$CLJS.h.ba=function(){return 1+$CLJS.D(this.l)};$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=2002701468^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.tb,b.tb)&&$CLJS.E.h(this.l,b.l)};
$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.nn,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new Ro(this.tb,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "vs":return!0;default:return $CLJS.fd(this.l,b)}};
$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.nn,b):$CLJS.O.call(null,$CLJS.nn,b))?new Ro(c,this.C,this.l,null):new Ro(this.tb,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.gd($CLJS.nn,this.tb)],null),this.l))};$CLJS.h.aa=function(a,b){return new Ro(this.tb,b,this.l,this.B)};$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};
$CLJS.h.Da=function(){var a=this;return $CLJS.Kl($CLJS.zl(this,function(b){return $CLJS.fd(a.tb,b)},function(b){return new $CLJS.Dd(null,a.tb,new $CLJS.Dd(null,b,null,1,null),2,null)}))};$CLJS.h.Ca=function(){return $CLJS.Jd($CLJS.Kn,this.tb)};So.o=function(a){return new Ro($CLJS.ig(a),null,null,null)};So.J=0;So.I=function(a){return this.o($CLJS.y(a))};$CLJS.h=To.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};
$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "preds-and-schemas":return this.ab;case "error-symbol":return this.cb;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gn,this.ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.go,this.cb],null)],null),this.l))};$CLJS.h.ia=function(){return new $CLJS.kf(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gn,$CLJS.go],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};
$CLJS.h.ba=function(){return 2+$CLJS.D(this.l)};$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=1418435858^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ab,b.ab)&&$CLJS.E.h(this.cb,b.cb)&&$CLJS.E.h(this.l,b.l)};
$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,2,[$CLJS.gn,null,$CLJS.go,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new To(this.ab,this.cb,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.fd(this.l,b)}};
$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.gn,b):$CLJS.O.call(null,$CLJS.gn,b))?new To(c,this.cb,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.go,b):$CLJS.O.call(null,$CLJS.go,b))?new To(this.ab,c,this.C,this.l,null):new To(this.ab,this.cb,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.gd($CLJS.gn,this.ab),new $CLJS.gd($CLJS.go,this.cb)],null),this.l))};
$CLJS.h.aa=function(a,b){return new To(this.ab,this.cb,b,this.l,this.B)};$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};
$CLJS.h.Da=function(){var a=this;return $CLJS.Ql($CLJS.om,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l.add(new $CLJS.n(null,2,[$CLJS.Ll,r,$CLJS.Cl,m],null));k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,
null);return $CLJS.Jd(new $CLJS.n(null,2,[$CLJS.Ll,l,$CLJS.Cl,f],null),d($CLJS.oc(e)))}return null}},null)}(a.ab)}(),function(b){var c=a.cb;c=$CLJS.p(c)?c:$CLJS.E.h(1,$CLJS.D(a.ab))?$CLJS.Fg.g($CLJS.ml($CLJS.A($CLJS.A(a.ab)))):$CLJS.Pn;return new $CLJS.Dd(null,c,new $CLJS.Dd(null,b,null,1,null),2,null)})};
$CLJS.h.Ca=function(){return $CLJS.Jd($CLJS.Cn,$CLJS.th.h($CLJS.Ai(function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fg.g($CLJS.ml(b)),$CLJS.tm(a)],null)},$CLJS.G([this.ab])),$CLJS.p(this.cb)?new $CLJS.P(null,1,5,$CLJS.Q,[this.cb],null):null))};var Uo=function Uo(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Uo.o(0<c.length?new $CLJS.lc(c.slice(0),0,null):null)};
Uo.o=function(a){if(!$CLJS.y(a)||!($CLJS.Jo($CLJS.D(a))||$CLJS.Nc(a)instanceof $CLJS.t))throw Error($CLJS.il("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.D(a)])));return new To($CLJS.Ue(function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null),v=$CLJS.I(m,1,null);m=l;if(!$CLJS.nm(r))throw Error($CLJS.jl(["Conditional predicate ",
$CLJS.q.g(r)," must be a function"].join("")));r=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.h(r,$CLJS.In)?$CLJS.Fo(!0):r,v],null);m.add(r);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);if(!$CLJS.nm(l))throw Error($CLJS.jl(["Conditional predicate ",$CLJS.q.g(l)," must be a function"].join("")));l=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.h(l,$CLJS.In)?$CLJS.Fo(!0):l,f],null);return $CLJS.Jd(l,d($CLJS.oc(e)))}return null}},
null)}(Oo(2,2,a))}()),$CLJS.Jo($CLJS.D(a))?null:$CLJS.Nc(a),null,null,null)};Uo.J=0;Uo.I=function(a){return this.o($CLJS.y(a))};$CLJS.h=Wo.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "derefable":return this.ib;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zn,this.ib],null)],null),this.l))};$CLJS.h.ia=function(){return new $CLJS.kf(this,1,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Zn],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};$CLJS.h.ba=function(){return 1+$CLJS.D(this.l)};
$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=1521498755^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ib,b.ib)&&$CLJS.E.h(this.l,b.l)};$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Zn,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new Wo(this.ib,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};
$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "derefable":return!0;default:return $CLJS.fd(this.l,b)}};$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Zn,b):$CLJS.O.call(null,$CLJS.Zn,b))?new Wo(c,this.C,this.l,null):new Wo(this.ib,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.gd($CLJS.Zn,this.ib)],null),this.l))};$CLJS.h.aa=function(a,b){return new Wo(this.ib,b,this.l,this.B)};
$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};$CLJS.h.Da=function(){return $CLJS.Rl($CLJS.om,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.n(null,1,[$CLJS.Cl,$CLJS.pb(this.ib)],null)],null))};
$CLJS.h.Ca=function(){if(this.ib instanceof $CLJS.u){var a=$CLJS.Sc(this.ib);var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.Sg);b=$CLJS.K.h(b,$CLJS.T);a=$CLJS.Fg.g([$CLJS.q.g(a),"/",$CLJS.q.g(b)].join(""));a=new $CLJS.Dd(null,$CLJS.hh,new $CLJS.Dd(null,a,null,1,null),2,null)}else a=$CLJS.Nn;return new $CLJS.Dd(null,$CLJS.ln,new $CLJS.Dd(null,a,null,1,null),2,null)};$CLJS.h=Yo.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};
$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "schema":return this.U;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cl,this.U],null)],null),this.l))};$CLJS.h.ia=function(){return new $CLJS.kf(this,1,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cl],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};$CLJS.h.ba=function(){return 1+$CLJS.D(this.l)};
$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=-805411239^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.U,b.U)&&$CLJS.E.h(this.l,b.l)};$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Cl,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new Yo(this.U,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};
$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "schema":return!0;default:return $CLJS.fd(this.l,b)}};$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Cl,b):$CLJS.O.call(null,$CLJS.Cl,b))?new Yo(c,this.C,this.l,null):new Yo(this.U,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.gd($CLJS.Cl,this.U)],null),this.l))};$CLJS.h.aa=function(a,b){return new Yo(this.U,b,this.l,this.B)};
$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};$CLJS.h.Da=function(){return $CLJS.Rl($CLJS.om,new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.n(null,2,[$CLJS.Ll,$CLJS.ki,$CLJS.Cl,$CLJS.ym(null)],null),new $CLJS.n(null,1,[$CLJS.Cl,this.U],null)],null))};$CLJS.h.Ca=function(){return new $CLJS.Dd(null,$CLJS.hn,new $CLJS.Dd(null,$CLJS.tm(this.U),null,1,null),2,null)};
$CLJS.fp=function fp(a){switch(arguments.length){case 1:return fp.g(arguments[0]);case 2:return fp.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return fp.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};$CLJS.fp.g=function(){return!0};$CLJS.fp.h=function(a,b){return!$CLJS.E.h(a,b)};
$CLJS.fp.o=function(a,b,c){if($CLJS.E.h(a,b))return!1;a=$CLJS.gg([a,b]);for(b=c;;){var d=$CLJS.A(b);c=$CLJS.C(b);if($CLJS.p(b)){if($CLJS.fd(a,d))return!1;a=$CLJS.Gd.h(a,d);b=c}else return!0}};$CLJS.fp.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};$CLJS.fp.J=2;$CLJS.np=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);op=new $CLJS.t("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);
$CLJS.pp=new $CLJS.M(null,"\x3e\x3d","\x3e\x3d",-623615505);qp=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);rp=new $CLJS.t(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);sp=new $CLJS.M(null,"pulse","pulse",-244494476);tp=new $CLJS.t(null,"ceil","ceil",-184398425,null);up=new $CLJS.M(null,"left-join","left-join",-672831855);vp=new $CLJS.t(null,"!\x3d","!\x3d",-201205829,null);wp=new $CLJS.M(null,"week","week",-1326473278);
$CLJS.xp=new $CLJS.M(null,"expression","expression",202311876);yp=new $CLJS.M("location","zip_code","location/zip_code",1641155222);zp=new $CLJS.t(null,"get-week","get-week",752472178,null);Ap=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);Bp=new $CLJS.t("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Cp=new $CLJS.M(null,"log","log",-1595516004);Dp=new $CLJS.t("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Ep=new $CLJS.M(null,"limit","limit",-1355822363);Fp=new $CLJS.t("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Hp=new $CLJS.t(null,"rtrim","rtrim",979195078,null);Ip=new $CLJS.M(null,"segment-id","segment-id",1810133590);Jp=new $CLJS.M(null,"string","string",-1989541586);$CLJS.Kp=new $CLJS.t("s","cond-pre","s/cond-pre",-923707600,null);Lp=new $CLJS.t(null,"BooleanExpression*","BooleanExpression*",623370113,null);Mp=new $CLJS.M(null,"allowed-for","allowed-for",122724334);
Np=new $CLJS.t("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);Op=new $CLJS.M(null,"date","date",-1463434462);Pp=new $CLJS.t(null,"max","max",1701898075,null);Qp=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);Rp=new $CLJS.t(null,"StringExpression*","StringExpression*",1110382599,null);$CLJS.Sp=new $CLJS.M(null,"median","median",569566131);$CLJS.Tp=new $CLJS.M("date","range","date/range",1647265776);
Up=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);Vp=new $CLJS.M(null,"lat-max","lat-max",841568226);Wp=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);Xp=new $CLJS.M(null,"dashboard","dashboard",-631747508);Yp=new $CLJS.t(null,"median","median",-2084869638,null);Zp=new $CLJS.M(null,"metric","metric",408798077);$p=new $CLJS.M(null,"day","day",-274800446);aq=new $CLJS.t(null,"Field","Field",430385967,null);bq=new $CLJS.M(null,"start","start",-355208981);
cq=new $CLJS.M(null,"boolean","boolean",-1919418404);dq=new $CLJS.t(null,"Aggregation","Aggregation",-955933538,null);$CLJS.eq=new $CLJS.M("date","month-year","date/month-year",1948031290);fq=new $CLJS.t("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);gq=new $CLJS.M(null,"lower","lower",1120320821);hq=new $CLJS.t(null,"Field*","Field*",-2104480026,null);iq=new $CLJS.t(null,"cum-sum","cum-sum",-589533420,null);
$CLJS.jq=new $CLJS.M(null,"template-tags","template-tags",1853115685);kq=new $CLJS.t(null,"coalesce","coalesce",-1999813740,null);$CLJS.lq=new $CLJS.M(null,"target","target",253001721);mq=new $CLJS.t(null,"length","length",-2065447907,null);nq=new $CLJS.t(null,"MBQLQuery","MBQLQuery",-1395590243,null);oq=new $CLJS.t(null,"get-quarter","get-quarter",-1326657176,null);pq=new $CLJS.t(null,"metric","metric",2049329604,null);$CLJS.qq=new $CLJS.M(null,"expression-name","expression-name",-1798450709);
rq=new $CLJS.M(null,"length","length",588987862);$CLJS.sq=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.tq=new $CLJS.M(null,"get-hour","get-hour",622714059);uq=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);vq=new $CLJS.t(null,"get-year","get-year",704520253,null);$CLJS.wq=new $CLJS.M(null,"\x3c\x3d","\x3c\x3d",-395636158);$CLJS.xq=new $CLJS.M(null,"or","or",235744169);yq=new $CLJS.M(null,"year","year",335913393);
zq=new $CLJS.t("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);Aq=new $CLJS.t("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);Bq=new $CLJS.t("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);$CLJS.Cq=new $CLJS.M(null,"fingerprint","fingerprint",598613022);Dq=new $CLJS.t(null,"/","/",-1371932971,null);Eq=new $CLJS.t(null,"stddev","stddev",775056588,null);
Fq=new $CLJS.M(null,"inner-join","inner-join",659431740);Gq=new $CLJS.t("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);$CLJS.Hq=new $CLJS.M(null,"hour-of-day","hour-of-day",2086777099);Iq=new $CLJS.t(null,"substring","substring",-1513569493,null);Jq=new $CLJS.M(null,"month","month",-1960248533);Kq=new $CLJS.M("cljs.analyzer","no-resolve","cljs.analyzer/no-resolve",-1872351017);Lq=new $CLJS.t("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);
Mq=new $CLJS.M(null,"lat-min","lat-min",1630784161);Nq=new $CLJS.t("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);Oq=new $CLJS.t(null,"datetime-diff","datetime-diff",-1521323614,null);$CLJS.Pq=new $CLJS.M(null,"between","between",1131099276);Qq=new $CLJS.t("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);Rq=new $CLJS.t(null,"percentile","percentile",1039342775,null);
Sq=new $CLJS.M("string","ends-with","string/ends-with",302681156);Tq=new $CLJS.t("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);$CLJS.Uq=new $CLJS.M(null,"us","us",746429226);Vq=new $CLJS.t(null,"floor","floor",-772394748,null);$CLJS.Wq=new $CLJS.M(null,"clause","clause",1479668060);Xq=new $CLJS.t(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);$CLJS.Yq=new $CLJS.t("s","pred","s/pred",-727014287,null);
Zq=new $CLJS.M(null,"max-results","max-results",-32858165);$CLJS.$q=new $CLJS.M(null,"sum-where","sum-where",2135348428);ar=new $CLJS.t("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);br=new $CLJS.t("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);cr=new $CLJS.M(null,"query-hash","query-hash",-1524484965);$CLJS.dr=new $CLJS.M(null,"parameters","parameters",-1229919748);er=new $CLJS.M(null,"question","question",-1411720117);
$CLJS.fr=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);gr=new $CLJS.t(null,"get-day-of-week","get-day-of-week",-644295017,null);hr=new $CLJS.t("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);ir=new $CLJS.t(null,"sum","sum",1777518341,null);jr=new $CLJS.t(null,"min","min",2085523049,null);kr=new $CLJS.t(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);lr=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);
mr=new $CLJS.M(null,"series-category","series-category",-969875317);nr=new $CLJS.t(null,"time-interval","time-interval",-1949813754,null);or=new $CLJS.t("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);$CLJS.pr=new $CLJS.M(null,"binning","binning",1709835866);qr=new $CLJS.M(null,"sugar","sugar",-73788488);rr=new $CLJS.M(null,"datetime","datetime",494675702);sr=new $CLJS.t(null,"lower","lower",-1534114948,null);tr=new $CLJS.M("location","country","location/country",1666636202);
ur=new $CLJS.t("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);$CLJS.vr=new $CLJS.M(null,"instance","instance",-2121349050);wr=new $CLJS.t(null,"ag:var","ag:var",-1804440286,null);xr=new $CLJS.t(null,"contains","contains",-1977535957,null);yr=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.zr=new $CLJS.M(null,"\x3c","\x3c",-646864291);$CLJS.Ar=new $CLJS.M(null,"datetime-add","datetime-add",209603411);
Br=new $CLJS.t("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);Cr=new $CLJS.t(null,"ExpressionArg","ExpressionArg",1060743736,null);Dr=new $CLJS.M(null,"amount","amount",364489504);Er=new $CLJS.M(null,"card-id","card-id",-1770060179);Fr=new $CLJS.t("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);Gr=new $CLJS.t(null,"FieldOptions","FieldOptions",-1536320891,null);
Hr=new $CLJS.t("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);Ir=new $CLJS.M(null,"none","none",1333468478);Jr=new $CLJS.t("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.Kr=new $CLJS.M(null,"strategy","strategy",-1471631918);Lr=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);Mr=new $CLJS.t("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
Nr=new $CLJS.t(null,"DatetimeExpression","DatetimeExpression",1944354145,null);Or=new $CLJS.t(null,"ArithmeticExpression*","ArithmeticExpression*",353324128,null);Pr=new $CLJS.M(null,"coalesce","coalesce",654622029);Qr=new $CLJS.M(null,"optional","optional",2053951509);Rr=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Sr=new $CLJS.M(null,"variadic","variadic",882626057);Tr=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);
Ur=new $CLJS.t("metabase.mbql.schema","ArithmeticExpression","metabase.mbql.schema/ArithmeticExpression",-2037262177,null);Vr=new $CLJS.M(null,"hour","hour",-555989214);Wr=new $CLJS.t("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);Xr=new $CLJS.t(null,"replace","replace",853943757,null);Yr=new $CLJS.t("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);Zr=new $CLJS.t(null,"time","time",-1268547887,null);
$r=new $CLJS.t("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);as=new $CLJS.t(null,"field","field",338095027,null);bs=new $CLJS.M("string","contains","string/contains",1602423827);cs=new $CLJS.t(null,"EqualityComparable","EqualityComparable",-2076258858,null);ds=new $CLJS.t(null,"segment","segment",675610331,null);$CLJS.es=new $CLJS.M(null,"order-by","order-by",1527318070);fs=new $CLJS.t("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);
$CLJS.gs=new $CLJS.M(null,"filter","filter",-948537934);hs=new $CLJS.t(null,"Filter*","Filter*",-1193205185,null);is=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);js=new $CLJS.t(null,"round","round",-645002441,null);ks=new $CLJS.t("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);ls=new $CLJS.t("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);
ms=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);ns=new $CLJS.t("s","enum","s/enum",-975416934,null);os=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);ps=new $CLJS.t(null,"CaseClauses","CaseClauses",-1749071354,null);qs=new $CLJS.t(null,"\x3e\x3d","\x3e\x3d",1016916022,null);rs=new $CLJS.t("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);ss=new $CLJS.t("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);
ts=new $CLJS.t(null,"case","case",-1510733573,null);us=new $CLJS.t(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);vs=new $CLJS.M(null,"public-question","public-question",629369976);ws=new $CLJS.t(null,"or","or",1876275696,null);As=new $CLJS.t(null,"sum-where","sum-where",-519087341,null);Bs=new $CLJS.t(null,"log","log",45015523,null);$CLJS.Cs=new $CLJS.M(null,"current","current",-1088038603);$CLJS.Ds=new $CLJS.M(null,"aggregation","aggregation",1597476696);
$CLJS.Es=new $CLJS.M(null,"percentile","percentile",-601188752);Fs=new $CLJS.t(null,"regex-match-first","regex-match-first",983231809,null);Gs=new $CLJS.t("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);Hs=new $CLJS.t(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);$CLJS.Is=new $CLJS.M(null,"source-table","source-table",-225307692);Js=new $CLJS.t(null,"\x3c\x3d","\x3c\x3d",1244895369,null);
$CLJS.Ks=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);Ls=new $CLJS.t("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);Ms=new $CLJS.t("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);Ns=new $CLJS.M(null,"internal","internal",-854870097);$CLJS.Os=new $CLJS.M(null,"join-alias","join-alias",1454206794);$CLJS.Ps=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.Qs=new $CLJS.M(null,"and","and",-971899817);
Rs=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);Ss=new $CLJS.t(null,"is-null","is-null",-356519403,null);Ts=new $CLJS.t(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);Us=new $CLJS.t(null,"ends-with","ends-with",1183740516,null);Vs=new $CLJS.t("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
Ws=new $CLJS.t("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);Xs=new $CLJS.t("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);Ys=new $CLJS.M("number","between","number/between",97700581);Zs=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.$s=new $CLJS.M(null,"get-month","get-month",-369374731);$CLJS.at=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);$CLJS.bt=new $CLJS.M(null,"default","default",-1987822328);
ct=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);dt=new $CLJS.M(null,"lon-max","lon-max",1590224717);et=new $CLJS.t(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);ft=new $CLJS.t(null,"CaseOptions","CaseOptions",1989286806,null);gt=new $CLJS.t("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);ht=new $CLJS.t("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);
it=new $CLJS.t("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);$CLJS.jt=new $CLJS.M(null,"*","*",-1294732318);$CLJS.kt=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.lt=new $CLJS.M(null,"not-null","not-null",-1326718535);mt=new $CLJS.t("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);nt=new $CLJS.M(null,"exp","exp",-261706262);ot=new $CLJS.t(null,"dimension","dimension",-2111181571,null);
pt=new $CLJS.t("s","Int","s/Int",-2116888657,null);qt=new $CLJS.M(null,"num-bins","num-bins",847672055);rt=new $CLJS.t(null,"-","-",-471816912,null);st=new $CLJS.M(null,"second","second",-444702010);tt=new $CLJS.t("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);ut=new $CLJS.M(null,"csv-download","csv-download",2141432084);vt=new $CLJS.t(null,"Filter","Filter",-424893332,null);wt=new $CLJS.M(null,"metric-id","metric-id",-686486942);
xt=new $CLJS.t(null,"is-empty","is-empty",600228619,null);yt=new $CLJS.M(null,"json-download","json-download",-971130133);zt=new $CLJS.M(null,"floor","floor",1882041021);At=new $CLJS.t(null,"absolute-datetime","absolute-datetime",1080191062,null);$CLJS.Bt=new $CLJS.M(null,"asc","asc",356854569);$CLJS.Ct=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);Dt=new $CLJS.t(null,"trim","trim",-1880116002,null);Et=new $CLJS.t(null,"datetime-add","datetime-add",1850134938,null);
Ft=new $CLJS.t("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);Gt=new $CLJS.M(null,"millisecond","millisecond",-540123566);Ht=new $CLJS.t("s","recursive","s/recursive",-1935549792,null);It=new $CLJS.t("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);Jt=new $CLJS.t(null,"get-day","get-day",1768100384,null);Kt=new $CLJS.t("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);
Lt=new $CLJS.t(null,"TimeUnit","TimeUnit",1200517789,null);Mt=new $CLJS.t(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);$CLJS.Nt=new $CLJS.M("date","relative","date/relative",25987732);Ot=new $CLJS.M(null,"concat","concat",-2108183992);Pt=new $CLJS.M(null,"condition","condition",1668437652);Qt=new $CLJS.t(null,"upper","upper",1886775433,null);Rt=new $CLJS.M("location","city","location/city",-1746973325);
St=new $CLJS.t(null,"ArithmeticExpression","ArithmeticExpression",-2677674,null);Tt=new $CLJS.t(null,"power","power",702679448,null);Ut=new $CLJS.M("string","\x3d","string/\x3d",983744235);Vt=new $CLJS.t("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);Wt=new $CLJS.M(null,"rtrim","rtrim",-661336449);Xt=new $CLJS.t(null,"not-null","not-null",313812992,null);Yt=new $CLJS.t(null,"BooleanExpression","BooleanExpression",1027887421,null);
Zt=new $CLJS.t("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$t=new $CLJS.M(null,"sqrt","sqrt",-1270051929);au=new $CLJS.t("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.bu=new $CLJS.M(null,"not","not",-595976884);$CLJS.cu=new $CLJS.M(null,"avg","avg",197406200);du=new $CLJS.M(null,"text","text",-1790561697);eu=new $CLJS.M("string","starts-with","string/starts-with",1266861170);fu=new $CLJS.M(null,"minute","minute",-642875969);
$CLJS.gu=new $CLJS.M(null,"distinct","distinct",-1788879121);hu=new $CLJS.t(null,"get-month","get-month",1271156796,null);$CLJS.iu=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.ju=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.ku=new $CLJS.M(null,"category","category",-593092832);lu=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.mu=new $CLJS.M(null,"quarter-of-year","quarter-of-year",-472803491);$CLJS.nu=new $CLJS.M(null,"is-empty","is-empty",-1040302908);
ou=new $CLJS.M(null,"lon-min","lon-min",-787291357);pu=new $CLJS.t("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);qu=new $CLJS.t("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);ru=new $CLJS.t(null,"share","share",1051097594,null);su=new $CLJS.t(null,"OrderComparable","OrderComparable",772072595,null);$CLJS.tu=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);uu=new $CLJS.M(null,"quarter","quarter",-508147616);
vu=new $CLJS.M(null,"rest","rest",-1241696419);$CLJS.wu=new $CLJS.M(null,"var","var",-769682797);$CLJS.xu=new $CLJS.M(null,"share","share",-589433933);yu=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);zu=new $CLJS.t("js","Date","js/Date",946858373,null);$CLJS.Au=new $CLJS.M(null,"ends-with","ends-with",-456791011);Bu=new $CLJS.t("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);Cu=new $CLJS.M(null,"constraints","constraints",422775616);
Du=new $CLJS.t(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);X=new $CLJS.M(null,"clause-name","clause-name",-996419059);Eu=new $CLJS.M("date","series-time","date/series-time",-2006474655);$CLJS.Fu=new $CLJS.M(null,"expressions","expressions",255689909);Gu=new $CLJS.t("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",-1385848876,null);Hu=new $CLJS.t(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);
Iu=new $CLJS.t("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);Ju=new $CLJS.M(null,"items","items",1031954938);Ku=new $CLJS.t(null,"sqrt","sqrt",370479598,null);Lu=new $CLJS.t("metabase.mbql.schema","ArithmeticExpression*","metabase.mbql.schema/ArithmeticExpression*",1582495545,null);Mu=new $CLJS.t("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);$CLJS.Nu=new $CLJS.M(null,"\x3e","\x3e",-555517146);
Ou=new $CLJS.M(null,"bin-width","bin-width",1377922579);Pu=new $CLJS.M(null,"number","number",1570378438);Qu=new $CLJS.t(null,"StringExpression","StringExpression",-233088561,null);Ru=new $CLJS.t(null,"\x3e","\x3e",1085014381,null);$CLJS.Su=new $CLJS.M(null,"\x3d","\x3d",1152933628);$CLJS.Tu=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);Uu=new $CLJS.t(null,"relative-datetime","relative-datetime",-1369266491,null);$CLJS.Vu=new $CLJS.M(null,"source-field","source-field",933829534);
Wu=new $CLJS.M(null,"operator","operator",-1860875338);Xu=new $CLJS.M("location","state","location/state",-114378652);Yu=new $CLJS.M(null,"trim","trim",774319767);Zu=new $CLJS.t("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$u=new $CLJS.t(null,"not-empty","not-empty",2029453590,null);av=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.bv=new $CLJS.M(null,"day-of-month","day-of-month",-1096650288);
cv=new $CLJS.t(null,"and","and",668631710,null);$CLJS.dv=new $CLJS.M("date","all-options","date/all-options",549325958);ev=new $CLJS.M(null,"snippet","snippet",953581994);$CLJS.fv=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);gv=new $CLJS.M(null,"upper","upper",246243906);hv=new $CLJS.t(null,"datetime-subtract","datetime-subtract",1915839224,null);$CLJS.iv=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);$CLJS.jv=new $CLJS.M(null,"+","+",1913524883);
kv=new $CLJS.t("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);lv=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);mv=new $CLJS.t(null,"exp","exp",1378825265,null);nv=new $CLJS.t(null,"ltrim","ltrim",-1000166486,null);$CLJS.ov=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.pv=new $CLJS.M(null,"widget-type","widget-type",1836256899);$CLJS.qv=new $CLJS.M(null,"next","next",-117701485);$CLJS.rv=new $CLJS.M(null,"not-empty","not-empty",388922063);
sv=new $CLJS.t("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);tv=new $CLJS.t("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);uv=new $CLJS.t("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);vv=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.wv=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);xv=new $CLJS.M(null,"card","card",-1430355152);
$CLJS.yv=new $CLJS.M(null,"get-week","get-week",-888059349);zv=new $CLJS.t(null,"asc","asc",1997386096,null);Av=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);$CLJS.Bv=new $CLJS.M(null,"last","last",1105735132);Cv=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);Dv=new $CLJS.t("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);Ev=new $CLJS.M(null,"database_type","database_type",-54700895);
$CLJS.Fv=new $CLJS.M(null,"source-query","source-query",198004422);Gv=new $CLJS.M(null,"unary","unary",-989314568);$CLJS.Hv=new $CLJS.M(null,"is-null","is-null",-1997050930);Iv=new $CLJS.M(null,"slug","slug",2029314850);Jv=new $CLJS.t(null,"template-tag","template-tag",1951372565,null);Kv=new $CLJS.t("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);Lv=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);Mv=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);
Nv=new $CLJS.M(null,"a","a",-2123407586);Ov=new $CLJS.M(null,"b","b",1482224470);Pv=new $CLJS.t(null,"get-second","get-second",-425414791,null);Qv=new $CLJS.t("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Rv=new $CLJS.M(null,"fk-field-id","fk-field-id",1124127357);Sv=new $CLJS.M(null,"y","y",-1757859776);Tv=new $CLJS.M(null,"requires-features","requires-features",-101116256);Uv=new $CLJS.M(null,"x","x",2099068185);Vv=new $CLJS.M(null,"s","s",1705939918);
$CLJS.Wv=new $CLJS.t("s","Str","s/Str",907974338,null);Xv=new $CLJS.M(null,"n","n",562130025);Yv=new $CLJS.M(null,"abs","abs",-246026477);Zv=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.$v=new $CLJS.M(null,"iso","iso",-1366207543);aw=new $CLJS.t(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);bw=new $CLJS.t("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);$CLJS.cw=new $CLJS.M(null,"breakout","breakout",-732419050);
dw=new $CLJS.M(null,"second-clause","second-clause",-461435645);ew=new $CLJS.t("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);fw=new $CLJS.M(null,"middleware","middleware",1462115504);gw=new $CLJS.t(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);hw=new $CLJS.M(null,"more","more",-2058821800);$CLJS.iw=new $CLJS.M(null,"alias","alias",-2039751630);
jw=new $CLJS.t("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);$CLJS.kw=new $CLJS.M(null,"/","/",1282502798);$CLJS.lw=new $CLJS.M(null,"display-name","display-name",694513143);mw=new $CLJS.t("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);nw=new $CLJS.t("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);ow=new $CLJS.M(null,"settings","settings",1556144875);
pw=new $CLJS.t("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);$CLJS.qw=new $CLJS.M(null,"-","-",-2112348439);rw=new $CLJS.t("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);sw=new $CLJS.t("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);tw=new $CLJS.M(null,"full-join","full-join",1305476385);uw=new $CLJS.M(null,"round","round",2009433328);vw=new $CLJS.t(null,"distinct","distinct",-148347594,null);
ww=new $CLJS.t("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.xw=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);yw=new $CLJS.M(null,"replacement","replacement",-1836238839);zw=new $CLJS.t(null,"*","*",345799209,null);Aw=new $CLJS.t("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);$CLJS.Bw=new $CLJS.M(null,"case","case",1143702196);Cw=new $CLJS.t(null,"avg","avg",1837937727,null);
Dw=new $CLJS.t(null,"concat","concat",-467652465,null);Ew=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);Fw=new $CLJS.t(null,"variable","variable",1359185035,null);Gw=new $CLJS.M(null,"declared","declared",92336021);Hw=new $CLJS.t("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);Iw=new $CLJS.t("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);$CLJS.Jw=new $CLJS.M(null,"time-interval","time-interval",704622015);
Kw=new $CLJS.M(null,"clause-form","clause-form",1820463737);Lw=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);$CLJS.Mw=new $CLJS.M(null,"get-second","get-second",-2065946318);Nw=new $CLJS.t(null,"starts-with","starts-with",2007034536,null);Ow=new $CLJS.t("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);$CLJS.Pw=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.Qw=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);Rw=new $CLJS.t(null,"get-hour","get-hour",-2031721710,null);$CLJS.Sw=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.Tw=new $CLJS.M(null,"cum-count","cum-count",-1964431543);Uw=new $CLJS.t("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);Vw=new $CLJS.M(null,"added","added",2057651688);
Ww=new $CLJS.t("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);$CLJS.Xw=new $CLJS.M(null,"contains","contains",676899812);Yw=new $CLJS.M(null,"executed-by","executed-by",-739811161);Zw=new $CLJS.M(null,"segment","segment",-964921196);$w=new $CLJS.t(null,"\x3c","\x3c",993667236,null);ax=new $CLJS.t("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);bx=new $CLJS.M(null,"display_name","display_name",-1494335013);
cx=new $CLJS.M(null,"substring","substring",1140866276);dx=new $CLJS.M(null,"match","match",1220059550);ex=new $CLJS.M(null,"mode","mode",654403691);fx=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.gx=new $CLJS.M(null,"max","max",61366548);$CLJS.hx=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);ix=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);$CLJS.jx=new $CLJS.M(null,"minute-of-hour","minute-of-hour",1903220478);
kx=new $CLJS.M(null,"card-name","card-name",-2035606807);$CLJS.lx=new $CLJS.M(null,"count","count",2139924085);mx=new $CLJS.M(null,"clauses","clauses",1454841241);nx=new $CLJS.t(null,"between","between",-1523336493,null);ox=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.px=new $CLJS.M(null,"field","field",-1302436500);$CLJS.qx=new $CLJS.M(null,"dimension","dimension",543254198);rx=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
sx=new $CLJS.t(null,"ParameterType","ParameterType",-442355043,null);tx=new $CLJS.M(null,"numeric","numeric",-1495594714);ux=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.vx=new $CLJS.M(null,"day-of-week","day-of-week",1639326729);$CLJS.wx=new $CLJS.M(null,"native","native",-613060878);xx=new $CLJS.t("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
yx=new $CLJS.t("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);zx=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);Ax=new $CLJS.M(null,"page","page",849072397);Bx=new $CLJS.t("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);$CLJS.Cx=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.Dx=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
Ex=new $CLJS.t("s","eq","s/eq",1021992833,null);Fx=new $CLJS.M(null,"pred","pred",1927423397);$CLJS.Gx=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);Hx=new $CLJS.t("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Ix=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);Jx=new $CLJS.t(null,"desc","desc",-560950005,null);Kx=new $CLJS.t("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);
Lx=new $CLJS.t(null,"inside","inside",-681932758,null);$CLJS.Mx=new $CLJS.M(null,"context","context",-830191113);Nx=new $CLJS.M(null,"power","power",-937852079);Ox=new $CLJS.t(null,"expression","expression",1842843403,null);Px=new $CLJS.M(null,"binary","binary",-1802232288);Qx=new $CLJS.M(null,"right-join","right-join",-56349359);Rx=new $CLJS.t("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);Sx=new $CLJS.t(null,"get-minute","get-minute",-1186349426,null);
Tx=new $CLJS.t(null,"cum-count","cum-count",-323900016,null);$CLJS.Ux=new $CLJS.M(null,"query","query",-1288509510);Vx=new $CLJS.t("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);Wx=new $CLJS.t("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);Xx=new $CLJS.M("date","series-datetime","date/series-datetime",-1930502777);$CLJS.Yx=new $CLJS.M(null,"year-of-era","year-of-era",682445876);Zx=new $CLJS.M(null,"pattern","pattern",242135423);
$x=new $CLJS.M(null,"required","required",1807647006);ay=new $CLJS.t(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);by=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);cy=new $CLJS.t("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);dy=new $CLJS.t(null,"does-not-contain","does-not-contain",1365584674,null);ey=new $CLJS.t(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);
fy=new $CLJS.t("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);$CLJS.gy=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.hy=new $CLJS.M(null,"joins","joins",1033962699);iy=new $CLJS.t("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);jy=new $CLJS.t(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);ky=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.ly=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.my=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);ny=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);oy=new $CLJS.t(null,"count-where","count-where",2025939247,null);py=new $CLJS.t(null,"abs","abs",1394505050,null);$CLJS.qy=new $CLJS.M(null,"min","min",444991522);ry=new $CLJS.t("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);$CLJS.sy=new $CLJS.M(null,"stddev","stddev",-865474939);
ty=new $CLJS.t("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);uy=new $CLJS.t(null,"+","+",-740910886,null);vy=new $CLJS.t("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);wy=new $CLJS.t("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);$CLJS.xy=new $CLJS.M(null,"fields","fields",-1932066230);$CLJS.yy=new $CLJS.M(null,"base-type","base-type",1167971299);
zy=new $CLJS.M(null,"regex","regex",939488856);Ay=new $CLJS.t(null,"temporal-extract","temporal-extract",-872749364,null);By=new $CLJS.M(null,"snippet-name","snippet-name",819240328);Cy=new $CLJS.M(null,"format-rows?","format-rows?",992129486);Dy=new $CLJS.t("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);$CLJS.Ey=new $CLJS.M(null,"include-current","include-current",-1602371981);$CLJS.Fy=new $CLJS.M("date","single","date/single",1554682003);
$CLJS.Gy=new $CLJS.M(null,"month-of-year","month-of-year",760756492);Hy=new $CLJS.M(null,"lat-field","lat-field",-830652957);Iy=new $CLJS.t("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);Jy=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);Ky=new $CLJS.t(null,"StringFilterOptions","StringFilterOptions",-847398229,null);Ly=new $CLJS.t("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);
$CLJS.My=new $CLJS.M(null,"unit","unit",375175175);$CLJS.Ny=new $CLJS.M(null,"id","id",-1388402092);Oy=new $CLJS.t("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Py=new $CLJS.t("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);Qy=new $CLJS.M(null,"first-clause","first-clause",-20953491);Ry=new $CLJS.M(null,"database","database",1849087575);Sy=new $CLJS.M("date","series-date","date/series-date",-196511793);
Ty=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);Uy=new $CLJS.M(null,"lon-field","lon-field",517872067);Vy=new $CLJS.M(null,"collection","collection",-683361892);Wy=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);$CLJS.Xy=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Yy=new $CLJS.M(null,"template-tag","template-tag",310841038);Zy=new $CLJS.t(null,"Fields","Fields",430617156,null);
$y=new $CLJS.t("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);var az=new $CLJS.R(null,new $CLJS.n(null,12,[uu,null,$p,null,wp,null,$CLJS.bt,null,$CLJS.vx,null,Jq,null,$CLJS.Gy,null,$CLJS.bv,null,yq,null,new $CLJS.M(null,"day-of-year","day-of-year",478600113),null,new $CLJS.M(null,"week-of-year","week-of-year",-910114435),null,$CLJS.mu,null],null),null),bz=new $CLJS.R(null,new $CLJS.n(null,7,[Vr,null,st,null,$CLJS.bt,null,$CLJS.Hq,null,Gt,null,$CLJS.jx,null,fu,null],null),null),cz=$CLJS.Ei.h(az,bz),dz=$CLJS.Cm($CLJS.ae(So,az),"date-bucketing-unit"),ez=$CLJS.Cm($CLJS.ae(So,
bz),"time-bucketing-unit"),fz=$CLJS.Cm($CLJS.ae(So,cz),"datetime-bucketing-unit"),gz=$CLJS.Cm($CLJS.ae(So,new $CLJS.R(null,new $CLJS.n(null,11,[$CLJS.np,null,$CLJS.vx,null,$CLJS.Hq,null,$CLJS.Gy,null,$CLJS.bv,null,$CLJS.Yx,null,$CLJS.hx,null,$CLJS.Dx,null,$CLJS.fv,null,$CLJS.mu,null,$CLJS.jx,null],null),null)),"temporal-extract-units"),hz=$CLJS.Cm($CLJS.ae(So,new $CLJS.R(null,new $CLJS.n(null,7,[$p,null,Vr,null,wp,null,st,null,Jq,null,yq,null,fu,null],null),null)),"datetime-diff-units"),iz=$CLJS.Cm($CLJS.ae(So,
new $CLJS.R(null,new $CLJS.n(null,3,[$CLJS.vr,null,$CLJS.$v,null,$CLJS.Uq,null],null),null)),"extract-week-modes"),jz=$CLJS.Cm($CLJS.ae(So,new $CLJS.R(null,new $CLJS.n(null,8,[uu,null,$p,null,Vr,null,wp,null,$CLJS.bt,null,Jq,null,yq,null,fu,null],null),null)),"relative-datetime-unit"),kz=$CLJS.Em($CLJS.to,hp,"valid ISO-8601 datetime string literal"),lz=$CLJS.Em($CLJS.to,hp,"valid ISO-8601 datetime string literal"),mz=$CLJS.Em($CLJS.to,hp,"valid ISO-8601 time string literal"),nz=bp($CLJS.iv,$CLJS.G(["n",
$CLJS.so($CLJS.G([$CLJS.ym($CLJS.Cs),$CLJS.no])),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,jz],null)])),oz=bp($CLJS.sq,$CLJS.G(["n",$CLJS.no,"unit",jz])),pz=Uo.o($CLJS.G([function(a){return $CLJS.La(cp(Ap,a))},bp(Ap,$CLJS.G(["t",Date,"unit",fz])),function(a){return $CLJS.Mc(a)instanceof Date},bp(Ap,$CLJS.G(["date",Date,"unit",dz])),$CLJS.In,bp(Ap,$CLJS.G(["datetime",Date,"unit",fz]))])),qz=bp($CLJS.Mh,$CLJS.G(["time",Date,"unit",ez])),rz=Uo.o($CLJS.G([$CLJS.ui(cp,Ap),pz,hp,lz,hp,kz,$CLJS.In,$CLJS.so($CLJS.G([Date]))])),
sz=Uo.o($CLJS.G([$CLJS.ui(cp,$CLJS.Mh),qz,hp,mz,$CLJS.In,$CLJS.so($CLJS.G([Date]))])),tz=$CLJS.so($CLJS.G([sz,rz])),uz,vz,wz,xz,yz,Cz,Dz,Ez,Gz,Iz,Jz,Kz,Lz,Mz,Nz,Oz,Sz,Tz,Vz,Wz,Xz,Yz,Zz,$z,aA,bA,cA,dA,eA,fA,gA,hA,iA,jA,kA,lA,mA,nA,oA,pA,qA,rA,sA,Pz,uA,vA,wA,xA,yA,zA,AA,BA,CA,DA,EA,FA,GA,HA,IA,Qz,Hz,LA,MA,NA,OA,PA,QA,RA,SA,TA,UA,VA,WA,XA,YA,ZA,$A,aB,bB,cB,dB,eB,fB,gB,hB,iB,jB,Uz,kB,lB,mB,nB,oB,tA,JA,qB,rB,sB,tB,uB,vB,wB,xB,yB,zB,AB,BB,CB,DB,EB,FB,GB,HB,IB,KB,LB,NB,OB,PB,QB,SB,TB,UB,VB,WB,XB,ZB,$B,bC,
cC,eC,fC,dC,gC,RB,hC,iC,jC,kC,lC,mC,nC,oC,pC,qC,rC,sC;
dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof pz?new $CLJS.u(function(){return pz},$CLJS.J(bw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,Ns,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
At,"metabase/mbql/schema.cljc",69,Ap,1,!0,178,178,$CLJS.B,"Schema for an `:absolute-datetime` clause.",$CLJS.p(pz)?pz.D:null])):null));return $CLJS.p(a)?a:At}(),pz],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof nz?new $CLJS.u(function(){return nz},$CLJS.J(yx,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,
$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Uu,"metabase/mbql/schema.cljc",29,$CLJS.iv,1,160,160,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iv,Xv,$CLJS.U($CLJS.S,$CLJS.U($CLJS.Kp,$CLJS.U(Ex,$CLJS.Cs),pt)),$CLJS.My,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,gw))],null),$CLJS.B,"Schema for a valid relative-datetime clause.",$CLJS.p(nz)?nz.D:null])):null));return $CLJS.p(a)?a:Uu}(),nz],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof qz?new $CLJS.u(function(){return qz},$CLJS.J(ry,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,Ns,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Zr,"metabase/mbql/schema.cljc",
27,$CLJS.Mh,1,!0,211,211,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Mh,$CLJS.Mh,$CLJS.U($CLJS.S,zu),$CLJS.My,$CLJS.U($CLJS.S,Lt)],null),$CLJS.B,"Schema for a valid time clause.",$CLJS.p(qz)?qz.D:null])):null));return $CLJS.p(a)?a:Zr}(),qz],null)]));uz=$CLJS.he([Qo(Ev),$CLJS.Zo($CLJS.to),Qo($CLJS.lj),$CLJS.Zo($CLJS.uo),Qo($CLJS.Xy),$CLJS.Zo($CLJS.vo),Qo($CLJS.My),$CLJS.Zo(fz),Qo($CLJS.T),$CLJS.Zo($CLJS.to),$CLJS.oo,$CLJS.jo]);vz=bp($CLJS.Yn,$CLJS.G(["value",$CLJS.jo,"type-info",$CLJS.Zo(uz)]));
wz=bp($CLJS.xp,$CLJS.G(["expression-name",$CLJS.to,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,$CLJS.Am($CLJS.Xc,"map")],null)]));xz=So.o($CLJS.G([qt,Ou,$CLJS.bt]));
yz=function(a){return $CLJS.Em(a,function(b){var c=$CLJS.je(b);b=$CLJS.K.h(c,$CLJS.Kr);c=$CLJS.K.h(c,qt);return $CLJS.E.h(b,qt)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.Em(a,function(b){var c=$CLJS.je(b);b=$CLJS.K.h(c,$CLJS.Kr);c=$CLJS.K.h(c,Ou);return $CLJS.E.h(b,Ou)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.he([$CLJS.Kr,xz,Qo(qt),$CLJS.wo,Qo(Ou),$CLJS.Em($CLJS.mo,$CLJS.ue($CLJS.qo),"bin width must be \x3e\x3d 0."),
$CLJS.oo,$CLJS.jo])));$CLJS.zz=function zz(a){switch(arguments.length){case 1:return zz.g(arguments[0]);case 2:return zz.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};$CLJS.zz.g=function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.yy);b=$CLJS.K.h(b,$CLJS.Pw);return $CLJS.zz.h(a,b)};
$CLJS.zz.h=function(a,b){a=$CLJS.p($CLJS.p(b)?a:b)?$CLJS.si(a,$CLJS.xk)?az:$CLJS.si(a,$CLJS.Wi)?bz:$CLJS.si(a,$CLJS.Mk)?cz:null:null;return $CLJS.p(a)?$CLJS.fd(a,b):!0};$CLJS.zz.J=2;
var Az=$CLJS.Em($CLJS.Em($CLJS.he([Qo($CLJS.yy),$CLJS.Zo($CLJS.uo),Qo($CLJS.Vu),$CLJS.Zo($CLJS.so($CLJS.G([$CLJS.wo,$CLJS.to]))),Qo($CLJS.Pw),$CLJS.Zo(fz),Qo($CLJS.Os),$CLJS.Zo($CLJS.to),Qo($CLJS.pr),$CLJS.Zo(yz),$CLJS.oo,$CLJS.jo]),$CLJS.zz,"Invalid :temporal-unit for the specified :base-type."),$CLJS.ue($CLJS.Kr),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),Bz=function(a){return $CLJS.Em(a,function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,
1,null);b=$CLJS.I(b,2,null);b=$CLJS.je(b);b=$CLJS.K.h(b,$CLJS.yy);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(bp($CLJS.px,$CLJS.G(["id-or-name",$CLJS.so($CLJS.G([$CLJS.wo,$CLJS.to])),"options",$CLJS.Zo(Xo(new $CLJS.u(function(){return Az},Ly,$CLJS.Yf([$CLJS.Vh,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[!0,$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Gr,"metabase/mbql/schema.cljc",28,1,369,369,$CLJS.B,null,$CLJS.p(Az)?Az.D:null]))))])));$CLJS.Em(Bz,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.dd(a)},"Must be a :field with an integer Field ID.");$CLJS.Em(Bz,function(a){$CLJS.I(a,0,null);return"string"===typeof $CLJS.I(a,1,null)},"Must be a :field with a string Field name.");
Cz=dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof wz?new $CLJS.u(function(){return wz},$CLJS.J(Vx,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Ox,"metabase/mbql/schema.cljc",60,$CLJS.xp,1,304,304,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xp,$CLJS.qq,$CLJS.U($CLJS.S,Wx),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,$CLJS.U($CLJS.Yq,$CLJS.Um,"map")))],null),$CLJS.B,"Schema for a valid expression clause.",$CLJS.p(wz)?wz.D:null])):null));return $CLJS.p(a)?a:Ox}(),wz],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof Bz?new $CLJS.u(function(){return Bz},
$CLJS.J(br,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Vw,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],["0.39.0",$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),as,"metabase/mbql/schema.cljc",51,$CLJS.px,1,415,415,$CLJS.B,"Schema for a `:field` clause.",$CLJS.p(Bz)?Bz.D:null])):null));return $CLJS.p(a)?a:as}(),Bz],null)]));
Dz=Xo(new $CLJS.u(function(){return Cz},Zt,$CLJS.Yf([$CLJS.Vh,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[!0,$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hq,"metabase/mbql/schema.cljc",22,1,439,439,$CLJS.B,null,$CLJS.p(Cz)?Cz.D:null])));
Ez=bp($CLJS.Ds,$CLJS.G(["aggregation-clause-index",$CLJS.no,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,$CLJS.Am($CLJS.Xc,"map")],null)]));$CLJS.Fz=Vo($CLJS.ui(cp,$CLJS.Ds),Ez,Dz);Gz=new $CLJS.R(null,new $CLJS.n(null,11,[gv,null,Zv,null,cx,null,fx,null,Ot,null,Pr,null,$CLJS.Bw,null,gq,null,Yu,null,uq,null,Wt,null],null),null);
Iz=Uo.o($CLJS.G([$CLJS.km,$CLJS.ko,$CLJS.ui(cp,Gz),Xo(new $CLJS.u(function(){return Hz},Fr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Qu,"metabase/mbql/schema.cljc",26,1,482,!0,482,$CLJS.B,null,$CLJS.p(Hz)?Hz.D:null]))),$CLJS.ui(cp,$CLJS.Yn),vz,$CLJS.In,Dz]));
Jz=new $CLJS.R(null,new $CLJS.n(null,16,[ky,null,$CLJS.jt,null,$t,null,$CLJS.qw,null,nt,null,Pr,null,$CLJS.kw,null,uw,null,Nx,null,$CLJS.jv,null,Yv,null,$CLJS.Bw,null,rq,null,$CLJS.Sw,null,Cp,null,zt,null],null),null);Kz=new $CLJS.R(null,new $CLJS.n(null,9,[$CLJS.wq,null,$CLJS.Nu,null,$CLJS.xq,null,$CLJS.bu,null,$CLJS.pp,null,$CLJS.Ct,null,$CLJS.Qs,null,$CLJS.Su,null,$CLJS.zr,null],null),null);
Lz=new $CLJS.R(null,new $CLJS.n(null,17,[$CLJS.qy,null,$CLJS.sy,null,$CLJS.kt,null,$CLJS.Tw,null,$CLJS.$q,null,$CLJS.Gx,null,$CLJS.gu,null,$CLJS.Es,null,$CLJS.xw,null,$CLJS.wu,null,$CLJS.Sp,null,$CLJS.xu,null,$CLJS.gx,null,$CLJS.lx,null,$CLJS.cu,null,Zp,null,$CLJS.ly,null],null),null);Mz=new $CLJS.R(null,new $CLJS.n(null,10,[$CLJS.my,null,$CLJS.Qw,null,$CLJS.Cx,null,$CLJS.tq,null,$CLJS.yv,null,$CLJS.fr,null,$CLJS.Mw,null,$CLJS.$s,null,$CLJS.ov,null,$CLJS.ju,null],null),null);
Nz=new $CLJS.R(null,new $CLJS.n(null,3,[$CLJS.wv,null,$CLJS.jv,null,$CLJS.Ar,null],null),null);Oz=$CLJS.Ei.h(Mz,Nz);
Sz=Uo.o($CLJS.G([Ao,$CLJS.mo,$CLJS.ui(cp,Jz),Xo(new $CLJS.u(function(){return Pz},Ur,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),St,"metabase/mbql/schema.cljc",30,1,525,!0,525,$CLJS.B,null,$CLJS.p(Pz)?Pz.D:null]))),$CLJS.ui(cp,Mz),Xo(new $CLJS.u(function(){return Qz},vy,$CLJS.Yf([$CLJS.Sg,$CLJS.T,
$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Nr,"metabase/mbql/schema.cljc",28,1,527,!0,527,$CLJS.B,null,$CLJS.p(Qz)?Qz.D:null]))),$CLJS.ui(cp,Lz),Xo(new $CLJS.u(function(){return $CLJS.Rz},Np,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dq,"metabase/mbql/schema.cljc",21,1,528,!0,528,$CLJS.B,null,$CLJS.p($CLJS.Rz)?$CLJS.Rz.D:null]))),$CLJS.ui(cp,$CLJS.Yn),vz,$CLJS.In,Dz]));
Tz=Uo.o($CLJS.G([$CLJS.ui(cp,Lz),Xo(new $CLJS.u(function(){return $CLJS.Rz},Np,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dq,"metabase/mbql/schema.cljc",21,1,528,!0,528,$CLJS.B,null,$CLJS.p($CLJS.Rz)?$CLJS.Rz.D:null]))),$CLJS.ui(cp,$CLJS.Yn),vz,$CLJS.ui(cp,Nz),Xo(new $CLJS.u(function(){return Qz},
vy,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Nr,"metabase/mbql/schema.cljc",28,1,527,!0,527,$CLJS.B,null,$CLJS.p(Qz)?Qz.D:null]))),$CLJS.In,$CLJS.so($CLJS.G([rz,Dz]))]));
Vz=Uo.o($CLJS.G([Ao,$CLJS.mo,function(a){return!0===a||!1===a},$CLJS.lo,$CLJS.ui(cp,Kz),Xo(new $CLJS.u(function(){return Uz},Ow,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Yt,"metabase/mbql/schema.cljc",27,1,526,!0,526,$CLJS.B,null,$CLJS.p(Uz)?Uz.D:null]))),$CLJS.ui(cp,Jz),Xo(new $CLJS.u(function(){return Pz},
Ur,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),St,"metabase/mbql/schema.cljc",30,1,525,!0,525,$CLJS.B,null,$CLJS.p(Pz)?Pz.D:null]))),$CLJS.km,$CLJS.ko,$CLJS.ui(cp,Gz),Xo(new $CLJS.u(function(){return Hz},Fr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,
$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Qu,"metabase/mbql/schema.cljc",26,1,482,!0,482,$CLJS.B,null,$CLJS.p(Hz)?Hz.D:null]))),$CLJS.ui(cp,Mz),Xo(new $CLJS.u(function(){return Qz},vy,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Nr,"metabase/mbql/schema.cljc",28,1,527,!0,527,$CLJS.B,null,$CLJS.p(Qz)?Qz.D:null]))),$CLJS.ui(cp,$CLJS.Yn),vz,$CLJS.In,Dz]));Wz=Vo($CLJS.ui(cp,$CLJS.sq),oz,Sz);Xz=bp(Pr,$CLJS.G(["a",Vz,"b",Vz,"more",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Vz],null)]));Yz=bp(cx,$CLJS.G(["s",Iz,"start",Sz,"length",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,Sz],null)]));Zz=bp(rq,$CLJS.G(["s",Iz]));$z=bp(Yu,$CLJS.G(["s",Iz]));aA=bp(Wt,$CLJS.G(["s",Iz]));bA=bp(Zv,$CLJS.G(["s",Iz]));cA=bp(gv,$CLJS.G(["s",Iz]));
dA=bp(gq,$CLJS.G(["s",Iz]));eA=bp(fx,$CLJS.G(["s",Iz,"match",$CLJS.ko,"replacement",$CLJS.ko]));fA=bp(Ot,$CLJS.G(["a",Iz,"b",Iz,"more",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Iz],null)]));gA=bp(uq,$CLJS.G(["s",Iz,"pattern",$CLJS.ko]));hA=bp($CLJS.jv,$CLJS.G(["x",Wz,"y",Wz,"more",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Wz],null)]));iA=bp($CLJS.qw,$CLJS.G(["x",Sz,"y",Wz,"more",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Wz],null)]));jA=bp($CLJS.kw,$CLJS.G(["x",Sz,"y",Sz,"more",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Sz],null)]));
kA=bp($CLJS.jt,$CLJS.G(["x",Sz,"y",Sz,"more",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Sz],null)]));lA=bp(zt,$CLJS.G(["x",Sz]));mA=bp(ky,$CLJS.G(["x",Sz]));nA=bp(uw,$CLJS.G(["x",Sz]));oA=bp(Yv,$CLJS.G(["x",Sz]));pA=bp(Nx,$CLJS.G(["x",Sz,"y",Sz]));qA=bp($t,$CLJS.G(["x",Sz]));rA=bp(nt,$CLJS.G(["x",Sz]));sA=bp(Cp,$CLJS.G(["x",Sz]));
Pz=Xo(new $CLJS.u(function(){return tA},Lu,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Or,"metabase/mbql/schema.cljc",31,1,667,!0,667,$CLJS.B,null,$CLJS.p(tA)?tA.D:null])));uA=bp($CLJS.Sw,$CLJS.G(["datetime-x",Tz,"datetime-y",Tz,"unit",hz]));
vA=bp($CLJS.Qw,$CLJS.G(["datetime",Tz,"unit",gz,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,iz],null)]));wA=bp($CLJS.ov,$CLJS.G(["date",Tz]));xA=bp($CLJS.my,$CLJS.G(["date",Tz]));yA=bp($CLJS.$s,$CLJS.G(["date",Tz]));zA=bp($CLJS.yv,$CLJS.G(["date",Tz,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,iz],null)]));AA=bp($CLJS.ju,$CLJS.G(["date",Tz]));BA=bp($CLJS.fr,$CLJS.G(["date",Tz]));CA=bp($CLJS.tq,$CLJS.G(["datetime",Tz]));DA=bp($CLJS.Cx,$CLJS.G(["datetime",Tz]));EA=bp($CLJS.Mw,$CLJS.G(["datetime",Tz]));
FA=$CLJS.Cm($CLJS.ae(So,new $CLJS.R(null,new $CLJS.n(null,9,[uu,null,$p,null,Vr,null,wp,null,st,null,Jq,null,yq,null,Gt,null,fu,null],null),null)),"arithmetic-datetime-unit");GA=bp($CLJS.Ar,$CLJS.G(["datetime",Tz,"amount",Sz,"unit",FA]));HA=bp($CLJS.wv,$CLJS.G(["datetime",Tz,"amount",Sz,"unit",FA]));
IA=dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof hA?new $CLJS.u(function(){return hA},$CLJS.J(ls,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uy,"metabase/mbql/schema.cljc",51,$CLJS.jv,1,632,632,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jv,Uv,$CLJS.U($CLJS.S,et),Sv,$CLJS.U($CLJS.S,et),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,et))],null),$CLJS.B,"Schema for a valid + clause.",$CLJS.p(hA)?hA.D:null])):null));return $CLJS.p(a)?a:uy}(),hA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof vA?new $CLJS.u(function(){return vA},$CLJS.J(xx,
new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Ay,"metabase/mbql/schema.cljc",71,$CLJS.Qw,1,689,689,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Qw,rr,$CLJS.U($CLJS.S,Ts),$CLJS.My,$CLJS.U($CLJS.S,Xq),
ex,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,kr))],null),$CLJS.B,"Schema for a valid temporal-extract clause.",$CLJS.p(vA)?vA.D:null])):null));return $CLJS.p(a)?a:Ay}(),vA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof GA?new $CLJS.u(function(){return GA},$CLJS.J(Yr,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,
$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ct,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Et,"metabase/mbql/schema.cljc",67,$CLJS.Ar,1,728,728,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ar,rr,$CLJS.U($CLJS.S,Ts),Dr,$CLJS.U($CLJS.S,Hu),$CLJS.My,$CLJS.U($CLJS.S,aw)],null),$CLJS.B,"Schema for a valid datetime-add clause.",$CLJS.p(GA)?GA.D:null])):null));return $CLJS.p(a)?a:Et}(),
GA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof HA?new $CLJS.u(function(){return HA},$CLJS.J(Vs,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ct,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hv,"metabase/mbql/schema.cljc",72,$CLJS.wv,1,733,733,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wv,rr,$CLJS.U($CLJS.S,Ts),Dr,$CLJS.U($CLJS.S,Hu),$CLJS.My,$CLJS.U($CLJS.S,aw)],null),$CLJS.B,"Schema for a valid datetime-subtract clause.",$CLJS.p(HA)?HA.D:null])):null));return $CLJS.p(a)?a:hv}(),HA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof wA?new $CLJS.u(function(){return wA},$CLJS.J(Ms,
new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vq,"metabase/mbql/schema.cljc",71,$CLJS.ov,1,695,695,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ov,Op,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-year clause.",
$CLJS.p(wA)?wA.D:null])):null));return $CLJS.p(a)?a:vq}(),wA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof xA?new $CLJS.u(function(){return xA},$CLJS.J(tv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oq,"metabase/mbql/schema.cljc",74,$CLJS.my,1,698,698,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.my,Op,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-quarter clause.",$CLJS.p(xA)?xA.D:null])):null));return $CLJS.p(a)?a:oq}(),xA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==
typeof yA?new $CLJS.u(function(){return yA},$CLJS.J(it,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hu,"metabase/mbql/schema.cljc",72,$CLJS.$s,1,701,701,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,
Op,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-month clause.",$CLJS.p(yA)?yA.D:null])):null));return $CLJS.p(a)?a:hu}(),yA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof zA?new $CLJS.u(function(){return zA},$CLJS.J(Ws,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,
new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zp,"metabase/mbql/schema.cljc",71,$CLJS.yv,1,704,704,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.yv,Op,$CLJS.U($CLJS.S,Ts),ex,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,kr))],null),$CLJS.B,"Schema for a valid get-week clause.",$CLJS.p(zA)?zA.D:null])):null));return $CLJS.p(a)?a:zp}(),zA],null),new $CLJS.P(null,2,5,$CLJS.Q,
[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof AA?new $CLJS.u(function(){return AA},$CLJS.J(Mr,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Jt,"metabase/mbql/schema.cljc",70,$CLJS.ju,1,708,708,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ju,Op,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-day clause.",$CLJS.p(AA)?AA.D:null])):null));return $CLJS.p(a)?a:Jt}(),AA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof BA?new $CLJS.u(function(){return BA},$CLJS.J(Br,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gr,"metabase/mbql/schema.cljc",78,$CLJS.fr,1,711,711,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,Op,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-day-of-week clause.",$CLJS.p(BA)?BA.D:null])):
null));return $CLJS.p(a)?a:gr}(),BA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof CA?new $CLJS.u(function(){return CA},$CLJS.J(Zu,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Rw,"metabase/mbql/schema.cljc",71,$CLJS.tq,1,714,714,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tq,rr,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-hour clause.",$CLJS.p(CA)?CA.D:null])):null));return $CLJS.p(a)?a:Rw}(),CA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof DA?new $CLJS.u(function(){return DA},$CLJS.J(Tq,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Sx,"metabase/mbql/schema.cljc",73,$CLJS.Cx,1,717,717,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cx,rr,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-minute clause.",$CLJS.p(DA)?DA.D:null])):
null));return $CLJS.p(a)?a:Sx}(),DA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof EA?new $CLJS.u(function(){return EA},$CLJS.J(mw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Qw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Pv,"metabase/mbql/schema.cljc",73,$CLJS.Mw,1,720,720,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mw,rr,$CLJS.U($CLJS.S,Ts)],null),$CLJS.B,"Schema for a valid get-second clause.",$CLJS.p(EA)?EA.D:null])):null));return $CLJS.p(a)?a:Pv}(),EA],null)]));
Qz=Xo(new $CLJS.u(function(){return IA},$y,$CLJS.Yf([$CLJS.Vh,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[!0,$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ay,"metabase/mbql/schema.cljc",35,1,738,738,$CLJS.B,null,$CLJS.p(IA)?IA.D:null])));
Hz=Xo(new $CLJS.u(function(){return JA},Kt,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Rp,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.B,null,$CLJS.p(JA)?JA.D:null])));
LA=bp($CLJS.Qs,$CLJS.G(["first-clause",Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null]))),"second-clause",Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null]))),"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,
$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null])))],null)]));
MA=bp($CLJS.xq,$CLJS.G(["first-clause",Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null]))),"second-clause",Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null]))),"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[vu,Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,
$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null])))],null)]));
NA=bp($CLJS.bu,$CLJS.G(["clause",Xo(new $CLJS.u(function(){return $CLJS.KA},Wr,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vt,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.B,null,$CLJS.p($CLJS.KA)?$CLJS.KA.D:null])))]));OA=Vo($CLJS.ui(cp,$CLJS.iv),nz,Dz);
PA=$CLJS.Zo($CLJS.so($CLJS.G([$CLJS.lo,$CLJS.mo,$CLJS.ko,tz,OA,Vz,vz])));QA=Vo($CLJS.ui(cp,$CLJS.Yn),vz,$CLJS.so($CLJS.G([$CLJS.mo,$CLJS.ko,tz,Vz,OA])));RA=bp($CLJS.Su,$CLJS.G(["field",PA,"value-or-field",PA,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[vu,PA],null)]));SA=bp($CLJS.Ct,$CLJS.G(["field",PA,"value-or-field",PA,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[vu,PA],null)]));TA=bp($CLJS.zr,$CLJS.G(["field",QA,"value-or-field",QA]));
UA=bp($CLJS.Nu,$CLJS.G(["field",QA,"value-or-field",QA]));VA=bp($CLJS.wq,$CLJS.G(["field",QA,"value-or-field",QA]));WA=bp($CLJS.pp,$CLJS.G(["field",QA,"value-or-field",QA]));XA=bp($CLJS.Pq,$CLJS.G(["field",QA,"min",QA,"max",QA]));YA=bp($CLJS.gy,$CLJS.G(["lat-field",QA,"lon-field",QA,"lat-max",QA,"lon-min",QA,"lat-min",QA,"lon-max",QA]));ZA=bp($CLJS.Hv,$CLJS.G(["field",Dz]));$A=bp($CLJS.lt,$CLJS.G(["field",Dz]));aB=bp($CLJS.nu,$CLJS.G(["field",Dz]));bB=bp($CLJS.rv,$CLJS.G(["field",Dz]));
cB=$CLJS.he([Qo(ny),$CLJS.lo]);dB=bp($CLJS.iu,$CLJS.G(["field",Iz,"string-or-field",Iz,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,cB],null)]));eB=bp($CLJS.Au,$CLJS.G(["field",Iz,"string-or-field",Iz,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,cB],null)]));fB=bp($CLJS.Xw,$CLJS.G(["field",Iz,"string-or-field",Iz,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,cB],null)]));gB=bp($CLJS.Tu,$CLJS.G(["field",Iz,"string-or-field",Iz,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,cB],null)]));
hB=$CLJS.he([Qo($CLJS.Ey),$CLJS.lo]);iB=bp($CLJS.Jw,$CLJS.G(["field",Dz,"n",$CLJS.so($CLJS.G([$CLJS.no,So.o($CLJS.G([$CLJS.Cs,$CLJS.Bv,$CLJS.qv]))])),"unit",jz,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,hB],null)]));jB=bp(Zw,$CLJS.G(["segment-id",$CLJS.so($CLJS.G([$CLJS.wo,$CLJS.to]))]));
Uz=Xo(new $CLJS.u(function(){return kB},ks,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lp,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.B,null,$CLJS.p(kB)?kB.D:null])));
kB=dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof LA?new $CLJS.u(function(){return LA},$CLJS.J(Hw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
cv,"metabase/mbql/schema.cljc",15,$CLJS.Qs,1,758,758,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Qs,Qy,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))),dw,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))),ms,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))))],null),$CLJS.B,"Schema for a valid and clause.",$CLJS.p(LA)?LA.D:null])):null));return $CLJS.p(a)?a:cv}(),LA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==
typeof kp&&"undefined"!==typeof MA?new $CLJS.u(function(){return MA},$CLJS.J(Iy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ws,"metabase/mbql/schema.cljc",14,$CLJS.xq,1,763,763,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xq,Qy,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,
vt))),dw,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))),ms,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))))],null),$CLJS.B,"Schema for a valid or clause.",$CLJS.p(MA)?MA.D:null])):null));return $CLJS.p(a)?a:ws}(),MA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof NA?new $CLJS.u(function(){return NA},$CLJS.J(gt,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ol,"metabase/mbql/schema.cljc",15,$CLJS.bu,1,768,768,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bu,$CLJS.Wq,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt)))],null),$CLJS.B,"Schema for a valid not clause.",$CLJS.p(NA)?NA.D:null])):null));return $CLJS.p(a)?a:$CLJS.ol}(),
NA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof TA?new $CLJS.u(function(){return TA},$CLJS.J(mt,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
$w,"metabase/mbql/schema.cljc",13,$CLJS.zr,1,814,814,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zr,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3c clause.",$CLJS.p(TA)?TA.D:null])):null));return $CLJS.p(a)?a:$w}(),TA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof VA?new $CLJS.u(function(){return VA},$CLJS.J(Dp,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Js,"metabase/mbql/schema.cljc",14,$CLJS.wq,1,816,816,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wq,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3c\x3d clause.",$CLJS.p(VA)?VA.D:null])):null));return $CLJS.p(a)?a:Js}(),VA],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof UA?new $CLJS.u(function(){return UA},$CLJS.J(Aw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Ru,"metabase/mbql/schema.cljc",
13,$CLJS.Nu,1,815,815,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nu,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3e clause.",$CLJS.p(UA)?UA.D:null])):null));return $CLJS.p(a)?a:Ru}(),UA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof WA?new $CLJS.u(function(){return WA},$CLJS.J(pu,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,
$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qs,"metabase/mbql/schema.cljc",14,$CLJS.pp,1,817,817,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pp,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3e\x3d clause.",$CLJS.p(WA)?WA.D:null])):null));return $CLJS.p(a)?a:qs}(),WA],null),new $CLJS.P(null,2,
5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof RA?new $CLJS.u(function(){return RA},$CLJS.J(Iw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Qn,"metabase/mbql/schema.cljc",
13,$CLJS.Su,1,811,811,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Su,$CLJS.px,$CLJS.U($CLJS.S,cs),Jy,$CLJS.U($CLJS.S,cs),Cv,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,cs))],null),$CLJS.B,"Schema for a valid \x3d clause.",$CLJS.p(RA)?RA.D:null])):null));return $CLJS.p(a)?a:$CLJS.Qn}(),RA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof SA?new $CLJS.u(function(){return SA},$CLJS.J(Iu,new $CLJS.n(null,1,[Kq,
!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vp,"metabase/mbql/schema.cljc",14,$CLJS.Ct,1,812,812,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ct,$CLJS.px,$CLJS.U($CLJS.S,cs),Jy,$CLJS.U($CLJS.S,cs),Cv,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,cs))],null),$CLJS.B,"Schema for a valid !\x3d clause.",$CLJS.p(SA)?
SA.D:null])):null));return $CLJS.p(a)?a:vp}(),SA],null)]));
lB=Uo.o($CLJS.G([$CLJS.ui(cp,Jz),Pz,$CLJS.ui(cp,Gz),Hz,$CLJS.ui(cp,Kz),Uz,$CLJS.In,dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof LA?new $CLJS.u(function(){return LA},$CLJS.J(Hw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cv,"metabase/mbql/schema.cljc",15,$CLJS.Qs,1,758,758,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Qs,Qy,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))),dw,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))),ms,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))))],null),$CLJS.B,"Schema for a valid and clause.",$CLJS.p(LA)?LA.D:null])):null));return $CLJS.p(a)?a:cv}(),LA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&
"undefined"!==typeof kp&&"undefined"!==typeof MA?new $CLJS.u(function(){return MA},$CLJS.J(Iy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ws,"metabase/mbql/schema.cljc",14,$CLJS.xq,1,763,763,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xq,Qy,$CLJS.U($CLJS.S,$CLJS.U(Ht,
$CLJS.U($CLJS.hh,vt))),dw,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))),ms,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt))))],null),$CLJS.B,"Schema for a valid or clause.",$CLJS.p(MA)?MA.D:null])):null));return $CLJS.p(a)?a:ws}(),MA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof NA?new $CLJS.u(function(){return NA},$CLJS.J(gt,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ol,"metabase/mbql/schema.cljc",15,$CLJS.bu,1,768,768,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bu,$CLJS.Wq,$CLJS.U($CLJS.S,$CLJS.U(Ht,$CLJS.U($CLJS.hh,vt)))],null),$CLJS.B,"Schema for a valid not clause.",$CLJS.p(NA)?NA.D:null])):null));return $CLJS.p(a)?a:$CLJS.ol}(),
NA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof RA?new $CLJS.u(function(){return RA},$CLJS.J(Iw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
$CLJS.Qn,"metabase/mbql/schema.cljc",13,$CLJS.Su,1,811,811,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Su,$CLJS.px,$CLJS.U($CLJS.S,cs),Jy,$CLJS.U($CLJS.S,cs),Cv,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,cs))],null),$CLJS.B,"Schema for a valid \x3d clause.",$CLJS.p(RA)?RA.D:null])):null));return $CLJS.p(a)?a:$CLJS.Qn}(),RA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof SA?new $CLJS.u(function(){return SA},
$CLJS.J(Iu,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vp,"metabase/mbql/schema.cljc",14,$CLJS.Ct,1,812,812,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ct,$CLJS.px,$CLJS.U($CLJS.S,cs),Jy,$CLJS.U($CLJS.S,cs),Cv,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,cs))],null),$CLJS.B,"Schema for a valid !\x3d clause.",
$CLJS.p(SA)?SA.D:null])):null));return $CLJS.p(a)?a:vp}(),SA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof TA?new $CLJS.u(function(){return TA},$CLJS.J(mt,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$w,"metabase/mbql/schema.cljc",13,$CLJS.zr,1,814,814,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zr,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3c clause.",$CLJS.p(TA)?TA.D:null])):null));return $CLJS.p(a)?a:$w}(),TA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof UA?new $CLJS.u(function(){return UA},$CLJS.J(Aw,new $CLJS.n(null,1,[Kq,!0],null)),
$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Ru,"metabase/mbql/schema.cljc",13,$CLJS.Nu,1,815,815,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nu,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3e clause.",$CLJS.p(UA)?UA.D:null])):null));return $CLJS.p(a)?a:Ru}(),
UA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof VA?new $CLJS.u(function(){return VA},$CLJS.J(Dp,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
Js,"metabase/mbql/schema.cljc",14,$CLJS.wq,1,816,816,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wq,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3c\x3d clause.",$CLJS.p(VA)?VA.D:null])):null));return $CLJS.p(a)?a:Js}(),VA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof WA?new $CLJS.u(function(){return WA},$CLJS.J(pu,new $CLJS.n(null,1,[Kq,!0],null)),
$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qs,"metabase/mbql/schema.cljc",14,$CLJS.pp,1,817,817,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pp,$CLJS.px,$CLJS.U($CLJS.S,su),Jy,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid \x3e\x3d clause.",$CLJS.p(WA)?WA.D:null])):null));return $CLJS.p(a)?
a:qs}(),WA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof XA?new $CLJS.u(function(){return XA},$CLJS.J(kv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nx,"metabase/mbql/schema.cljc",19,$CLJS.Pq,1,820,820,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Pq,$CLJS.px,$CLJS.U($CLJS.S,su),$CLJS.qy,$CLJS.U($CLJS.S,su),$CLJS.gx,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid between clause.",$CLJS.p(XA)?XA.D:null])):null));return $CLJS.p(a)?a:nx}(),XA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof dB?new $CLJS.u(function(){return dB},$CLJS.J(Jr,
new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Nw,"metabase/mbql/schema.cljc",23,$CLJS.iu,1,843,843,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iu,$CLJS.px,$CLJS.U($CLJS.S,ey),lv,$CLJS.U($CLJS.S,ey),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,Ky))],null),$CLJS.B,"Schema for a valid starts-with clause.",
$CLJS.p(dB)?dB.D:null])):null));return $CLJS.p(a)?a:Nw}(),dB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof eB?new $CLJS.u(function(){return eB},$CLJS.J(It,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Us,"metabase/mbql/schema.cljc",21,$CLJS.Au,1,844,844,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Au,$CLJS.px,$CLJS.U($CLJS.S,ey),lv,$CLJS.U($CLJS.S,ey),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,Ky))],null),$CLJS.B,"Schema for a valid ends-with clause.",$CLJS.p(eB)?eB.D:null])):null));return $CLJS.p(a)?a:Us}(),eB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof fB?new $CLJS.u(function(){return fB},
$CLJS.J($r,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xr,"metabase/mbql/schema.cljc",20,$CLJS.Xw,1,845,845,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Xw,$CLJS.px,$CLJS.U($CLJS.S,ey),lv,$CLJS.U($CLJS.S,ey),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,Ky))],null),$CLJS.B,
"Schema for a valid contains clause.",$CLJS.p(fB)?fB.D:null])):null));return $CLJS.p(a)?a:xr}(),fB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof gB?new $CLJS.u(function(){return gB},$CLJS.J(Bx,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dy,"metabase/mbql/schema.cljc",36,$CLJS.Tu,1,848,848,!0,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Tu,$CLJS.px,$CLJS.U($CLJS.S,ey),lv,$CLJS.U($CLJS.S,ey),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,Ky))],null),$CLJS.B,"Schema for a valid does-not-contain clause.",$CLJS.p(gB)?gB.D:null])):null));return $CLJS.p(a)?a:dy}(),gB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof YA?new $CLJS.u(function(){return YA},
$CLJS.J(Rx,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lx,"metabase/mbql/schema.cljc",26,$CLJS.gy,1,823,823,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.gy,Hy,$CLJS.U($CLJS.S,su),Uy,$CLJS.U($CLJS.S,su),Vp,$CLJS.U($CLJS.S,su),ou,$CLJS.U($CLJS.S,su),Mq,$CLJS.U($CLJS.S,
su),dt,$CLJS.U($CLJS.S,su)],null),$CLJS.B,"Schema for a valid inside clause.",$CLJS.p(YA)?YA.D:null])):null));return $CLJS.p(a)?a:Lx}(),YA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof aB?new $CLJS.u(function(){return aB},$CLJS.J(rs,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,
new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xt,"metabase/mbql/schema.cljc",28,$CLJS.nu,1,837,837,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nu,$CLJS.px,$CLJS.U($CLJS.S,aq)],null),$CLJS.B,"Schema for a valid is-empty clause.",$CLJS.p(aB)?aB.D:null])):null));return $CLJS.p(a)?a:xt}(),aB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==
typeof kp&&"undefined"!==typeof bB?new $CLJS.u(function(){return bB},$CLJS.J(ew,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$u,"metabase/mbql/schema.cljc",29,$CLJS.rv,1,838,838,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rv,$CLJS.px,$CLJS.U($CLJS.S,aq)],null),
$CLJS.B,"Schema for a valid not-empty clause.",$CLJS.p(bB)?bB.D:null])):null));return $CLJS.p(a)?a:$u}(),bB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof ZA?new $CLJS.u(function(){return ZA},$CLJS.J(tt,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Ss,"metabase/mbql/schema.cljc",27,$CLJS.Hv,1,832,832,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hv,$CLJS.px,$CLJS.U($CLJS.S,aq)],null),$CLJS.B,"Schema for a valid is-null clause.",$CLJS.p(ZA)?ZA.D:null])):null));return $CLJS.p(a)?a:Ss}(),ZA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof $A?new $CLJS.u(function(){return $A},$CLJS.J(pw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Xt,"metabase/mbql/schema.cljc",28,$CLJS.lt,1,833,833,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.px,$CLJS.U($CLJS.S,aq)],null),$CLJS.B,"Schema for a valid not-null clause.",$CLJS.p($A)?$A.D:null])):null));return $CLJS.p(a)?a:Xt}(),$A],null),new $CLJS.P(null,2,
5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof iB?new $CLJS.u(function(){return iB},$CLJS.J(zq,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nr,"metabase/mbql/schema.cljc",
33,$CLJS.Jw,1,868,868,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Jw,$CLJS.px,$CLJS.U($CLJS.S,aq),Xv,$CLJS.U($CLJS.S,$CLJS.U($CLJS.Kp,pt,$CLJS.U(ns,$CLJS.Cs,$CLJS.Bv,$CLJS.qv))),$CLJS.My,$CLJS.U($CLJS.S,gw),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,rp))],null),$CLJS.B,"Schema for a valid time-interval clause.",$CLJS.p(iB)?iB.D:null])):null));return $CLJS.p(a)?a:nr}(),iB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&
"undefined"!==typeof jB?new $CLJS.u(function(){return jB},$CLJS.J(Ls,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ds,"metabase/mbql/schema.cljc",27,Zw,1,881,881,!0,new $CLJS.P(null,3,5,$CLJS.Q,[Zw,Ip,$CLJS.U($CLJS.S,$CLJS.U($CLJS.Kp,fs,Wx))],null),$CLJS.B,
"Schema for a valid segment clause.",$CLJS.p(jB)?jB.D:null])):null));return $CLJS.p(a)?a:ds}(),jB],null)]))]));$CLJS.KA=Xo(new $CLJS.u(function(){return lB},Gs,$CLJS.Yf([$CLJS.Vh,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[!0,$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hs,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.B,null,$CLJS.p(lB)?lB.D:null])));
mB=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Po($CLJS.KA,"pred"),$CLJS.Po(Vz,"expr")],null)],null);nB=$CLJS.he([Qo($CLJS.bt),Vz]);oB=bp($CLJS.Bw,$CLJS.G(["clauses",mB,"options",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,nB],null)]));
tA=dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof hA?new $CLJS.u(function(){return hA},$CLJS.J(ls,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uy,"metabase/mbql/schema.cljc",51,$CLJS.jv,1,632,632,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jv,Uv,$CLJS.U($CLJS.S,et),Sv,$CLJS.U($CLJS.S,et),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,et))],null),$CLJS.B,"Schema for a valid + clause.",$CLJS.p(hA)?hA.D:null])):null));return $CLJS.p(a)?a:uy}(),hA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof iA?new $CLJS.u(function(){return iA},$CLJS.J(op,
new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rt,"metabase/mbql/schema.cljc",51,$CLJS.qw,1,635,635,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qw,Uv,$CLJS.U($CLJS.S,Hu),Sv,$CLJS.U($CLJS.S,et),hw,$CLJS.U($CLJS.S,
$CLJS.U($CLJS.$g,et))],null),$CLJS.B,"Schema for a valid - clause.",$CLJS.p(iA)?iA.D:null])):null));return $CLJS.p(a)?a:rt}(),iA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp?new $CLJS.u(function(){return jA},$CLJS.J(ur,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,X,$CLJS.di,$CLJS.Th,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),
$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Dq,"metabase/mbql/schema.cljc",$CLJS.kw,1,638,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kw,Uv,$CLJS.U($CLJS.S,Hu),Sv,$CLJS.U($CLJS.S,Hu),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,Hu))],null),$CLJS.B,"Schema for a valid / clause.",$CLJS.p(jA)?jA.D:null])):null));return $CLJS.p(a)?a:Dq}(),jA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==
typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof kA?new $CLJS.u(function(){return kA},$CLJS.J(Vt,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zw,"metabase/mbql/schema.cljc",
51,$CLJS.jt,1,640,640,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jt,Uv,$CLJS.U($CLJS.S,Hu),Sv,$CLJS.U($CLJS.S,Hu),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,Hu))],null),$CLJS.B,"Schema for a valid * clause.",$CLJS.p(kA)?kA.D:null])):null));return $CLJS.p(a)?a:zw}(),kA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof Xz?new $CLJS.u(function(){return Xz},$CLJS.J(Ww,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,
$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kq,"metabase/mbql/schema.cljc",58,Pr,1,599,599,new $CLJS.P(null,7,5,$CLJS.Q,[Pr,Nv,$CLJS.U($CLJS.S,Cr),Ov,$CLJS.U($CLJS.S,Cr),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,Cr))],null),$CLJS.B,"Schema for a valid coalesce clause.",
$CLJS.p(Xz)?Xz.D:null])):null));return $CLJS.p(a)?a:kq}(),Xz],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof Zz?new $CLJS.u(function(){return Zz},$CLJS.J(Dv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mq,"metabase/mbql/schema.cljc",56,rq,1,605,605,new $CLJS.P(null,3,5,$CLJS.Q,[rq,Vv,$CLJS.U($CLJS.S,ey)],null),$CLJS.B,"Schema for a valid length clause.",$CLJS.p(Zz)?Zz.D:null])):null));return $CLJS.p(a)?a:mq}(),Zz],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof lA?
new $CLJS.u(function(){return lA},$CLJS.J(Uw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Vq,"metabase/mbql/schema.cljc",55,zt,1,642,642,new $CLJS.P(null,3,5,$CLJS.Q,[zt,Uv,$CLJS.U($CLJS.S,
Hu)],null),$CLJS.B,"Schema for a valid floor clause.",$CLJS.p(lA)?lA.D:null])):null));return $CLJS.p(a)?a:Vq}(),lA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof mA?new $CLJS.u(function(){return mA},$CLJS.J(Lq,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,
1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tp,"metabase/mbql/schema.cljc",54,ky,1,645,645,new $CLJS.P(null,3,5,$CLJS.Q,[ky,Uv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid ceil clause.",$CLJS.p(mA)?mA.D:null])):null));return $CLJS.p(a)?a:tp}(),mA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&
"undefined"!==typeof kp&&"undefined"!==typeof nA?new $CLJS.u(function(){return nA},$CLJS.J(Hx,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),js,"metabase/mbql/schema.cljc",55,uw,1,648,648,new $CLJS.P(null,
3,5,$CLJS.Q,[uw,Uv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid round clause.",$CLJS.p(nA)?nA.D:null])):null));return $CLJS.p(a)?a:js}(),nA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof oA?new $CLJS.u(function(){return oA},$CLJS.J(ty,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],
[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),py,"metabase/mbql/schema.cljc",53,Yv,1,651,651,new $CLJS.P(null,3,5,$CLJS.Q,[Yv,Uv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid abs clause.",$CLJS.p(oA)?oA.D:null])):null));return $CLJS.p(a)?a:py}(),oA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==
typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof pA?new $CLJS.u(function(){return pA},$CLJS.J(ax,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[Rs,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Tt,"metabase/mbql/schema.cljc",
69,Nx,1,654,654,new $CLJS.P(null,5,5,$CLJS.Q,[Nx,Uv,$CLJS.U($CLJS.S,Hu),Sv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid power clause.",$CLJS.p(pA)?pA.D:null])):null));return $CLJS.p(a)?a:Tt}(),pA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof qA?new $CLJS.u(function(){return qA},$CLJS.J(uv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,
$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[Rs,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Ku,"metabase/mbql/schema.cljc",68,$t,1,657,657,new $CLJS.P(null,3,5,$CLJS.Q,[$t,Uv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid sqrt clause.",$CLJS.p(qA)?qA.D:null])):null));return $CLJS.p(a)?a:Ku}(),qA],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof rA?new $CLJS.u(function(){return rA},$CLJS.J(sw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[Rs,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mv,"metabase/mbql/schema.cljc",67,nt,1,660,660,new $CLJS.P(null,3,5,$CLJS.Q,[nt,Uv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid exp clause.",$CLJS.p(rA)?rA.D:null])):null));return $CLJS.p(a)?a:mv}(),rA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof sA?new $CLJS.u(function(){return sA},$CLJS.J(ss,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,
$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[Rs,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Bs,"metabase/mbql/schema.cljc",67,Cp,1,663,663,new $CLJS.P(null,3,5,$CLJS.Q,[Cp,Uv,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid log clause.",$CLJS.p(sA)?sA.D:null])):null));return $CLJS.p(a)?a:Bs}(),sA],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof oB?new $CLJS.u(function(){return oB},$CLJS.J(cy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ts,"metabase/mbql/schema.cljc",61,$CLJS.Bw,1,915,915,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bw,mx,$CLJS.U($CLJS.S,ps),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,ft))],null),$CLJS.B,"Schema for a valid case clause.",$CLJS.p(oB)?oB.D:null])):null));return $CLJS.p(a)?a:ts}(),oB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof uA?new $CLJS.u(function(){return uA},$CLJS.J(fq,new $CLJS.n(null,
1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Sw,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Oq,"metabase/mbql/schema.cljc",65,$CLJS.Sw,1,684,684,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Sw,Mv,$CLJS.U($CLJS.S,Ts),Lv,$CLJS.U($CLJS.S,Ts),$CLJS.My,$CLJS.U($CLJS.S,
Du)],null),$CLJS.B,"Schema for a valid datetime-diff clause.",$CLJS.p(uA)?uA.D:null])):null));return $CLJS.p(a)?a:Oq}(),uA],null)]));
JA=dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof Yz?new $CLJS.u(function(){return Yz},$CLJS.J(Py,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Iq,"metabase/mbql/schema.cljc",59,cx,1,602,602,new $CLJS.P(null,7,5,$CLJS.Q,[cx,Vv,$CLJS.U($CLJS.S,ey),bq,$CLJS.U($CLJS.S,Hu),rq,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,Hu))],null),$CLJS.B,"Schema for a valid substring clause.",$CLJS.p(Yz)?Yz.D:null])):null));return $CLJS.p(a)?a:Iq}(),Yz],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof $z?new $CLJS.u(function(){return $z},$CLJS.J(Bu,new $CLJS.n(null,
1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Dt,"metabase/mbql/schema.cljc",54,Yu,1,608,608,new $CLJS.P(null,3,5,$CLJS.Q,[Yu,Vv,$CLJS.U($CLJS.S,ey)],null),$CLJS.B,"Schema for a valid trim clause.",$CLJS.p($z)?
$z.D:null])):null));return $CLJS.p(a)?a:Dt}(),$z],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof bA?new $CLJS.u(function(){return bA},$CLJS.J(qu,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nv,"metabase/mbql/schema.cljc",55,Zv,1,614,614,new $CLJS.P(null,3,5,$CLJS.Q,[Zv,Vv,$CLJS.U($CLJS.S,ey)],null),$CLJS.B,"Schema for a valid ltrim clause.",$CLJS.p(bA)?bA.D:null])):null));return $CLJS.p(a)?a:nv}(),bA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof aA?new $CLJS.u(function(){return aA},
$CLJS.J(Bq,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hp,"metabase/mbql/schema.cljc",55,Wt,1,611,611,new $CLJS.P(null,3,5,$CLJS.Q,[Wt,Vv,$CLJS.U($CLJS.S,ey)],null),$CLJS.B,"Schema for a valid rtrim clause.",
$CLJS.p(aA)?aA.D:null])):null));return $CLJS.p(a)?a:Hp}(),aA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof eA?new $CLJS.u(function(){return eA},$CLJS.J(nw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Xr,"metabase/mbql/schema.cljc",57,fx,1,623,623,new $CLJS.P(null,7,5,$CLJS.Q,[fx,Vv,$CLJS.U($CLJS.S,ey),dx,$CLJS.U($CLJS.S,$CLJS.Wv),yw,$CLJS.U($CLJS.S,$CLJS.Wv)],null),$CLJS.B,"Schema for a valid replace clause.",$CLJS.p(eA)?eA.D:null])):null));return $CLJS.p(a)?a:Xr}(),eA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==
typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof dA?new $CLJS.u(function(){return dA},$CLJS.J(Dy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sr,"metabase/mbql/schema.cljc",55,gq,1,
620,620,new $CLJS.P(null,3,5,$CLJS.Q,[gq,Vv,$CLJS.U($CLJS.S,ey)],null),$CLJS.B,"Schema for a valid lower clause.",$CLJS.p(dA)?dA.D:null])):null));return $CLJS.p(a)?a:sr}(),dA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof cA?new $CLJS.u(function(){return cA},$CLJS.J(rw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,
$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Qt,"metabase/mbql/schema.cljc",55,gv,1,617,617,new $CLJS.P(null,3,5,$CLJS.Q,[gv,Vv,$CLJS.U($CLJS.S,ey)],null),$CLJS.B,"Schema for a valid upper clause.",$CLJS.p(cA)?cA.D:null])):null));return $CLJS.p(a)?a:Qt}(),cA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof fA?new $CLJS.u(function(){return fA},$CLJS.J(au,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Dw,"metabase/mbql/schema.cljc",56,Ot,1,626,626,new $CLJS.P(null,7,5,$CLJS.Q,[Ot,Nv,$CLJS.U($CLJS.S,ey),Ov,$CLJS.U($CLJS.S,ey),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,ey))],null),$CLJS.B,"Schema for a valid concat clause.",$CLJS.p(fA)?fA.D:null])):null));return $CLJS.p(a)?a:Dw}(),fA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof gA?new $CLJS.u(function(){return gA},$CLJS.J(fy,new $CLJS.n(null,
1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,2,[$CLJS.Fu,"null",zy,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Fs,"metabase/mbql/schema.cljc",74,uq,1,629,629,new $CLJS.P(null,5,5,$CLJS.Q,[uq,Vv,$CLJS.U($CLJS.S,ey),Zx,$CLJS.U($CLJS.S,$CLJS.Wv)],null),$CLJS.B,"Schema for a valid regex-match-first clause.",
$CLJS.p(gA)?gA.D:null])):null));return $CLJS.p(a)?a:Fs}(),gA],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof Xz?new $CLJS.u(function(){return Xz},$CLJS.J(Ww,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Fu,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kq,"metabase/mbql/schema.cljc",58,Pr,1,599,599,new $CLJS.P(null,7,5,$CLJS.Q,[Pr,Nv,$CLJS.U($CLJS.S,Cr),Ov,$CLJS.U($CLJS.S,Cr),hw,$CLJS.U($CLJS.S,$CLJS.U($CLJS.$g,Cr))],null),$CLJS.B,"Schema for a valid coalesce clause.",$CLJS.p(Xz)?Xz.D:null])):null));return $CLJS.p(a)?a:kq}(),Xz],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==
typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof oB?new $CLJS.u(function(){return oB},$CLJS.J(cy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ts,"metabase/mbql/schema.cljc",61,$CLJS.Bw,1,
915,915,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bw,mx,$CLJS.U($CLJS.S,ps),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,ft))],null),$CLJS.B,"Schema for a valid case clause.",$CLJS.p(oB)?oB.D:null])):null));return $CLJS.p(a)?a:ts}(),oB],null)]));$CLJS.pB=Uo.o($CLJS.G([$CLJS.ui(cp,Jz),Pz,$CLJS.ui(cp,Gz),Hz,$CLJS.ui(cp,Kz),Uz,$CLJS.ui(cp,Oz),Qz,$CLJS.ui(cp,$CLJS.Bw),oB,$CLJS.In,Dz]));qB=bp($CLJS.lx,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,Dz],null)]));
rB=bp($CLJS.Tw,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[Qr,Dz],null)]));sB=bp($CLJS.cu,$CLJS.G(["field-or-expression",$CLJS.pB]));tB=bp($CLJS.Gx,$CLJS.G(["field-or-expression",$CLJS.pB]));uB=bp($CLJS.gu,$CLJS.G(["field-or-expression",$CLJS.pB]));vB=bp($CLJS.ly,$CLJS.G(["field-or-expression",$CLJS.pB]));wB=bp($CLJS.qy,$CLJS.G(["field-or-expression",$CLJS.pB]));xB=bp($CLJS.gx,$CLJS.G(["field-or-expression",$CLJS.pB]));yB=bp($CLJS.$q,$CLJS.G(["field-or-expression",$CLJS.pB,"pred",$CLJS.KA]));
zB=bp($CLJS.kt,$CLJS.G(["pred",$CLJS.KA]));AB=bp($CLJS.xu,$CLJS.G(["pred",$CLJS.KA]));BB=bp($CLJS.sy,$CLJS.G(["field-or-expression",$CLJS.pB]));CB=bp($CLJS.wu,$CLJS.G(["field-or-expression",$CLJS.pB]));DB=bp($CLJS.Sp,$CLJS.G(["field-or-expression",$CLJS.pB]));EB=bp($CLJS.Es,$CLJS.G(["field-or-expression",$CLJS.pB,"percentile",Sz]));FB=bp(Zp,$CLJS.G(["metric-id",$CLJS.so($CLJS.G([$CLJS.wo,$CLJS.to]))]));
GB=Vo($CLJS.ui(cp,Jz),Pz,dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof sB?new $CLJS.u(function(){return sB},$CLJS.J(ht,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Cw,"metabase/mbql/schema.cljc",60,$CLJS.cu,1,954,954,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cu,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid avg clause.",$CLJS.p(sB)?sB.D:null])):null));return $CLJS.p(a)?a:Cw}(),sB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof tB?new $CLJS.u(function(){return tB},$CLJS.J(Aq,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,
$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iq,"metabase/mbql/schema.cljc",64,$CLJS.Gx,1,955,955,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid cum-sum clause.",$CLJS.p(tB)?tB.D:null])):null));return $CLJS.p(a)?
a:iq}(),tB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof uB?new $CLJS.u(function(){return uB},$CLJS.J(Oy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vw,"metabase/mbql/schema.cljc",65,$CLJS.gu,1,956,956,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gu,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid distinct clause.",$CLJS.p(uB)?uB.D:null])):null));return $CLJS.p(a)?a:vw}(),uB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof BB?new $CLJS.u(function(){return BB},$CLJS.J(Mu,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[vv,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Eq,"metabase/mbql/schema.cljc",76,$CLJS.sy,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sy,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid stddev clause.",$CLJS.p(BB)?BB.D:null])):null));return $CLJS.p(a)?
a:Eq}(),BB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof vB?new $CLJS.u(function(){return vB},$CLJS.J(hr,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ir,"metabase/mbql/schema.cljc",60,$CLJS.ly,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ly,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid sum clause.",$CLJS.p(vB)?vB.D:null])):null));return $CLJS.p(a)?a:ir}(),vB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof wB?new $CLJS.u(function(){return wB},$CLJS.J(or,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,
$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jr,"metabase/mbql/schema.cljc",60,$CLJS.qy,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qy,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid min clause.",$CLJS.p(wB)?wB.D:null])):null));return $CLJS.p(a)?a:
jr}(),wB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof xB?new $CLJS.u(function(){return xB},$CLJS.J(Fp,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Pp,"metabase/mbql/schema.cljc",60,$CLJS.gx,1,959,959,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gx,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid max clause.",$CLJS.p(xB)?xB.D:null])):null));return $CLJS.p(a)?a:Pp}(),xB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof FB?new $CLJS.u(function(){return FB},$CLJS.J(Xs,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,
$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pq,"metabase/mbql/schema.cljc",26,Zp,1,989,989,!0,new $CLJS.P(null,3,5,$CLJS.Q,[Zp,wt,$CLJS.U($CLJS.S,$CLJS.U($CLJS.Kp,fs,Wx))],null),$CLJS.B,"Schema for a valid metric clause.",$CLJS.p(FB)?FB.D:null])):null));return $CLJS.p(a)?a:pq}(),FB],null),new $CLJS.P(null,2,5,$CLJS.Q,
[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof AB?new $CLJS.u(function(){return AB},$CLJS.J(sv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ru,"metabase/mbql/schema.cljc",62,$CLJS.xu,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xu,Fx,$CLJS.U($CLJS.S,vt)],null),$CLJS.B,"Schema for a valid share clause.",$CLJS.p(AB)?AB.D:null])):null));return $CLJS.p(a)?a:ru}(),AB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof zB?new $CLJS.u(function(){return zB},$CLJS.J(Kx,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,
$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oy,"metabase/mbql/schema.cljc",68,$CLJS.kt,1,964,964,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,Fx,$CLJS.U($CLJS.S,vt)],null),$CLJS.B,"Schema for a valid count-where clause.",$CLJS.p(zB)?zB.D:null])):null));return $CLJS.p(a)?
a:oy}(),zB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof yB?new $CLJS.u(function(){return yB},$CLJS.J(Bp,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),As,"metabase/mbql/schema.cljc",66,$CLJS.$q,1,961,961,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$q,os,$CLJS.U($CLJS.S,us),Fx,$CLJS.U($CLJS.S,vt)],null),$CLJS.B,"Schema for a valid sum-where clause.",$CLJS.p(yB)?yB.D:null])):null));return $CLJS.p(a)?a:As}(),yB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof oB?new $CLJS.u(function(){return oB},$CLJS.J(cy,new $CLJS.n(null,1,[Kq,!0],null)),
$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ts,"metabase/mbql/schema.cljc",61,$CLJS.Bw,1,915,915,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bw,mx,$CLJS.U($CLJS.S,ps),$CLJS.Ul,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,ft))],null),$CLJS.B,"Schema for a valid case clause.",
$CLJS.p(oB)?oB.D:null])):null));return $CLJS.p(a)?a:ts}(),oB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof DB?new $CLJS.u(function(){return DB},$CLJS.J(Kv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[yr,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,
1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Yp,"metabase/mbql/schema.cljc",68,$CLJS.Sp,1,977,977,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sp,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid median clause.",$CLJS.p(DB)?DB.D:null])):null));return $CLJS.p(a)?a:Yp}(),DB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==
typeof EB?new $CLJS.u(function(){return EB},$CLJS.J(jw,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[yr,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Rq,"metabase/mbql/schema.cljc",72,$CLJS.Es,1,980,980,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,os,
$CLJS.U($CLJS.S,us),$CLJS.Es,$CLJS.U($CLJS.S,Hu)],null),$CLJS.B,"Schema for a valid percentile clause.",$CLJS.p(EB)?EB.D:null])):null));return $CLJS.p(a)?a:Rq}(),EB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof CB?new $CLJS.u(function(){return CB},$CLJS.J(iy,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,
$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wr,"metabase/mbql/schema.cljc",77,$CLJS.wu,1,974,974,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wu,os,$CLJS.U($CLJS.S,us)],null),$CLJS.B,"Schema for a valid var clause.",$CLJS.p(CB)?CB.D:null])):null));return $CLJS.p(a)?a:wr}(),CB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&
"undefined"!==typeof kp&&"undefined"!==typeof rB?new $CLJS.u(function(){return rB},$CLJS.J(ar,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Tx,"metabase/mbql/schema.cljc",74,$CLJS.Tw,1,944,944,
!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tw,$CLJS.px,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,aq))],null),$CLJS.B,"Schema for a valid cum-count clause.",$CLJS.p(rB)?rB.D:null])):null));return $CLJS.p(a)?a:Tx}(),rB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof qB?new $CLJS.u(function(){return qB},$CLJS.J(Gq,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([Tv,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,
$CLJS.Th,$CLJS.Uh,qr,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[new $CLJS.R(null,new $CLJS.n(null,1,[ox,"null"],null),null),$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Yg,"metabase/mbql/schema.cljc",70,$CLJS.lx,1,943,943,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lx,$CLJS.px,$CLJS.U($CLJS.S,$CLJS.U($CLJS.en,aq))],null),$CLJS.B,"Schema for a valid count clause.",$CLJS.p(qB)?qB.D:null])):null));return $CLJS.p(a)?
a:$CLJS.Yg}(),qB],null)])));HB=Xo(new $CLJS.u(function(){return GB},Qq,$CLJS.Yf([$CLJS.Vh,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[!0,$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hs,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.B,null,$CLJS.p(GB)?GB.D:null])));IB=$CLJS.he([Qo($CLJS.T),$CLJS.to,Qo($CLJS.lw),$CLJS.to,$CLJS.oo,$CLJS.jo]);
$CLJS.JB=bp($CLJS.xw,$CLJS.G(["aggregation",HB,"options",IB]));$CLJS.Rz=Vo($CLJS.ui(cp,$CLJS.xw),$CLJS.JB,HB);KB=bp($CLJS.Bt,$CLJS.G(["field",$CLJS.Fz]));LB=bp($CLJS.Ps,$CLJS.G(["field",$CLJS.Fz]));
$CLJS.MB=dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof KB?new $CLJS.u(function(){return KB},$CLJS.J(Qv,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zv,"metabase/mbql/schema.cljc",15,$CLJS.Bt,1,1033,1033,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bt,$CLJS.px,$CLJS.U($CLJS.S,Mt)],null),$CLJS.B,"Schema for a valid asc clause.",$CLJS.p(KB)?KB.D:null])):null));return $CLJS.p(a)?a:zv}(),KB],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof LB?new $CLJS.u(function(){return LB},$CLJS.J(Ft,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,
$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Jx,"metabase/mbql/schema.cljc",16,$CLJS.Ps,1,1034,1034,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ps,$CLJS.px,$CLJS.U($CLJS.S,Mt)],null),$CLJS.B,"Schema for a valid desc clause.",$CLJS.p(LB)?LB.D:null])):null));return $CLJS.p(a)?a:Jx}(),LB],null)]));
So.o($CLJS.G([ev,xv,$CLJS.qx,Pu,du,Op]));NB=$CLJS.he([Qo($CLJS.Ny),$CLJS.to,$CLJS.T,$CLJS.to,$CLJS.lw,$CLJS.to,$CLJS.oo,$CLJS.jo]);OB=$CLJS.Ko($CLJS.G([NB,$CLJS.he([$CLJS.xn,$CLJS.ym(ev),By,$CLJS.to,yu,$CLJS.wo,Qo(Ry),$CLJS.wo])]));PB=$CLJS.Ko($CLJS.G([NB,new $CLJS.n(null,2,[$CLJS.xn,$CLJS.ym(xv),Er,$CLJS.wo],null)]));QB=$CLJS.Ko($CLJS.G([NB,$CLJS.he([Qo($CLJS.bt),$CLJS.jo,Qo($x),$CLJS.lo])]));
SB=$CLJS.Ko($CLJS.G([QB,new $CLJS.n(null,3,[$CLJS.xn,$CLJS.ym($CLJS.qx),$CLJS.qx,Bz,$CLJS.pv,Xo(new $CLJS.u(function(){return RB},Gu,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sx,"metabase/mbql/schema.cljc",23,1,1130,!0,1130,$CLJS.B,null,$CLJS.p(RB)?RB.D:null])))],null)]));
TB=$CLJS.ae(So,new $CLJS.R(null,new $CLJS.n(null,4,[Op,null,Pu,null,cq,null,du,null],null),null));UB=$CLJS.Ko($CLJS.G([QB,new $CLJS.n(null,1,[$CLJS.xn,TB],null)]));VB=Uo.o($CLJS.G([function(a){return $CLJS.E.h($CLJS.xn.g(a),$CLJS.qx)},SB,function(a){return $CLJS.E.h($CLJS.xn.g(a),ev)},OB,function(a){return $CLJS.E.h($CLJS.xn.g(a),xv)},PB,$CLJS.In,UB]));
WB=$CLJS.Em($CLJS.he([$CLJS.to,VB]),function(a){return $CLJS.re(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.h(c,$CLJS.T.g(b))},a)},"keys in template tag map must match the :name of their values");XB=$CLJS.he([$CLJS.Ux,$CLJS.jo,Qo($CLJS.jq),WB,Qo(Vy),$CLJS.Zo($CLJS.to),$CLJS.oo,$CLJS.jo]);
ZB=Vo($CLJS.xh.h($CLJS.Xc,$CLJS.wx),$CLJS.Mo(XB,new $CLJS.n(null,1,[$CLJS.Ux,$CLJS.wx],null)),Xo(new $CLJS.u(function(){return $CLJS.YB},Nq,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nq,"metabase/mbql/schema.cljc",25,1,1210,!0,1210,$CLJS.B,null,$CLJS.p($CLJS.YB)?$CLJS.YB.D:null]))));
$B=$CLJS.he([$CLJS.T,$CLJS.to,$CLJS.lj,$CLJS.uo,bx,$CLJS.to,Qo($CLJS.Xy),$CLJS.Zo($CLJS.vo),Qo($CLJS.Cq),$CLJS.Zo($CLJS.yo),$CLJS.jo,$CLJS.jo]);$CLJS.aC=/^card__[1-9]\d*$/;bC=$CLJS.so($CLJS.G([$CLJS.wo,$CLJS.aC]));cC=$CLJS.ae(So,new $CLJS.R(null,new $CLJS.n(null,4,[tw,null,Qx,null,up,null,Fq,null],null),null));
eC=$CLJS.Em($CLJS.Yf([$CLJS.oo,Qo($CLJS.Ks),Qo($CLJS.Is),Qo($CLJS.iw),Qo($CLJS.xy),Pt,Qo($CLJS.Fv),Qo(Rv),Qo($CLJS.Kr)],[$CLJS.jo,$CLJS.Zo(new $CLJS.P(null,1,5,$CLJS.Q,[$B],null)),bC,$CLJS.to,$CLJS.Cm($CLJS.so($CLJS.G([So.o($CLJS.G([$CLJS.Kh,Ir])),Xo(new $CLJS.u(function(){return dC},ww,$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,Gw,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Zy,"metabase/mbql/schema.cljc",16,1,1257,!0,1257,$CLJS.B,null,$CLJS.p(dC)?dC.D:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.KA,ZB,$CLJS.Zo($CLJS.wo),cC]),$CLJS.xh.h($CLJS.yh.h($CLJS.Is,$CLJS.Fv),$CLJS.ue($CLJS.xh.h($CLJS.Is,$CLJS.Fv))),"Joins must have either a `source-table` or `source-query`, but not both.");
fC=$CLJS.Em(ep(new $CLJS.P(null,1,5,$CLJS.Q,[eC],null)),function(a){return gp($CLJS.Be($CLJS.Bo,$CLJS.xg.h($CLJS.iw,a)))},"All join aliases must be unique.");dC=$CLJS.Cm($CLJS.Em(ep(new $CLJS.P(null,1,5,$CLJS.Q,[Dz],null)),gp,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.YB=$CLJS.Em($CLJS.Em($CLJS.Yf([$CLJS.oo,Qo($CLJS.es),Qo($CLJS.Ks),Qo($CLJS.hy),Qo($CLJS.Is),Qo($CLJS.gs),Qo($CLJS.xy),Qo($CLJS.Ds),Qo($CLJS.cw),Qo($CLJS.Fv),Qo(Ep),Qo(Ax),Qo($CLJS.Fu)],[$CLJS.jo,$CLJS.Em(ep(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MB],null)),gp,"distinct"),$CLJS.Zo(new $CLJS.P(null,1,5,$CLJS.Q,[$B],null)),fC,bC,$CLJS.KA,dC,ep(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Rz],null)),ep(new $CLJS.P(null,1,5,$CLJS.Q,[Dz],null)),ZB,$CLJS.xo,new $CLJS.n(null,2,[Ax,$CLJS.wo,Ju,$CLJS.wo],null),$CLJS.he([$CLJS.to,
$CLJS.pB])]),function(a){return $CLJS.E.h(1,$CLJS.D($CLJS.ri(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fv,$CLJS.Is],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.cw);b=$CLJS.K.h(b,$CLJS.xy);return $CLJS.Tc(mp.h($CLJS.ig(a),$CLJS.ig(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
gC=$CLJS.Yf([$CLJS.ku,Eu,Op,Rr,ux,Sq,Xu,Ys,$CLJS.dv,Pu,Xx,zx,tr,mr,Ut,qp,Sy,$CLJS.Tp,eu,bs,$CLJS.Fy,Rt,$CLJS.Ny,$CLJS.Nt,yp,$CLJS.eq,$CLJS.at,lr,cq,du,Lr],[new $CLJS.n(null,1,[Mp,new $CLJS.R(null,new $CLJS.n(null,5,[$CLJS.ku,null,Op,null,Pu,null,cq,null,du,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Eu,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,6,[$CLJS.ku,null,Op,null,$CLJS.dv,null,mr,null,$CLJS.Fy,
null,$CLJS.Ny,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,Jp,Wu,Sr,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Rr,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,tx,Wu,Sr,Mp,new $CLJS.R(null,new $CLJS.n(null,6,[$CLJS.ku,null,ux,null,Pu,null,mr,null,$CLJS.Ny,null,yp,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,Jp,Wu,Gv,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Sq,null],null),null)],null),new $CLJS.n(null,1,[Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Xu,null],null),null)],null),new $CLJS.n(null,
3,[$CLJS.xn,tx,Wu,Px,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Ys,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.dv,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,tx,Mp,new $CLJS.R(null,new $CLJS.n(null,6,[$CLJS.ku,null,ux,null,Pu,null,mr,null,$CLJS.Ny,null,yp,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Xx,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,tx,Wu,Gv,Mp,new $CLJS.R(null,
new $CLJS.n(null,1,[zx,null],null),null)],null),new $CLJS.n(null,1,[Mp,new $CLJS.R(null,new $CLJS.n(null,1,[tr,null],null),null)],null),new $CLJS.n(null,1,[Mp,new $CLJS.R(null,new $CLJS.n(null,5,[Op,null,Pu,null,mr,null,cq,null,du,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,Jp,Wu,Sr,Mp,new $CLJS.R(null,new $CLJS.n(null,9,[$CLJS.ku,null,Xu,null,tr,null,mr,null,Ut,null,Rt,null,$CLJS.Ny,null,yp,null,du,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,Jp,Wu,Gv,Mp,new $CLJS.R(null,new $CLJS.n(null,
1,[qp,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Sy,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,5,[Eu,null,$CLJS.dv,null,Xx,null,Sy,null,$CLJS.Tp,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,Jp,Wu,Gv,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[eu,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,Jp,Wu,Gv,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[bs,null],null),null)],null),new $CLJS.n(null,
2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,6,[$CLJS.ku,null,Op,null,$CLJS.dv,null,mr,null,$CLJS.Fy,null,$CLJS.Ny,null],null),null)],null),new $CLJS.n(null,1,[Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Rt,null],null),null)],null),new $CLJS.n(null,1,[Mp,new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Ny,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,5,[Eu,null,$CLJS.dv,null,Xx,null,Sy,null,$CLJS.Nt,null],null),null)],null),new $CLJS.n(null,1,[Mp,new $CLJS.R(null,
new $CLJS.n(null,1,[yp,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,5,[Eu,null,$CLJS.dv,null,Xx,null,Sy,null,$CLJS.eq,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Op,Mp,new $CLJS.R(null,new $CLJS.n(null,5,[Eu,null,$CLJS.dv,null,Xx,null,Sy,null,$CLJS.at,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,tx,Wu,Sr,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[lr,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,cq,Mp,new $CLJS.R(null,new $CLJS.n(null,
4,[$CLJS.ku,null,mr,null,$CLJS.Ny,null,cq,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.xn,Jp,Mp,new $CLJS.R(null,new $CLJS.n(null,9,[$CLJS.ku,null,Xu,null,tr,null,mr,null,Ut,null,Rt,null,$CLJS.Ny,null,yp,null,du,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.xn,tx,Wu,Gv,Mp,new $CLJS.R(null,new $CLJS.n(null,1,[Lr,null],null),null)],null)]);RB=$CLJS.ae(So,$CLJS.bg(gC));hC=bp(Yy,$CLJS.G(["tag-name",$CLJS.so($CLJS.G([$CLJS.to,new $CLJS.n(null,1,[$CLJS.Ny,$CLJS.to],null)]))]));
iC=bp($CLJS.qx,$CLJS.G(["target",$CLJS.so($CLJS.G([Dz,hC]))]));jC=bp(lu,$CLJS.G(["target",hC]));
kC=$CLJS.so($CLJS.G([Dz,dp($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof iC?new $CLJS.u(function(){return iC},$CLJS.J(Hr,new $CLJS.n(null,1,[Kq,!0],null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ot,"metabase/mbql/schema.cljc",21,$CLJS.qx,1,1560,1560,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qx,$CLJS.lq,$CLJS.U($CLJS.S,$CLJS.U($CLJS.Kp,aq,Jv))],null),$CLJS.B,"Schema for a valid dimension clause.",$CLJS.p(iC)?iC.D:null])):null));return $CLJS.p(a)?a:ot}(),iC],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=X.g($CLJS.Sc("undefined"!==typeof ip&&"undefined"!==typeof jp&&"undefined"!==typeof kp&&"undefined"!==typeof jC?new $CLJS.u(function(){return jC},$CLJS.J(wy,new $CLJS.n(null,1,[Kq,!0],
null)),$CLJS.Yf([$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,X,$CLJS.di,$CLJS.Th,$CLJS.Uh,Kw,$CLJS.bi,$CLJS.V,$CLJS.ci],[$CLJS.J(jy,new $CLJS.n(null,1,[$CLJS.V,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Fw,"metabase/mbql/schema.cljc",20,lu,1,1563,1563,new $CLJS.P(null,3,5,$CLJS.Q,[lu,$CLJS.lq,$CLJS.U($CLJS.S,Jv)],null),$CLJS.B,"Schema for a valid variable clause.",$CLJS.p(jC)?jC.D:null])):null));return $CLJS.p(a)?a:Fw}(),jC],null)]))]));
lC=$CLJS.he([$CLJS.xn,RB,Qo($CLJS.Ny),$CLJS.to,Qo($CLJS.lq),kC,Qo($CLJS.Yn),$CLJS.jo,Qo($CLJS.T),$CLJS.to,Qo(Iv),$CLJS.to,Qo($CLJS.bt),$CLJS.jo,$CLJS.oo,$CLJS.jo]);mC=new $CLJS.P(null,1,5,$CLJS.Q,[lC],null);nC=$CLJS.he([Qo(Ty),$CLJS.to,$CLJS.oo,$CLJS.jo]);oC=$CLJS.Em($CLJS.he([Qo(Zq),$CLJS.xo,Qo(ix),$CLJS.xo,$CLJS.oo,$CLJS.jo]),function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,Zq);b=$CLJS.K.h(b,ix);return $CLJS.La($CLJS.p(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
pC=$CLJS.he([Qo(is),$CLJS.lo,Qo(Cy),$CLJS.lo,Qo(Ix),$CLJS.lo,Qo(by),$CLJS.lo,Qo(Ew),$CLJS.Zo($CLJS.lo),Qo(Wp),$CLJS.Zo($CLJS.lo),Qo(Lw),$CLJS.Zo($CLJS.lo),$CLJS.oo,$CLJS.jo]);qC=So.o($CLJS.G([Up,Vy,ut,Xp,av,Qp,yt,Zs,Av,vs,sp,er,rx]));rC=$CLJS.he([Qo($CLJS.Mx),$CLJS.Zo(qC),Qo(Yw),$CLJS.Zo($CLJS.wo),Qo(Er),$CLJS.Zo($CLJS.wo),Qo(kx),$CLJS.Zo($CLJS.to),Qo(Tr),$CLJS.Zo($CLJS.wo),Qo(Wy),$CLJS.Zo($CLJS.wo),Qo($CLJS.tu),$CLJS.Zo(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.he([$CLJS.jo,$CLJS.jo])],null)),Qo(cr),$CLJS.Zo($CLJS.jo)]);
sC=$CLJS.so($CLJS.G([$CLJS.ym(-1337),$CLJS.wo]));
$CLJS.tC=$CLJS.Em($CLJS.Em($CLJS.Em($CLJS.Yf([$CLJS.oo,Qo($CLJS.wx),Qo(ow),Qo($CLJS.Lh),Qo(Cu),Qo(fw),$CLJS.xn,Qo($CLJS.Ux),Ry,Qo($CLJS.dr)],[$CLJS.jo,XB,$CLJS.Zo(nC),$CLJS.Zo(rC),$CLJS.Zo(oC),$CLJS.Zo(pC),So.o($CLJS.G([$CLJS.Ux,$CLJS.wx])),$CLJS.YB,sC,mC]),$CLJS.xh.h($CLJS.yh.h($CLJS.wx,$CLJS.Ux),$CLJS.ue($CLJS.xh.h($CLJS.wx,$CLJS.Ux))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.wx);var c=$CLJS.K.h(b,$CLJS.Ux);b=$CLJS.K.h(b,
$CLJS.xn);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.q.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.ue($CLJS.Ks),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$o(a);return function(c){var d=b(c);if($CLJS.p(d))throw $CLJS.Ho($CLJS.il("Value does not match schema: %s",$CLJS.G([$CLJS.Eg($CLJS.G([d]))])),new $CLJS.n(null,4,[$CLJS.xn,$CLJS.pn,$CLJS.Cl,a,$CLJS.Yn,c,$CLJS.Jh,d],null));return c}})($CLJS.tC);