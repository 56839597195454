var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.shared.util.js");require("./metabase.types.coercion_hierarchies.js");
'use strict';var li,oi,pi,ti,xi,yi,zi,Bi,Gi,vi,Ki,Li,Mi,Ni,Oi,Pi,Qi,Ri,Si,Ti,Ui,Vi,Xi,Yi,Zi,aj,bj,cj,dj,ej,fj,gj,hj,ij,jj,kj,mj,nj,pj,qj,rj,sj,tj,uj,vj,wj,xj,yj,zj,Aj,Bj,Cj,Ej,Fj,Gj,Hj,Ij,Jj,Kj,Lj,Mj,Nj,Oj,Pj,Qj,Rj,Sj,Tj,Vj,Wj,Xj,Yj,Zj,ak,bk,ck,dk,ek,fk,gk,hk,ik,jk,kk,lk,mk,nk,ok,pk,qk,rk,sk,tk,uk,vk,wk,yk,zk,Ak,Bk,Ck,Dk,Ek,Fk,Gk,Hk,Ik,Jk,Kk,Lk,Nk,Fi,Ok,Pk,Qk,Rk,Sk,Tk,Uk,Vk;$CLJS.ki=function(a){return null==a};
li=function(a){return function(b,c){b=a.h?a.h(b,c):a.call(null,b,c);return $CLJS.xc(b)?new $CLJS.wc(b):b}};$CLJS.mi=function(a){if(null!=a){var b=a instanceof $CLJS.M?$CLJS.Kd(a):null;return $CLJS.p(b)?[b,"/",$CLJS.kg(a)].join(""):$CLJS.kg(a)}return null};$CLJS.ni=function(a,b){return $CLJS.ke($CLJS.K.h($CLJS.jh.g(a),b))};oi=function(a,b){return $CLJS.ke($CLJS.K.h($CLJS.dh.g(a),b))};
pi=function(a){var b=li(a);return function(){function c(g,l){return $CLJS.qd(b,g,l)}function d(g){return a.g?a.g(g):a.call(null,g)}function e(){return a.v?a.v():a.call(null)}var f=null;f=function(g,l){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,g);case 2:return c.call(this,g,l)}throw Error("Invalid arity: "+arguments.length);};f.v=e;f.g=d;f.h=c;return f}()};
$CLJS.qi=function(a,b,c){var d=$CLJS.E.h(b,c);if(d)return d;d=$CLJS.dh.g(a);d=d.g?d.g(b):d.call(null,b);if(!(d=$CLJS.fd(d,c))&&(d=$CLJS.Zc(c)))if(d=$CLJS.Zc(b))if(d=$CLJS.D(c)===$CLJS.D(b)){d=!0;for(var e=0;;)if(d&&e!==$CLJS.D(c))d=$CLJS.qi(a,b.g?b.g(e):b.call(null,e),c.g?c.g(e):c.call(null,e)),e+=1;else return d}else return d;else return d;else return d};
$CLJS.ri=function(a,b){var c=$CLJS.ie;for(b=$CLJS.y(b);;)if(b){var d=$CLJS.A(b),e=$CLJS.K.j(a,d,$CLJS.Vg);c=$CLJS.E.h(e,$CLJS.Vg)?c:$CLJS.Cg.j(c,d,e);b=$CLJS.C(b)}else return $CLJS.sb(c,$CLJS.Sc(a))};$CLJS.si=function(a,b){return $CLJS.qi($CLJS.pb($CLJS.Rh()),a,b)};ti=function(a){return $CLJS.p($CLJS.yh.j($CLJS.Wc,$CLJS.Vc,$CLJS.ki)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.ui=function(a,b){return function(){function c(k,m,r){return a.O?a.O(b,k,m,r):a.call(null,b,k,m,r)}function d(k,m){return a.j?a.j(b,k,m):a.call(null,b,k,m)}function e(k){return a.h?a.h(b,k):a.call(null,b,k)}function f(){return a.g?a.g(b):a.call(null,b)}var g=null,l=function(){function k(r,v,w,x){var z=null;if(3<arguments.length){z=0;for(var H=Array(arguments.length-3);z<H.length;)H[z]=arguments[z+3],++z;z=new $CLJS.lc(H,0,null)}return m.call(this,r,v,w,z)}function m(r,v,w,x){return $CLJS.ee(a,
b,r,v,w,$CLJS.G([x]))}k.J=3;k.I=function(r){var v=$CLJS.A(r);r=$CLJS.C(r);var w=$CLJS.A(r);r=$CLJS.C(r);var x=$CLJS.A(r);r=$CLJS.oc(r);return m(v,w,x,r)};k.o=m;return k}();g=function(k,m,r,v){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,k);case 2:return d.call(this,k,m);case 3:return c.call(this,k,m,r);default:var w=null;if(3<arguments.length){w=0;for(var x=Array(arguments.length-3);w<x.length;)x[w]=arguments[w+3],++w;w=new $CLJS.lc(x,0,null)}return l.o(k,m,r,w)}throw Error("Invalid arity: "+
arguments.length);};g.J=3;g.I=l.I;g.v=f;g.g=e;g.h=d;g.j=c;g.o=l.o;return g}()};$CLJS.wi=function(a,b){var c=$CLJS.be(vi,a,b);return $CLJS.Jd(c,$CLJS.zh.h(function(d){return c===d},b))};
xi=function(a){return $CLJS.qd(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return W.j(b,d,c)},$CLJS.pb(function(){var b=new $CLJS.u(function(){return $CLJS.Rh},$CLJS.Zh,$CLJS.Yf([$CLJS.Vh,$CLJS.Sg,$CLJS.T,$CLJS.Yh,$CLJS.Wh,$CLJS.di,$CLJS.Th,$CLJS.Uh,$CLJS.bi,$CLJS.V,$CLJS.ci],[!0,$CLJS.Sh,$CLJS.Xh,"cljs/core.cljs",28,1,11153,11153,$CLJS.U($CLJS.Te),null,$CLJS.p($CLJS.Rh)?$CLJS.Rh.D:null]));return b.v?b.v():b.call(null)}()),a)};
yi=function(a,b,c){b=$CLJS.ig(ti(b));$CLJS.wh.O($CLJS.ei,$CLJS.Cg,a,b);$CLJS.wh.O($CLJS.fi,$CLJS.Cg,a,c)};
zi=function(){$CLJS.p($CLJS.pb($CLJS.hi))||$CLJS.p($CLJS.pb($CLJS.hi))||$CLJS.xe($CLJS.hi,xi(function(){return function c(b){return new $CLJS.Ld(null,function(){for(var d=b;;){var e=$CLJS.y(d);if(e){var f=e,g=$CLJS.A(f),l=$CLJS.I(g,0,null),k=$CLJS.I(g,1,null);if(e=$CLJS.y(function(m,r,v,w,x,z){return function Y(N){return new $CLJS.Ld(null,function(la,va,Ja){return function(){for(;;){var Ea=$CLJS.y(N);if(Ea){if($CLJS.ad(Ea)){var cb=$CLJS.Rb(Ea),zb=$CLJS.D(cb),wb=$CLJS.Od(zb);a:for(var ic=0;;)if(ic<
zb){var zd=$CLJS.Pc(cb,ic);wb.add(new $CLJS.P(null,2,5,$CLJS.Q,[zd,Ja],null));ic+=1}else{cb=!0;break a}return cb?$CLJS.Rd($CLJS.Td(wb),Y($CLJS.Sb(Ea))):$CLJS.Rd($CLJS.Td(wb),null)}wb=$CLJS.A(Ea);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[wb,Ja],null),Y($CLJS.oc(Ea)))}return null}}}(m,r,v,w,x,z),null)}}(d,g,l,k,f,e)(k)))return $CLJS.th.h(e,c($CLJS.oc(d)));d=$CLJS.oc(d)}else return null}},null)}($CLJS.pb($CLJS.ei))}()));return $CLJS.pb($CLJS.hi)};
$CLJS.Ai=function(a,b){return $CLJS.ae($CLJS.th,$CLJS.be($CLJS.xg,a,b))};Bi=function(){this.state=$CLJS.fg;this.A=32768;this.M=0};
Gi=function(a){var b=zi();$CLJS.p($CLJS.pb($CLJS.ii))||$CLJS.p($CLJS.pb($CLJS.ii))||$CLJS.xe($CLJS.ii,xi($CLJS.y($CLJS.pb($CLJS.fi))));var c=$CLJS.pb($CLJS.ii);return $CLJS.ke($CLJS.qd($CLJS.ui(Ci,$CLJS.Ei),$CLJS.ri($CLJS.pb($CLJS.gi),new $CLJS.P(null,1,5,$CLJS.Q,[a],null)),function(){return function f(e){return new $CLJS.Ld(null,function(){for(var g=e;;){var l=$CLJS.y(g);if(l){var k=l,m=$CLJS.A(k);if($CLJS.si(m,Fi)){var r=$CLJS.ni(c,m);if(l=$CLJS.y(function(v,w,x,z,H,N,Y){return function Ja(va){return new $CLJS.Ld(null,
function(Ea,cb,zb){return function(){for(var wb=va;;)if(wb=$CLJS.y(wb)){if($CLJS.ad(wb)){var ic=$CLJS.Rb(wb),zd=$CLJS.D(ic),Qe=$CLJS.Od(zd);a:for(var $c=0;;)if($c<zd){var Di=$CLJS.Pc(ic,$c);$CLJS.si(Di,Fi)||(Di=$CLJS.he([Di,$CLJS.gg([zb])]),Qe.add(Di));$c+=1}else{ic=!0;break a}return ic?$CLJS.Rd($CLJS.Td(Qe),Ja($CLJS.Sb(wb))):$CLJS.Rd($CLJS.Td(Qe),null)}Qe=$CLJS.A(wb);if($CLJS.si(Qe,Fi))wb=$CLJS.oc(wb);else return $CLJS.Jd($CLJS.he([Qe,$CLJS.gg([zb])]),Ja($CLJS.oc(wb)))}else return null}}(v,w,x,z,
H,N,Y),null)}}(g,r,m,k,l,b,c)(r)))return $CLJS.th.h(l,f($CLJS.oc(g)))}g=$CLJS.oc(g)}else return null}},null)}(oi(b,a))}()))};vi=function vi(a){switch(arguments.length){case 2:return vi.h(arguments[0],arguments[1]);case 3:return vi.j(arguments[0],arguments[1],arguments[2]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vi.o(arguments[0],arguments[1],arguments[2],new $CLJS.lc(c.slice(3),0,null))}};vi.h=function(a,b){return b};
vi.j=function(a,b,c){return(a.g?a.g(b):a.call(null,b))>(a.g?a.g(c):a.call(null,c))?b:c};vi.o=function(a,b,c,d){return $CLJS.qd(function(e,f){return vi.j(a,e,f)},vi.j(a,b,c),d)};vi.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);var d=$CLJS.C(c);c=$CLJS.A(d);d=$CLJS.C(d);return this.o(b,a,c,d)};vi.J=3;var Ci=function Ci(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ci.o(arguments[0],1<c.length?new $CLJS.lc(c.slice(1),0,null):null)};
Ci.o=function(a,b){if($CLJS.p($CLJS.se($CLJS.xd,b))){var c=function(d,e){var f=$CLJS.kb(e),g=$CLJS.lb(e);if($CLJS.fd(d,f)){e=$CLJS.Cg.j;var l=$CLJS.K.h(d,f);g=a.h?a.h(l,g):a.call(null,l,g);d=e.call($CLJS.Cg,d,f,g)}else d=$CLJS.Cg.j(d,f,g);return d};return $CLJS.ud(function(d,e){return $CLJS.qd(c,$CLJS.p(d)?d:$CLJS.ie,$CLJS.y(e))},b)}return null};Ci.J=1;Ci.I=function(a){var b=$CLJS.A(a);a=$CLJS.C(a);return this.o(b,a)};
var Hi=function Hi(a){switch(arguments.length){case 1:return Hi.g(arguments[0]);case 2:return Hi.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};Hi.g=function(a){return Hi.h($CLJS.pb($CLJS.Rh()),a)};Hi.h=function(a,b){return $CLJS.ke($CLJS.K.h($CLJS.Ug.g(a),b))};Hi.J=2;
$CLJS.Ei=function Ei(a){switch(arguments.length){case 0:return Ei.v();case 1:return Ei.g(arguments[0]);case 2:return Ei.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ei.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};$CLJS.Ei.v=function(){return $CLJS.fg};$CLJS.Ei.g=function(a){return a};$CLJS.Ei.h=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.qd($CLJS.Gd,b,a):$CLJS.qd($CLJS.Gd,a,b)};
$CLJS.Ei.o=function(a,b,c){a=$CLJS.wi($CLJS.D,$CLJS.Gd.o(c,b,$CLJS.G([a])));return $CLJS.qd($CLJS.uf,$CLJS.A(a),$CLJS.oc(a))};$CLJS.Ei.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};$CLJS.Ei.J=2;var W=function W(a){switch(arguments.length){case 2:return W.h(arguments[0],arguments[1]);case 3:return W.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};
W.h=function(a,b){a=$CLJS.G([a,b]);$CLJS.ce($CLJS.wh,$CLJS.Rh(),W,a);return null};
W.j=function(a,b,c){function d(l,k,m,r,v){return $CLJS.qd(function(w,x){return $CLJS.Cg.j(w,x,$CLJS.qd($CLJS.Gd,$CLJS.K.j(v,x,$CLJS.fg),$CLJS.Jd(r,v.g?v.g(r):v.call(null,r))))},l,$CLJS.Jd(k,m.g?m.g(k):m.call(null,k)))}var e=$CLJS.jh.g(a),f=$CLJS.Ug.g(a),g=$CLJS.dh.g(a);if($CLJS.fd(e.g?e.g(b):e.call(null,b),c))b=null;else{if($CLJS.fd(g.g?g.g(b):g.call(null,b),c))throw Error([$CLJS.q.g(b),"already has",$CLJS.q.g(c),"as ancestor"].join(""));if($CLJS.fd(g.g?g.g(c):g.call(null,c),b))throw Error(["Cyclic derivation:",
$CLJS.q.g(c),"has",$CLJS.q.g(b),"as ancestor"].join(""));b=new $CLJS.n(null,3,[$CLJS.jh,$CLJS.Cg.j($CLJS.jh.g(a),b,$CLJS.Gd.h($CLJS.K.j(e,b,$CLJS.fg),c)),$CLJS.dh,d($CLJS.dh.g(a),b,f,c,g),$CLJS.Ug,d($CLJS.Ug.g(a),c,g,b,f)],null)}return $CLJS.p(b)?b:a};W.J=3;Bi.prototype.Gb=function(){return this.state};Bi.prototype.na=$CLJS.gc;Bi.prototype.S=function(a,b,c){$CLJS.Hb(b,"#object[cljs.core.Volatile ");$CLJS.wg(new $CLJS.n(null,1,[$CLJS.eh,this.state],null),b,c);return $CLJS.Hb(b,"]")};
$CLJS.Ii=function Ii(a){switch(arguments.length){case 0:return Ii.v();case 1:return Ii.g(arguments[0]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};
$CLJS.Ii.v=function(){return function(a){var b=new Bi;return function(){function c(g,l){if($CLJS.fd($CLJS.pb(b),l))return g;var k=$CLJS.Gd.h(b.Gb(null),l);b.state=k;return a.h?a.h(g,l):a.call(null,g,l)}function d(g){return a.g?a.g(g):a.call(null,g)}function e(){return a.v?a.v():a.call(null)}var f=null;f=function(g,l){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,g);case 2:return c.call(this,g,l)}throw Error("Invalid arity: "+arguments.length);};f.v=e;f.g=d;f.h=c;return f}()}};
$CLJS.Ii.g=function(a){return function e(c,d){return new $CLJS.Ld(null,function(){var f;a:{var g=c;for(f=d;;){var l=g;g=$CLJS.I(l,0,null);if(l=$CLJS.y(l))if($CLJS.fd(f,g))g=$CLJS.oc(l);else{f=$CLJS.Jd(g,e($CLJS.oc(l),$CLJS.Gd.h(f,g)));break a}else{f=null;break a}}}return f},null)}(a,$CLJS.fg)};$CLJS.Ii.J=1;
$CLJS.Ji=function Ji(a){switch(arguments.length){case 0:return Ji.v();case 1:return Ji.g(arguments[0]);case 2:return Ji.h(arguments[0],arguments[1]);case 3:return Ji.j(arguments[0],arguments[1],arguments[2]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ji.o(arguments[0],arguments[1],arguments[2],new $CLJS.lc(c.slice(3),0,null))}};$CLJS.Ji.v=function(){return $CLJS.xd};$CLJS.Ji.g=function(a){return a};
$CLJS.Ji.h=function(a,b){return function(){function c(k,m,r){k=b.j?b.j(k,m,r):b.call(null,k,m,r);return a.g?a.g(k):a.call(null,k)}function d(k,m){k=b.h?b.h(k,m):b.call(null,k,m);return a.g?a.g(k):a.call(null,k)}function e(k){k=b.g?b.g(k):b.call(null,k);return a.g?a.g(k):a.call(null,k)}function f(){var k=b.v?b.v():b.call(null);return a.g?a.g(k):a.call(null,k)}var g=null,l=function(){function k(r,v,w,x){var z=null;if(3<arguments.length){z=0;for(var H=Array(arguments.length-3);z<H.length;)H[z]=arguments[z+
3],++z;z=new $CLJS.lc(H,0,null)}return m.call(this,r,v,w,z)}function m(r,v,w,x){r=$CLJS.de(b,r,v,w,x);return a.g?a.g(r):a.call(null,r)}k.J=3;k.I=function(r){var v=$CLJS.A(r);r=$CLJS.C(r);var w=$CLJS.A(r);r=$CLJS.C(r);var x=$CLJS.A(r);r=$CLJS.oc(r);return m(v,w,x,r)};k.o=m;return k}();g=function(k,m,r,v){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,k);case 2:return d.call(this,k,m);case 3:return c.call(this,k,m,r);default:var w=null;if(3<arguments.length){w=0;for(var x=
Array(arguments.length-3);w<x.length;)x[w]=arguments[w+3],++w;w=new $CLJS.lc(x,0,null)}return l.o(k,m,r,w)}throw Error("Invalid arity: "+arguments.length);};g.J=3;g.I=l.I;g.v=f;g.g=e;g.h=d;g.j=c;g.o=l.o;return g}()};
$CLJS.Ji.j=function(a,b,c){return function(){function d(m,r,v){m=c.j?c.j(m,r,v):c.call(null,m,r,v);m=b.g?b.g(m):b.call(null,m);return a.g?a.g(m):a.call(null,m)}function e(m,r){m=c.h?c.h(m,r):c.call(null,m,r);m=b.g?b.g(m):b.call(null,m);return a.g?a.g(m):a.call(null,m)}function f(m){m=c.g?c.g(m):c.call(null,m);m=b.g?b.g(m):b.call(null,m);return a.g?a.g(m):a.call(null,m)}function g(){var m=c.v?c.v():c.call(null);m=b.g?b.g(m):b.call(null,m);return a.g?a.g(m):a.call(null,m)}var l=null,k=function(){function m(v,
w,x,z){var H=null;if(3<arguments.length){H=0;for(var N=Array(arguments.length-3);H<N.length;)N[H]=arguments[H+3],++H;H=new $CLJS.lc(N,0,null)}return r.call(this,v,w,x,H)}function r(v,w,x,z){v=$CLJS.de(c,v,w,x,z);v=b.g?b.g(v):b.call(null,v);return a.g?a.g(v):a.call(null,v)}m.J=3;m.I=function(v){var w=$CLJS.A(v);v=$CLJS.C(v);var x=$CLJS.A(v);v=$CLJS.C(v);var z=$CLJS.A(v);v=$CLJS.oc(v);return r(w,x,z,v)};m.o=r;return m}();l=function(m,r,v,w){switch(arguments.length){case 0:return g.call(this);case 1:return f.call(this,
m);case 2:return e.call(this,m,r);case 3:return d.call(this,m,r,v);default:var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.lc(z,0,null)}return k.o(m,r,v,x)}throw Error("Invalid arity: "+arguments.length);};l.J=3;l.I=k.I;l.v=g;l.g=f;l.h=e;l.j=d;l.o=k.o;return l}()};
$CLJS.Ji.o=function(a,b,c,d){var e=$CLJS.Hd($CLJS.Jd(a,$CLJS.Jd(b,$CLJS.Jd(c,d))));return function(){function f(l){var k=null;if(0<arguments.length){k=0;for(var m=Array(arguments.length-0);k<m.length;)m[k]=arguments[k+0],++k;k=new $CLJS.lc(m,0,null)}return g.call(this,k)}function g(l){l=$CLJS.ae($CLJS.A(e),l);for(var k=$CLJS.C(e);;)if(k){var m=$CLJS.A(k);l=m.g?m.g(l):m.call(null,l);k=$CLJS.C(k)}else return l}f.J=0;f.I=function(l){l=$CLJS.y(l);return g(l)};f.o=g;return f}()};
$CLJS.Ji.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);var d=$CLJS.C(c);c=$CLJS.A(d);d=$CLJS.C(d);return this.o(b,a,c,d)};$CLJS.Ji.J=3;Ki=new $CLJS.M("type","Integer","type/Integer",-638928316);Li=new $CLJS.M("entity","ProductTable","entity/ProductTable",1692844366);Mi=new $CLJS.M("type","IPAddress","type/IPAddress",-808425343);Ni=new $CLJS.M("entity","GenericTable","entity/GenericTable",1152424804);Oi=new $CLJS.M("type","Duration","type/Duration",1970868302);
Pi=new $CLJS.M("type","PK","type/PK",-1723274286);Qi=new $CLJS.M("type","CreationTime","type/CreationTime",-1473681229);Ri=new $CLJS.M("type","Name","type/Name",-134787639);Si=new $CLJS.M("type","BigInteger","type/BigInteger",1152965666);Ti=new $CLJS.M("type","UpdatedTimestamp","type/UpdatedTimestamp",-754878742);Ui=new $CLJS.M("type","URL","type/URL",-1433976351);Vi=new $CLJS.M("type","TimeWithLocalTZ","type/TimeWithLocalTZ",-259094111);$CLJS.Wi=new $CLJS.M("type","Time","type/Time",-814852413);
Xi=new $CLJS.M("entity","TransactionTable","entity/TransactionTable",-888813059);Yi=new $CLJS.M("Coercion","String-\x3eTemporal","Coercion/String-\x3eTemporal",946586714);Zi=new $CLJS.M("type","UpdatedTemporal","type/UpdatedTemporal",-596775941);$CLJS.$i=new $CLJS.M(null,"effective_type","effective_type",1699478099);aj=new $CLJS.M("type","Comment","type/Comment",-1406574403);bj=new $CLJS.M("type","UpdatedDate","type/UpdatedDate",-1756161562);
cj=new $CLJS.M("entity","CompanyTable","entity/CompanyTable",-1166853089);dj=new $CLJS.M("type","CancelationDate","type/CancelationDate",561287015);ej=new $CLJS.M("Coercion","Number-\x3eTemporal","Coercion/Number-\x3eTemporal",-1061171580);fj=new $CLJS.M("type","DruidHyperUnique","type/DruidHyperUnique",-585863040);gj=new $CLJS.M("type","Share","type/Share",-1285033895);hj=new $CLJS.M("type","DeletionTime","type/DeletionTime",-1426373145);ij=new $CLJS.M("type","Source","type/Source",1060815848);
jj=new $CLJS.M("type","JoinTime","type/JoinTime",1290040594);kj=new $CLJS.M("Coercion","YYYYMMDDHHMMSSBytes-\x3eTemporal","Coercion/YYYYMMDDHHMMSSBytes-\x3eTemporal",-624663946);$CLJS.lj=new $CLJS.M(null,"base_type","base_type",1908272670);mj=new $CLJS.M("type","Coordinate","type/Coordinate",1950913043);nj=new $CLJS.M("type","Country","type/Country",2058497652);$CLJS.oj=new $CLJS.M("type","*","type/*",-1283496752);pj=new $CLJS.M("type","User","type/User",832931932);
qj=new $CLJS.M("Coercion","ISO8601-\x3eTime","Coercion/ISO8601-\x3eTime",-1197754849);rj=new $CLJS.M("Coercion","UNIXMicroSeconds-\x3eDateTime","Coercion/UNIXMicroSeconds-\x3eDateTime",1299502399);sj=new $CLJS.M("Coercion","Bytes-\x3eTemporal","Coercion/Bytes-\x3eTemporal",-2023274781);tj=new $CLJS.M("type","DateTimeWithZoneOffset","type/DateTimeWithZoneOffset",1768393068);uj=new $CLJS.M("type","Title","type/Title",1977060721);vj=new $CLJS.M("type","Dictionary","type/Dictionary",1352048818);
wj=new $CLJS.M("type","JoinTimestamp","type/JoinTimestamp",1554527110);xj=new $CLJS.M("type","TextLike","type/TextLike",-1295784190);yj=new $CLJS.M("Coercion","YYYYMMDDHHMMSSString-\x3eTemporal","Coercion/YYYYMMDDHHMMSSString-\x3eTemporal",41716060);zj=new $CLJS.M("type","CancelationTime","type/CancelationTime",1076177064);Aj=new $CLJS.M("type","Owner","type/Owner",1745970850);Bj=new $CLJS.M("type","Latitude","type/Latitude",-1080544141);Cj=new $CLJS.M("type","ZipCode","type/ZipCode",845484542);
$CLJS.Dj=new $CLJS.M("Semantic","*","Semantic/*",-546724996);Ej=new $CLJS.M("type","Price","type/Price",286577051);Fj=new $CLJS.M("type","Array","type/Array",-2060534244);Gj=new $CLJS.M("type","FK","type/FK",360937035);Hj=new $CLJS.M("type","Enum","type/Enum",-1132893505);Ij=new $CLJS.M("type","Birthdate","type/Birthdate",619594666);Jj=new $CLJS.M("type","Text","type/Text",603746827);Kj=new $CLJS.M("type","Discount","type/Discount",109235331);Lj=new $CLJS.M("type","Float","type/Float",1261800143);
Mj=new $CLJS.M("type","SerializedJSON","type/SerializedJSON",300065547);Nj=new $CLJS.M("type","Email","type/Email",-1486863280);Oj=new $CLJS.M("type","Address","type/Address",-413689880);Pj=new $CLJS.M("type","State","type/State",-154641657);Qj=new $CLJS.M("type","JoinDate","type/JoinDate",-793235819);Rj=new $CLJS.M("type","Boolean","type/Boolean",26348630);Sj=new $CLJS.M("type","GrossMargin","type/GrossMargin",1961535799);Tj=new $CLJS.M("type","Income","type/Income",-342566883);
$CLJS.Uj=new $CLJS.M("Relation","*","Relation/*",-706261522);Vj=new $CLJS.M("Coercion","UNIXMilliSeconds-\x3eDateTime","Coercion/UNIXMilliSeconds-\x3eDateTime",-1296453709);Wj=new $CLJS.M("type","UpdatedTime","type/UpdatedTime",-1278858780);Xj=new $CLJS.M("Coercion","ISO8601-\x3eDateTime","Coercion/ISO8601-\x3eDateTime",-1255976090);Yj=new $CLJS.M("type","AvatarURL","type/AvatarURL",-425042887);Zj=new $CLJS.M("type","DeletionTimestamp","type/DeletionTimestamp",-935598691);
ak=new $CLJS.M("type","DeletionTemporal","type/DeletionTemporal",2136289994);bk=new $CLJS.M("type","Product","type/Product",1803490713);ck=new $CLJS.M("Coercion","ISO8601-\x3eDate","Coercion/ISO8601-\x3eDate",-103814729);dk=new $CLJS.M("type","Description","type/Description",-680883950);ek=new $CLJS.M("type","DateTimeWithLocalTZ","type/DateTimeWithLocalTZ",339704031);fk=new $CLJS.M("type","CancelationTemporal","type/CancelationTemporal",-532782161);gk=new $CLJS.M("type","XML","type/XML",-804526569);
hk=new $CLJS.M("type","Structured","type/Structured",-1651495863);ik=new $CLJS.M("type","Subscription","type/Subscription",-1076112474);jk=new $CLJS.M("type","Decimal","type/Decimal",-1657568790);kk=new $CLJS.M("type","DeletionDate","type/DeletionDate",-2052285784);lk=new $CLJS.M("entity","UserTable","entity/UserTable",-1504290772);mk=new $CLJS.M("type","Quantity","type/Quantity",-1936151227);nk=new $CLJS.M("type","Instant","type/Instant",1168385286);
ok=new $CLJS.M("type","PostgresEnum","type/PostgresEnum",-900722397);pk=new $CLJS.M("type","UUID","type/UUID",1767712212);qk=new $CLJS.M("type","CreationDate","type/CreationDate",-1102411433);rk=new $CLJS.M("type","JoinTemporal","type/JoinTemporal",-1465575331);sk=new $CLJS.M("type","CreationTimestamp","type/CreationTimestamp",-687400081);tk=new $CLJS.M("type","Cost","type/Cost",363698341);uk=new $CLJS.M("Coercion","UNIXTime-\x3eTemporal","Coercion/UNIXTime-\x3eTemporal",-968093468);
vk=new $CLJS.M("type","Longitude","type/Longitude",-196788672);wk=new $CLJS.M("type","TimeWithTZ","type/TimeWithTZ",-442869120);$CLJS.xk=new $CLJS.M("type","Date","type/Date",-690428629);yk=new $CLJS.M("type","Score","type/Score",188189565);zk=new $CLJS.M("type","Temporal","type/Temporal",-237169010);Ak=new $CLJS.M("type","Currency","type/Currency",713609092);Bk=new $CLJS.M("type","Category","type/Category",-264699550);Ck=new $CLJS.M("entity","SubscriptionTable","entity/SubscriptionTable",1660366729);
Dk=new $CLJS.M("type","CreationTemporal","type/CreationTemporal",-1324176405);Ek=new $CLJS.M("entity","EventTable","entity/EventTable",-2132834802);Fk=new $CLJS.M("type","Author","type/Author",-836053084);Gk=new $CLJS.M("type","ImageURL","type/ImageURL",2081541690);Hk=new $CLJS.M("type","Number","type/Number",-2071693690);Ik=new $CLJS.M("type","DateTimeWithZoneID","type/DateTimeWithZoneID",-1588069560);Jk=new $CLJS.M("type","MongoBSONID","type/MongoBSONID",663302764);
Kk=new $CLJS.M("type","JSON","type/JSON",-14729800);Lk=new $CLJS.M("Coercion","UNIXSeconds-\x3eDateTime","Coercion/UNIXSeconds-\x3eDateTime",-1635093627);$CLJS.Mk=new $CLJS.M("type","DateTime","type/DateTime",352113310);Nk=new $CLJS.M("Coercion","ISO8601-\x3eTemporal","Coercion/ISO8601-\x3eTemporal",410003391);Fi=new $CLJS.M("Coercion","*","Coercion/*",1713686116);Ok=new $CLJS.M("type","CancelationTimestamp","type/CancelationTimestamp",-741584330);
Pk=new $CLJS.M("type","Location","type/Location",-1929284186);Qk=new $CLJS.M("type","Company","type/Company",-1114287726);Rk=new $CLJS.M("type","TimeWithZoneOffset","type/TimeWithZoneOffset",959948573);Sk=new $CLJS.M("type","DateTimeWithTZ","type/DateTimeWithTZ",-1919106635);Tk=new $CLJS.M("entity","GoogleAnalyticsTable","entity/GoogleAnalyticsTable",910090965);Uk=new $CLJS.M("type","Collection","type/Collection",1447925820);Vk=new $CLJS.M("type","City","type/City",420361040);W.h(Ni,new $CLJS.M("entity","*","entity/*",-2043291259));W.h(lk,Ni);W.h(cj,Ni);W.h(Xi,Ni);W.h(Li,Ni);W.h(Ck,Ni);W.h(Ek,Ni);W.h(Tk,Ni);W.h(Hk,$CLJS.oj);W.h(Ki,Hk);W.h(Si,Ki);W.h(mk,$CLJS.Dj);W.h(mk,Ki);W.h(Lj,Hk);W.h(jk,Lj);W.h(gj,$CLJS.Dj);W.h(gj,Lj);W.h(Ak,jk);W.h(Ak,$CLJS.Dj);W.h(Tj,Ak);W.h(Kj,Ak);W.h(Ej,Ak);W.h(Sj,Ak);W.h(tk,Ak);W.h(Pk,$CLJS.Dj);W.h(mj,Pk);W.h(mj,Lj);W.h(Bj,mj);W.h(vk,mj);W.h(yk,$CLJS.Dj);W.h(yk,Hk);W.h(Oi,$CLJS.Dj);W.h(Oi,Hk);W.h(Jj,$CLJS.oj);W.h(pk,Jj);W.h(Ui,$CLJS.Dj);
W.h(Ui,Jj);W.h(Gk,Ui);W.h(Yj,Gk);W.h(Nj,$CLJS.Dj);W.h(Nj,Jj);W.h(Bk,$CLJS.Dj);W.h(Hj,$CLJS.Dj);W.h(Oj,Pk);W.h(Vk,Oj);W.h(Vk,Bk);W.h(Vk,Jj);W.h(Pj,Oj);W.h(Pj,Bk);W.h(Pj,Jj);W.h(nj,Oj);W.h(nj,Bk);W.h(nj,Jj);W.h(Cj,Oj);W.h(Cj,Jj);W.h(Ri,Bk);W.h(Ri,Jj);W.h(uj,Bk);W.h(uj,Jj);W.h(dk,$CLJS.Dj);W.h(dk,Jj);W.h(aj,$CLJS.Dj);W.h(aj,Jj);W.h(ok,Jj);W.h(zk,$CLJS.oj);W.h($CLJS.xk,zk);W.h($CLJS.Wi,zk);W.h(wk,$CLJS.Wi);W.h(Vi,wk);W.h(Rk,wk);W.h($CLJS.Mk,zk);W.h(Sk,$CLJS.Mk);W.h(ek,Sk);W.h(tj,Sk);W.h(Ik,Sk);
W.h(nk,ek);W.h(Dk,$CLJS.Dj);W.h(sk,Dk);W.h(sk,$CLJS.Mk);W.h(Qi,Dk);W.h(Qi,$CLJS.Wi);W.h(qk,Dk);W.h(qk,$CLJS.xk);W.h(rk,$CLJS.Dj);W.h(wj,rk);W.h(wj,$CLJS.Mk);W.h(jj,rk);W.h(jj,$CLJS.Wi);W.h(Qj,rk);W.h(Qj,$CLJS.xk);W.h(fk,$CLJS.Dj);W.h(Ok,fk);W.h(Ok,$CLJS.Mk);W.h(zj,fk);W.h(zj,$CLJS.xk);W.h(dj,fk);W.h(dj,$CLJS.xk);W.h(ak,$CLJS.Dj);W.h(Zj,ak);W.h(Zj,$CLJS.Mk);W.h(hj,ak);W.h(hj,$CLJS.Wi);W.h(kk,ak);W.h(kk,$CLJS.xk);W.h(Zi,$CLJS.Dj);W.h(Ti,Zi);W.h(Ti,$CLJS.Mk);W.h(Wj,Zi);W.h(Wj,$CLJS.Wi);W.h(bj,Zi);
W.h(bj,$CLJS.xk);W.h(Ij,$CLJS.Dj);W.h(Ij,$CLJS.xk);W.h(Rj,$CLJS.oj);W.h(fj,$CLJS.oj);W.h(xj,$CLJS.oj);W.h(Jk,xj);W.h(Mi,xj);W.h(Mi,$CLJS.Dj);W.h(Uk,$CLJS.oj);W.h(hk,$CLJS.oj);W.h(vj,Uk);W.h(Fj,Uk);W.h(Kk,hk);W.h(Kk,Uk);W.h(gk,hk);W.h(gk,Uk);W.h(hk,$CLJS.Dj);W.h(hk,Jj);W.h(Mj,hk);W.h(gk,hk);W.h(pj,$CLJS.Dj);W.h(Fk,pj);W.h(Aj,pj);W.h(bk,Bk);W.h(Qk,Bk);W.h(ik,Bk);W.h(ij,Bk);W.h(Gj,$CLJS.Uj);W.h(Pi,$CLJS.Uj);W.h(Yi,Fi);W.h(Nk,Yi);W.h(Xj,Nk);W.h(qj,Nk);W.h(ck,Nk);W.h(yj,Yi);W.h(sj,Fi);W.h(kj,sj);
W.h(ej,Fi);W.h(uk,ej);W.h(Lk,uk);W.h(Vj,uk);W.h(rj,uk);
var Xk=$CLJS.Fh($CLJS.uf.h($CLJS.ie,function Wk(a){return new $CLJS.Ld(null,function(){for(;;){var c=$CLJS.y(a);if(c){if($CLJS.ad(c)){var d=$CLJS.Rb(c),e=$CLJS.D(d),f=$CLJS.Od(e);a:for(var g=0;;)if(g<e){var l=$CLJS.Pc(d,g);l=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kg(l),$CLJS.mi(l)],null);f.add(l);g+=1}else{d=!0;break a}return d?$CLJS.Rd($CLJS.Td(f),Wk($CLJS.Sb(c))):$CLJS.Rd($CLJS.Td(f),null)}f=$CLJS.A(c);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kg(f),$CLJS.mi(f)],null),Wk($CLJS.oc(c)))}return null}},
null)}($CLJS.Ii.g($CLJS.Ai(Hi,$CLJS.G([new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oj,$CLJS.Dj,$CLJS.Uj],null)]))))));yi(rj,new $CLJS.R(null,new $CLJS.n(null,2,[Ki,null,jk,null],null),null),nk);yi(Vj,new $CLJS.R(null,new $CLJS.n(null,2,[Ki,null,jk,null],null),null),nk);yi(Lk,new $CLJS.R(null,new $CLJS.n(null,2,[Ki,null,jk,null],null),null),nk);yi(ck,Jj,$CLJS.xk);yi(Xj,Jj,$CLJS.Mk);yi(qj,Jj,$CLJS.Wi);yi(yj,Jj,$CLJS.Mk);var Yk=$CLJS.wh.j,Zk=$CLJS.ui(Ci,$CLJS.Ei),$k;
a:for(var al=ti($CLJS.oj),bl=new $CLJS.ze(null,-1,$CLJS.gg([kj]),null),cl=$CLJS.Kb($CLJS.ie),dl=$CLJS.y(al),el=$CLJS.y(bl);;)if(dl&&el){var fl=$CLJS.xf.j(cl,$CLJS.A(dl),$CLJS.A(el)),gl=$CLJS.C(dl),hl=$CLJS.C(el);cl=fl;dl=gl;el=hl}else{$k=$CLJS.Mb(cl);break a}Yk.call($CLJS.wh,$CLJS.gi,Zk,$k);$CLJS.wh.O($CLJS.fi,$CLJS.Cg,kj,$CLJS.Mk);
module.exports={isa:function(a,b){return $CLJS.si($CLJS.yg.g(a),$CLJS.yg.g(b))},coercions_for_type:function(a){a=$CLJS.uf.j($CLJS.B,$CLJS.Ji.h($CLJS.Ii.v(),pi),$CLJS.dg(Gi($CLJS.yg.g(a))));return $CLJS.Fh($CLJS.xg.h(function(b){return[$CLJS.Kd(b),"/",$CLJS.kg(b)].join("")},a))},is_coerceable:function(a){return $CLJS.cd($CLJS.ke(Gi($CLJS.yg.g(a))))},TYPE:Xk};