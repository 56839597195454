var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var rG=function(){},tG=function(a,b){return $CLJS.Ih(1,$CLJS.sG.h(new $CLJS.ze(null,-1,a,null),b))},uG=function(a){return $CLJS.K.h($CLJS.eF,a).value},wG=function(a){var b=uG(a);return $CLJS.p(function(){var c=uG($CLJS.qF);return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.mG:$CLJS.p(function(){var c=uG($CLJS.rF);return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.oG:$CLJS.p(function(){var c=uG($CLJS.Lh);return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.cG:$CLJS.p(function(){var c=uG($CLJS.yF);
return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.jG:$CLJS.p(function(){var c=uG($CLJS.nF);return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.lG:$CLJS.p(function(){var c=uG($CLJS.mF);return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.eG:$CLJS.p(function(){var c=uG($CLJS.pF);return vG.h?vG.h(c,b):vG.call(null,c,b)}())?$CLJS.hG:$CLJS.fG},xG=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.q.g(b),$CLJS.q.g(" ")].join(""),a],null)},yG=function(a,b,c){var d=
$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.q.g(d),"%c",$CLJS.q.g(b),"%c"].join(""),$CLJS.Gd.o(a,["color:",$CLJS.q.g($CLJS.K.h($CLJS.qG,c))].join(""),$CLJS.G(["color:black"]))],null)},zG=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.q.g(d),"%c",$CLJS.q.g(b),"%c"].join(""),$CLJS.Gd.o(a,["color:",$CLJS.q.g($CLJS.K.h($CLJS.qG,$CLJS.bG)),";background-color:",$CLJS.q.g($CLJS.K.h($CLJS.qG,c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],
null)},AG=function(a){function b(c,d){return d>=c}a=uG(a);if(b(uG($CLJS.qF),a))return"error";if(b(uG($CLJS.rF),a))return"warn";if(b(uG($CLJS.Lh),a))return"info";b(uG($CLJS.yF),a);return"log"},BG=function(a){return function(b){var c=$CLJS.je(b),d=$CLJS.K.h(c,$CLJS.xF),e=$CLJS.K.h(c,$CLJS.iF);b=$CLJS.K.h(c,$CLJS.kF);e=AG(e);e=$CLJS.xa(console,e);e=$CLJS.p(e)?e:console.log;$CLJS.ae(e,a.g?a.g(c):a.call(null,c));return $CLJS.p(b)?(c=["[",$CLJS.q.g(d),"]"].join(""),d=$CLJS.q.g(b),b=b.stack,e.O?e.O(c,d,
"\n",b):e.call(null,c,d,"\n",b)):null}},vG=function vG(a){switch(arguments.length){case 1:return vG.g(arguments[0]);case 2:return vG.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vG.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};vG.g=function(){return!0};vG.h=function(a,b){return a<=b};vG.o=function(a,b,c){for(;;)if(a<=b)if($CLJS.C(c))a=b,b=$CLJS.A(c),c=$CLJS.C(c);else return b<=$CLJS.A(c);else return!1};
vG.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};vG.J=2;$CLJS.sG=function sG(a){switch(arguments.length){case 0:return sG.v();case 1:return sG.g(arguments[0]);case 2:return sG.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sG.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};$CLJS.sG.v=function(){return $CLJS.B};
$CLJS.sG.g=function(a){return new $CLJS.Ld(null,function(){return a},null)};$CLJS.sG.h=function(a,b){return new $CLJS.Ld(null,function(){var c=$CLJS.y(a),d=$CLJS.y(b);return c&&d?$CLJS.Jd($CLJS.A(c),$CLJS.Jd($CLJS.A(d),$CLJS.sG.h($CLJS.oc(c),$CLJS.oc(d)))):null},null)};$CLJS.sG.o=function(a,b,c){return new $CLJS.Ld(null,function(){var d=$CLJS.xg.h($CLJS.y,$CLJS.Gd.o(c,b,$CLJS.G([a])));return $CLJS.re($CLJS.xd,d)?$CLJS.th.h($CLJS.xg.h($CLJS.A,d),$CLJS.ae($CLJS.sG,$CLJS.xg.h($CLJS.oc,d))):null},null)};
$CLJS.sG.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};$CLJS.sG.J=2;
var CG=function CG(a,b){for(;;){if($CLJS.E.h($CLJS.dG,b))return yG(a,", ",$CLJS.fG);if($CLJS.E.h($CLJS.pG,b))return xG(a);if(b instanceof $CLJS.M)return yG(a,b,$CLJS.cG);if(b instanceof $CLJS.t)return yG(a,b,$CLJS.jG);if("string"===typeof b)return yG(a,$CLJS.Eg($CLJS.G([b])),$CLJS.iG);if($CLJS.Pe(b)){var d=xG(function(){var f=a,g=$CLJS.kb(b);return CG.h?CG.h(f,g):CG.call(null,f,g)}()),e=$CLJS.lb(b);return CG.h?CG.h(d,e):CG.call(null,d,e)}if(b instanceof $CLJS.n||b instanceof $CLJS.Xf)return d=a,d=
yG(d,"{",$CLJS.kG),d=$CLJS.qd(CG,d,tG($CLJS.dG,b)),yG(d,"}",$CLJS.kG);if($CLJS.Xc(b))return d=a,d=yG(d,["#",$CLJS.q.g(function(){var f=$CLJS.Na(b),g=f.name;return $CLJS.Tc(g)?$CLJS.Eg($CLJS.G([f])):g}())," "].join(""),$CLJS.nG),d=yG(d,"{",$CLJS.kG),d=$CLJS.qd(CG,d,tG($CLJS.dG,b)),yG(d,"}",$CLJS.kG);if($CLJS.Vc(b))return d=a,d=yG(d,"#{",$CLJS.kG),d=$CLJS.qd(CG,d,tG($CLJS.pG,b)),yG(d,"}",$CLJS.kG);if($CLJS.Zc(b))return d=a,d=yG(d,"[",$CLJS.kG),d=$CLJS.qd(CG,d,tG($CLJS.pG,b)),yG(d,"]",$CLJS.kG);if($CLJS.Hh(b))return d=
a,d=yG(d,"(",$CLJS.nG),d=$CLJS.qd(CG,d,tG($CLJS.pG,b)),yG(d,")",$CLJS.nG);if(null!=b?b.M&16384||$CLJS.gc===b.Od||(b.M?0:$CLJS.Ma(rG,b)):$CLJS.Ma(rG,b))d=yG(a,"#atom ",$CLJS.nG),e=$CLJS.pb(b),a=d,b=e;else if(null!=b&&$CLJS.gc===b.Qc)d=yG(a,"#uuid ",$CLJS.nG),e=$CLJS.q.g(b),a=d,b=e;else if(null!=b&&b.constructor===Object)d=yG(a,"#js ",$CLJS.nG),e=$CLJS.qd(function(f,g){return function(l,k){return $CLJS.Cg.j(l,$CLJS.yg.g(k),$CLJS.xa(g,k))}}(a,b),$CLJS.ie,Object.keys(b)),a=d,b=e;else if($CLJS.Ka(b))d=
yG(a,"#js ",$CLJS.nG),e=$CLJS.uf.h($CLJS.Te,b),a=d,b=e;else return yG(a,$CLJS.Eg($CLJS.G([b])),$CLJS.gG)}};$CLJS.DG=BG(function(a){a=$CLJS.je(a);$CLJS.K.h(a,$CLJS.iF);var b=$CLJS.K.h(a,$CLJS.xF),c=$CLJS.K.h(a,$CLJS.oF);$CLJS.K.h(a,$CLJS.kF);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.q.g(b),"]"].join(""),c],null)});
$CLJS.EG=BG(function(a){var b=$CLJS.je(a),c=$CLJS.K.h(b,$CLJS.iF);a=$CLJS.K.h(b,$CLJS.xF);var d=$CLJS.K.h(b,$CLJS.oF);$CLJS.K.h(b,$CLJS.kF);b=wG(c);d=CG(xG(zG(zG(zG(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Te],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Jd(a,d)});
$CLJS.FG=BG(function(a){a=$CLJS.je(a);$CLJS.K.h(a,$CLJS.iF);var b=$CLJS.K.h(a,$CLJS.xF),c=$CLJS.K.h(a,$CLJS.oF);$CLJS.K.h(a,$CLJS.kF);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.q.g(b),"]"].join(""),$CLJS.Eg($CLJS.G([c]))],null)});