import { DELETE, GET, POST, PUT } from "metabase/lib/new-api";


export const CreateFgaProject = async params => {
  return POST("/api/v1/fga/project", params);
};

/**
 *
 * @param {*} params {projectId,name,creatorId,protocolSlug,protocolName}
 * @returns
 */
export const UpdateFgaProject = async params => {
  return PUT("/api/v1/fga/project", params);
};

export const GetFgaProject = async params => {
  return GET("/api/v1/fga/project", params);
};

export const GetFgaConnectorJob = async params => {
  return POST("/api/v1/fga/connector-config/connection/job", params);
};

export const GetFgaConnectors = async params => {
  return GET("/api/v1/fga/connector-config", params);
};

export const getAvailableConnectors = async params => {
  return POST(`/api/v1/fga/connector-config/available`, params);
};

export const addConnectors = async params => {
  return POST(`/api/v1/fga/connector-config/connection/community`, params);
};

export const postDatabaseSource = async params => {
  return POST(`/api/v1/fga/connector-config/source`, params);
};

export const createDatabaseConnection = async params => {
  return POST(`/api/v1/fga/connector-config/connection`, params);
};

export const updateDatabaseConnection = async params => {
  return PUT(`/api/v1/fga/connector-config/connection`, params);
};

export const databaseConnectionSync = async params => {
  return POST(`/api/v1/fga/connector-config/connection/sync/manual`, params);
};

export const getDatabaseConnection = async params => {
  return GET(`/api/v1/fga/connector-config/connection`, params);
};

export const deleteDatabaseConnection = async params => {
  return DELETE(`/api/v1/fga/connector-config/connection`, params);
};

export const connectionSourceTest = async params => {
  return POST(`/api/v1/fga/connector-config/source/test`, params);
};

export const connectionList = async params => {
  return GET(`/api/v1/fga/connector-config/connection/list`, params);
};
export const postProject = async params => {
  return POST(`/api/v1/project`, params);
};
export const getProtocolDetail = async params => {
  return GET(`/api/v1/project/protocol/detail`, params);
};
export const getEcosystemGameList = async params => {
  return GET(`/api/v1/ecosystem/game/list`, params);
};
export const getProjectDashboardList = async params => {
  return POST(`/api/v1/project/dashboards`, params);
};
export const assignDashboardFromProject = async params => {
  return POST(`/api/v1/project/assign/dashboard`, params);
};
export const removeDashboardFromProject = async params => {
  return POST(`/api/v1/project/remove/dashboard`, params);
};
export const getProjectUserList = async params => {
  return POST(`/api/v1/project/users`, params);
};
export const assignUserToProject = async params => {
  return POST(`/api/v1/project/assign/user`, params);
};
export const removeUserFromProject = async params => {
  return POST(`/api/v1/project/remove/user`, params);
};
export const assignProjectRole = async params => {
  return POST(`/api/v1/project/assign/role`, params);
};
export const removeProjectRole = async params => {
  return POST(`/api/v1/project/remove/role`, params);
};
export const sourceFileUploadStatus = async params => {
  return GET(`/api/v1/fga/connector-config/source/file/upload/status`, params);
};
export const refreshConnectionInfo = async params => {
  return POST(`/api/v1/fga/connector-config/connection/refresh`, params);
};
// FP Api ---------------

export const getTable = async params => {
  return POST(`/api/v1/database/table/list`, params);
};

export const getFavorite = async ({ params = {} }) => {
  return GET(`/api/v1/favorite`, params);
};

export const copyCard = async ({ params, cardId }) => {
  return POST(`/api/v1/card/${cardId}/copy`, params);
};

export const searchCards = async params => {
  return POST(`/api/v1/card/search`, params);
};

export const quickRegister = async params => {
  return POST(`/api/v1/quick/register`, params);
};

export const tableSearchV2 = async params => {
  return POST(`/api/v1/database/table/search`, params);
};

export const fetchTableCategory = async params => {
  return POST(`/api/v1/database/table/category`, params);
};

export const elasticSearch = async params => {
  return POST(`/api/v1/elasticsearch/search`, params);
};

export const navigationSearch = async params => {
  return POST(`/api/v1/navigation/search`, params);
};

export const navigationNum = async params => {
  return POST(`/api/v1/navigation/num`, params);
};

export const dashboardIdInfo = async params => {
  return POST(`/api/v1/dashboard/basic`, params, { silent: true });
};

export const chartIdInfo = async params => {
  return POST(`/api/v1/card/basic`, params, { silent: true });
};

export const dashboardParams = async params => {
  return GET(
    `api/v1/dashboard/${params.dashboardId}/params/${params.paramId}/values`,
    { pageSize: 100, current: 1 },
  );
};

export const publicDashboardParams = async params => {
  return GET(
    `api/v1/public/dashboard/${params.dashboardId}/params/${params.paramId}/values`,
    { pageSize: 100, current: 1 },
  );
};

export const loadAppConfig = params => {
  return GET(`api/v1/config`, params, { silent: true });
};

export const chartInfo = params => {
  return POST(`api/v1/dataDictionary/chartInfo`, params);
};

export const cardDownload = (params, config) => {
  const { cardId, parameters, type } = params;
  const formData = new FormData();
  formData.append("parameters", parameters || "");
  formData.append("type", type);
  return POST(`api/v1/card/${cardId}/download`, formData, config);
};

export const datasetDownload = (params, config) => {
  const { query, visualization_settings, type } = params;
  const formData = new FormData();
  formData.append("query", query);
  formData.append("visualization_settings", visualization_settings);
  formData.append("type", type);
  return POST(`api/v1/card/dataset/download`, formData, config);
};

export const getContractSubmittedList = params => {
  return GET(`/api/v1/contract/submitted/list`, params);
};

export const getContractProtocolByAddress = params => {
  return GET(`/api/v1/protocol/find/list`, params);
};

export const journeyPathAnalyze = (params) => {
  return POST(`api/v1/ab/user-journey/path-analyze`, params);
};

export const journeyPathUserDetail = (params) => {
  return POST(`api/v1/ab/user-journey/path-user-detail`, params);
};

export const journeyPathUserTrend = (params) => {
  return POST(`api/v1/ab/user-journey/path-user-trend`, params);
};

export const searchUser = (params) => {
  return POST('api/v1/user/search', params)
}

export const searchDashboard = (params) => {
  return POST('api/v1/dashboard/search', params)
}

export const addDashboardToMenuConfig = (params) => {
  return POST('api/v1/project/addDashboardToMenuConfig', params)
}

export const editDashboardToMenuConfig = (params) => {
  return POST('api/v1/project/editDashboardToMenuConfig', params)
}

export const removeDashboardToMenuConfig = (params) => {
  return POST('api/v1/project/removeDashboardToMenuConfig', params)
}

export const queryStandardDashboards = (params) => {
  return POST('api/v1/dashboard/queryStandardDashboards', params)
}

export const getToolMenu = (params) => {
  return GET('api/v1/standardPanel/getToolMenu', params)
}

export const updateProject = (params) => {
  return POST('api/v1/project/update', params)
}

export const delRedisKey = (params) => {
  return POST('api/v1/custom/data/del/key', params)
}

export const getPageData = (params) => {
  return GET('api/v1/standardPanel/pageConfig/getPageData', params)
}

export const getUDTableList = (params) => {
  return GET('api/v1/fga/connector-config/connection/userdefind/list', params)
}

export const removeUDTable = (params) => {
  return DELETE('api/v1/fga/connector-config/connection/userdefind/file', { data: params })
}

export const setTablePermissions = (params) => {
  return POST('api/v1/table/permissions', params)
}

export const waitSync = (params) => {
  return GET('api/v1/fga/connector-config/connection/wait/sync', params)
}

export const schemaUpdate = (params) => {
  return POST('api/v1/fga/connector-config/connection/schema/update', params)
}

export const clearConnection = (params) => {
  return POST('api/v1/fga/connector-config/connection/s3/clear', params)
}
