export const getProject = location => {
  return "animoca";
};
// todo delete
export const isDefi360 = project => {
  return false;
  // return (project ? project : getProject()) === "defi360";
};

export const getNeedReplaceProjectSQL = () => {
  // return null;
  const GAUserSStr = window.localStorage.getItem("GAUserS");
  if (GAUserSStr === 'true') {
    return null;
  }
  // 如果是Management project，不需要替换
  const latestGAProject = window.localStorage.getItem("LatestGAProject") || "";
  if (latestGAProject === "Management") {
    return null;
  }
  if (window.location.pathname.startsWith("/ab/dashboard") ||
    window.location.pathname.startsWith("/dashboard") ||
    window.location.pathname.startsWith("/@")) {
    return null
  }
  return getLatestGAProjectId();
};

export const getLatestGAProjectId = () => {
  if (!window.location.pathname.startsWith("/ab/project")) {
    return null;
  }
  const projectIdStr = window.localStorage.getItem("LatestGAProjectId");
  let projectId;
  try {
    projectId = projectIdStr && parseInt(projectIdStr);
  } catch (e) {}
  return projectId;
};

export const getLatestGAProjectName = () => {
  if (!window.location.pathname.startsWith("/ab/project")) {
    return null;
  }
  if (isWeb2Path()) {
    return window.localStorage.getItem("LatestWeb2SubProject") || "";
  }
  return window.localStorage.getItem("LatestGAProject") || "";
};

export const isWeb2Path = () => {
  const path = window.location.pathname;
  const web2PathArray = [
    "/monetization",
    "/user-acquisition",
    "/user-engagement",
    "/user-retention",
    "/anichess_pv_p_dashboard",
    "/anichess_pv_p_leaderboard",
    "/anichess_pv_p_game_log",
    "/anichess_pv_p_perm_code_usage",
  ];
  return web2PathArray.some(item => {
    return path.includes(item);
  })
};
