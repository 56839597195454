/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const SeriesDatetimeComponent = props => {
  const { setValue, value, onChangeCallback, dateRange } = props;
  console.log("value", value)
  console.log("dateRange", dateRange)
  const defaultDateRange = value?.startsWith("past")?[]:value?.split("~")??[];
  console.log("defaultDateRange", defaultDateRange)
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > dayjs().endOf('day')||current<dayjs('2010-01-01');
  };

  // Display format for UI
  const displayFormat = "YYYY-MM-DD HH";
  // Internal format for data
  const dataFormat = "YYYY-MM-DDTHH";

  return (
    <>
      <DatePicker.RangePicker
        bordered={false}
        format={displayFormat}
        allowClear={false}
        disabledDate={disabledDate}
        defaultValue={
          defaultDateRange?.length > 1?[dayjs(defaultDateRange[0]),dayjs(defaultDateRange[1])]:null
        }
        value={
          value?.startsWith("past")?null:  (value ? [dayjs(dateRange[0]),dayjs(dateRange[1])] : [])
        }
        showTime={{
          format: 'HH',
        }}
        onChange={(values, formatString) => {
          if (values) {
            // Convert to the required format with 'T'
            const formattedDates = values.map(date => date.format(dataFormat));
            onChangeCallback(formattedDates);
            setValue(`${formattedDates[0]}~${formattedDates[1]}`);
          }
        }}
      />
    </>
  );
};

export default SeriesDatetimeComponent;
