/* eslint-disable curly */
import axios from "axios";
import { message } from "antd";
import { saveAs } from "file-saver";
import * as Sentry from "@sentry/react";
import api from "metabase/lib/api";
import { getLatestGAProjectId, getLatestGAProjectName } from "metabase/lib/project_info";
import { getNeedReplaceProjectSQL } from './project_info';

axios.defaults.baseURL = api.basename;
axios.defaults.headers.put["Content-Type"] = "application/json; charset=utf-8";

const getTime = () => {
  return Date.now();
};

const saveStream = (headers, data) => {
  const filename = headers["content-disposition"]
    ?.match(/".*"/)[0]
    ?.replace(/"/g, "");
  let blob;
  if (filename.endsWith("csv")) {
    blob = new Blob([data]);
  } else if (filename.endsWith("xlsx")) {
    blob = new Blob([data], { type: "application/octet-stream" });
  } else if (filename.endsWith("json")) {
    blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });
  }
  saveAs(blob, filename);
};

axios.interceptors.request.use(config => {
  // Start Sentry transaction for HTTP request
  const transaction = Sentry.startTransaction({
    op: "http.request",
    name: `${config.method?.toUpperCase() || 'REQUEST'} ${config.url}`,
  });

  // Add transaction to request config for later use
  config._sentryTransaction = transaction;

  const headers = config?.headers || {};
  const requestime = getTime();
  const needReplaceProjectId = getNeedReplaceProjectSQL();
  const latestGAProjectId = getLatestGAProjectId();
  const latestGAProjectName = getLatestGAProjectName();
  const projectIdObject = needReplaceProjectId
    ? { fgaProjectId: needReplaceProjectId }
    : {};
  const projectNameObject = latestGAProjectName
    ? { fgaProjectName: latestGAProjectName}
    : {};
  const identificationProjectIdObject = latestGAProjectId
    ? { tbProjectId: latestGAProjectId}
    : {};
  return {
    ...config,
    ...{ requestime: requestime },
    headers: {
      ...headers,
      common: {
        ...headers?.common,
        client_request_time: requestime,
        ...projectIdObject,
        ...projectNameObject,
        ...identificationProjectIdObject,
      },
    },
  };
});

axios.interceptors.response.use(
  async response => {
    // Finish Sentry transaction if it exists
    if (response.config._sentryTransaction) {
      // Add response status as span data
      response.config._sentryTransaction.setData("status", response.status);
      response.config._sentryTransaction.setData("statusText", response.statusText);
      // Finish the transaction
      response.config._sentryTransaction.finish();
    }

    const { data, config, headers } = response;
    if (data instanceof Blob) {
      const text = await data.text();
      if (text.includes('"code":-1') || text.includes('"code":503')) {
        try {
          const messageStr = JSON.parse(text).message
          if (!config.silent) {
            message.error(messageStr);
          }
          // Capture error in Sentry
          Sentry.captureMessage(`API Error: ${messageStr}`, "error");
          return Promise.reject(messageStr);
        } catch (e) {
          // Capture parsing error in Sentry
          Sentry.captureException(e);
        }
      }
    }
    if (headers["content-type"] === "application/octet-stream") {
      saveStream(headers, data);
      return data;
    }
    if (data.code) {
      if (!config.silent) {
        message.error(data.message);
      }
      // Capture API error in Sentry
      Sentry.captureMessage(`API Error Code ${data.code}: ${data.message}`, "error");
      return Promise.reject(data.message);
    }
    return data.data;
  },
  error => {
    // Finish Sentry transaction if it exists
    if (error.config && error.config._sentryTransaction) {
      error.config._sentryTransaction.setStatus("error");
      error.config._sentryTransaction.finish();
    }

    // Capture error in Sentry with additional context
    Sentry.withScope(scope => {
      if (error.response) {
        scope.setExtra("status", error.response.status);
        scope.setExtra("statusText", error.response.statusText);
        scope.setExtra("url", error.config.url);
        scope.setExtra("method", error.config.method);
      }
      Sentry.captureException(error);
    });

    errorHandle(error);
    return Promise.reject(error);
  },
);

function errorHandle(err) {
  if (err.response) {
    const { config } = err.response;
    switch (err.response.status) {
      case 401:
        message.error({
          content: "You does not have permission to access",
          key: "permission",
        });
        break;
      default:
        if (!config?.params?.silentFp) {
          message.error("Service exception, please contact the administrator");
        }
    }
  } else {
    message.error("Service exception, please contact the administrator");
  }
}

export const axiosInstance = axios;

export const GET = async (url, params) => {
  return await axios.get(url, { params });
};

export const POST = async (url, params, config) => {
  return await axios.post(url, params, config);
};

export const PUT = async (url, params, config) => {
  return await axios.put(url, params, config);
};

export const DELETE = async (url, params) => {
  return await axios.delete(url, params);
};
