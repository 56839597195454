var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var NJ=function(a){var b=new $CLJS.Ba;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.q.g($CLJS.A(a))),a=$CLJS.C(a);else return b.toString()},OJ=function(a){var b=$CLJS.Mb($CLJS.wd(function(c,d,e){return $CLJS.xf.j(c,$CLJS.yg.g?$CLJS.yg.g(d):$CLJS.yg.call(null,d),e)},$CLJS.Kb($CLJS.ie),a));return $CLJS.J(b,$CLJS.Sc(a))},QJ=function(a){var b=PJ;return $CLJS.J($CLJS.Mb($CLJS.wd(function(c,d,e){return $CLJS.xf.j(c,d,b.g?b.g(e):b.call(null,e))},null!=a&&(a.M&4||$CLJS.gc===a.Ac)?$CLJS.Kb(a):
$CLJS.Kb($CLJS.ie),a)),$CLJS.Sc(a))},TJ=function(a){var b=RJ;if("string"===typeof a)return SJ(b,a);throw new TypeError("re-seq must match against a string.");},UJ=function(a){return new $CLJS.Ld(null,function(){return $CLJS.Jd(a.v?a.v():a.call(null),UJ(a))},null)},VJ=function(a,b){return new $CLJS.Ld(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c),e=a.g?a.g(d):a.call(null,d),f=$CLJS.Jd(d,$CLJS.lg(function(g){return $CLJS.E.h(e,a.g?a.g(g):a.call(null,g))},$CLJS.C(c)));return $CLJS.Jd(f,VJ(a,
new $CLJS.Ld(null,function(){return $CLJS.Ih($CLJS.D(f),c)},null)))}return null},null)},WJ=function(a,b,c){var d=$CLJS.QC(a,/\{0\}/);d=$CLJS.E.h($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.og(/.*\{0\}.*/,a);return $CLJS.uC.ngettext($CLJS.uC.msgid($CLJS.Fh(d),$CLJS.p(a)?c:""),b,c)},XJ=function(a,b){switch(a){case "minutes":return WJ("Minute","Minutes",b);case "hours":return WJ("Hour","Hours",b);case "days":return WJ("Day","Days",b);case "weeks":return WJ("Week","Weeks",b);case "months":return WJ("Month",
"Months",b);case "quarters":return WJ("Quarter","Quarters",b);case "years":return WJ("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));}},YJ=function(a,b,c){b=parseInt(b);c=XJ(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.E.h(b,1)],null);if($CLJS.E.h(new $CLJS.P(null,2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.aD("Previous {0}",$CLJS.G([c]));if($CLJS.E.h(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.aD("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.E.h(new $CLJS.P(null,
2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.aD("Next {0}",$CLJS.G([c]));if($CLJS.E.h(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.aD("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));},$J=function(a){return $CLJS.TC(a,ZJ,function(b){return["\\",$CLJS.q.g(b)].join("")})},aK=function(a,b,c,d,e){this.tag=a;this.source=b;this.C=c;this.l=d;this.B=e;this.A=2230716170;this.M=139264},cK=function(a){var b=$CLJS.PC(a,bK),c=$CLJS.xg.h($CLJS.A,TJ(a)),
d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.th.h(b,$CLJS.No(d-$CLJS.D(b),UJ($CLJS.Fo(""))));d=$CLJS.th.h(c,$CLJS.No(d-$CLJS.D(c),UJ($CLJS.Fo(""))));a=$CLJS.sG.h(a,d);return $CLJS.xg.h(function(e){var f=$CLJS.ng(RJ,e);return $CLJS.p(f)?($CLJS.I(f,0,null),f=$CLJS.I(f,1,null),new aK(f,e,null,null,null)):e},a)},dK=function(a){return $CLJS.Ai(function(b){return"string"===typeof $CLJS.A(b)?new $CLJS.P(null,1,5,$CLJS.Q,[NJ(b)],null):b},$CLJS.G([VJ(function(b){return"string"===typeof b||
$CLJS.La($CLJS.Yn.g(b))},a)]))},fK=function(a,b,c){return $CLJS.xg.h(function(d){if(d instanceof aK){var e=$CLJS.Cg.j;var f=$CLJS.fh.g(d),g=$CLJS.K.h(a,f);f=$CLJS.Yn.g(g);g=$CLJS.xn.g(g);if($CLJS.p(f))try{var l=$J(eK.j(g,f,b))}catch(k){if(k instanceof Error)l=eK.j($CLJS.bt,f,b);else throw k;}else l=null;d=e.call($CLJS.Cg,d,$CLJS.Yn,l)}return d},c)},iK=function(a){a=NJ($CLJS.xg.h(function(b){return b instanceof aK?b:$CLJS.TC(b,gK,"")},a));return $CLJS.TC(a,hK,$CLJS.Mc)},PJ=function(a){return OJ($CLJS.A(jK(new $CLJS.P(null,
1,5,$CLJS.Q,[$CLJS.dr],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))))},SJ=function SJ(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Ue(d);return $CLJS.Jd(f,new $CLJS.Ld(null,function(){var g=e.length;g=d.index+(1>g?1:g);return g<=b.length?(g=b.substring(g),SJ.h?SJ.h(a,g):SJ.call(null,a,g)):null},null))},jK=function jK(a,b){if($CLJS.La($CLJS.y(a)))a=(0,$CLJS.EJ)(b);else{var d=$CLJS.K,e=d.h;var f=$CLJS.jg(a);b=$CLJS.he([$CLJS.Nc(a),b]);f=jK.h?jK.h(f,b):jK.call(null,
f,b);a=e.call(d,f,$CLJS.Nc(a))}return a};var eK=function(){var a=$CLJS.vh.g($CLJS.ie),b=$CLJS.vh.g($CLJS.ie),c=$CLJS.vh.g($CLJS.ie),d=$CLJS.vh.g($CLJS.ie),e=$CLJS.K.j($CLJS.ie,$CLJS.YD,$CLJS.Rh.v?$CLJS.Rh.v():$CLJS.Rh.call(null));return new $CLJS.JC($CLJS.Fg.h("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.yg.g(f)},e,a,b,c,d)}();$CLJS.KC(eK,$CLJS.Fy,function(a,b,c){return(0,$CLJS.MJ)(b).locale(c).format("MMMM D, YYYY")});
$CLJS.KC(eK,$CLJS.eq,function(a,b,c){a=(0,$CLJS.MJ)(b,"YYYY-MM").locale(c);return $CLJS.p(a.isValid())?a.format("MMMM, YYYY"):""});$CLJS.KC(eK,$CLJS.at,function(a,b,c){a=(0,$CLJS.MJ)(b,"[Q]Q-YYYY").locale(c);return $CLJS.p(a.isValid())?a.format("[Q]Q, YYYY"):""});$CLJS.KC(eK,$CLJS.Tp,function(a,b,c){b=$CLJS.QC(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.p($CLJS.p(a)?b:a)?[$CLJS.q.g(eK.j($CLJS.Fy,a,c))," - ",$CLJS.q.g(eK.j($CLJS.Fy,b,c))].join(""):""});
$CLJS.KC(eK,$CLJS.Nt,function(a,b){function c(d,e){return $CLJS.C($CLJS.og(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.bD("Today");if(c(/^thisweek$/,b))return $CLJS.bD("This Week");if(c(/^thismonth$/,b))return $CLJS.bD("This Month");if(c(/^thisquarter$/,b))return $CLJS.bD("This Quarter");if(c(/^thisyear$/,b))return $CLJS.bD("This Year");if(c(/^past1days$/,b))return $CLJS.bD("Yesterday");if(c(/^next1days$/,b))return $CLJS.bD("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.ae(YJ,
a);throw Error(["No matching clause: ",$CLJS.q.g(b)].join(""));});$CLJS.KC(eK,$CLJS.dv,function(a,b,c){function d(e,f){return $CLJS.Mc($CLJS.og(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.p(a))return eK.j($CLJS.Nt,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.p(a))return eK.j($CLJS.Fy,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.p(a)?eK.j($CLJS.Tp,a,c):eK.j($CLJS.Nt,b,c)});
$CLJS.KC(eK,$CLJS.bt,function(a,b){return $CLJS.Wc(b)?$CLJS.E.h($CLJS.D(b),1)?$CLJS.q.g($CLJS.A(b)):$CLJS.aD("{0} and {1}",$CLJS.G([$CLJS.Io($CLJS.jg(b)),$CLJS.Nc(b)])):$CLJS.q.g(b)});var ZJ=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,RJ=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,bK=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;$CLJS.h=aK.prototype;$CLJS.h.toString=function(){var a=$CLJS.Yn.g(this);return $CLJS.p(a)?a:this.source};$CLJS.h.ga=function(a,b){return this.N(null,b,null)};
$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fh,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qH,this.source],null)],null),this.l))};$CLJS.h.ia=function(){return new $CLJS.kf(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fh,$CLJS.qH],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};
$CLJS.h.ba=function(){return 2+$CLJS.D(this.l)};$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=-884441273^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.tag,b.tag)&&$CLJS.E.h(this.source,b.source)&&$CLJS.E.h(this.l,b.l)};
$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,2,[$CLJS.qH,null,$CLJS.fh,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new aK(this.tag,this.source,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.fd(this.l,b)}};
$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.fh,b):$CLJS.O.call(null,$CLJS.fh,b))?new aK(c,this.source,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.qH,b):$CLJS.O.call(null,$CLJS.qH,b))?new aK(this.tag,c,this.C,this.l,null):new aK(this.tag,this.source,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.gd($CLJS.fh,this.tag),new $CLJS.gd($CLJS.qH,this.source)],null),this.l))};
$CLJS.h.aa=function(a,b){return new aK(this.tag,this.source,b,this.l,this.B)};$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};
var gK=/\[\[.+\]\]/,hK=/\[\[(.+?)\]\]/,kK=function kK(a){switch(arguments.length){case 2:return kK.h(arguments[0],arguments[1]);case 3:return kK.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}},lK=["metabase","shared","parameters","parameters","substitute_tags"],mK=$CLJS.fa;lK[0]in mK||"undefined"==typeof mK.execScript||mK.execScript("var "+lK[0]);
for(var nK;lK.length&&(nK=lK.shift());)lK.length||void 0===kK?mK=mK[nK]&&mK[nK]!==Object.prototype[nK]?mK[nK]:mK[nK]={}:mK[nK]=kK;kK.h=function(a,b){return kK.j(a,b,"en")};kK.j=function(a,b,c){return $CLJS.p(a)?(b=$CLJS.fH(b),b=QJ(b),iK(dK(fK(b,c,cK(a))))):null};kK.J=3;module.exports={tag_names:function(a){a=$CLJS.ig($CLJS.xg.h($CLJS.Mc,TJ($CLJS.p(a)?a:"")));return $CLJS.Fh(a)},substitute_tags:kK};