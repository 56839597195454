var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.log.js");
'use strict';var PG,QG,RG,SG,TG,UG,VG,WG,YG,$G,aH,bH,dH,hH,iH,ZG,jH,mH,nH,oH,sH,tH,vH,wH,xH,yH,zH,AH,XG,EH,FH,GH,HH,IH,MH,NH,PH,RH,SH,UH,WH,gH,eH,VH,XH,YH,rH,ZH,$H,aI,bI,cI,dI,eI,lH,fI,gI,pH,hI,iI,jI,kI,lI;PG=function(){};QG=function(){};RG=function(){};SG=function(a,b){if(null!=a&&null!=a.cd)a=a.cd(a,b);else{var c=SG[$CLJS.sa(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=SG._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Oa("IEncodeClojure.-js-\x3eclj",a);}return a};
TG=function(a){return a instanceof Error?a.message:null};UG=function(a){a:for(var b=a;;)if(b=$CLJS.y(b))b=$CLJS.C(b);else break a;return a};VG=function(a){function b(d,e){d.push(e);return d}var c=[];return $CLJS.qd?$CLJS.qd(b,c,a):$CLJS.rd.call(null,b,c,a)};
WG=function(a,b){b=$CLJS.Yc(b)?$CLJS.uf.h($CLJS.ie,b):b;return(null!=b?b.M&4||$CLJS.gc===b.Ac||(b.M?0:$CLJS.Ma(QG,b)):$CLJS.Ma(QG,b))?$CLJS.Mb($CLJS.wd(a.g?a.g($CLJS.xf):a.call(null,$CLJS.xf),$CLJS.Kb($CLJS.Oc(b)),b)):$CLJS.wd(a.g?a.g($CLJS.Cg):a.call(null,$CLJS.Cg),$CLJS.Oc(b),b)};YG=function(a){var b=XG;return WG(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};
$G=function(a){var b=ZG;return WG(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};aH=function(a,b,c){var d=$CLJS.hd(a,b);if($CLJS.p(d)){var e=$CLJS.Cg.j;d=$CLJS.lb(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.Cg,a,b,c)}return a};
bH=function(a,b,c){if($CLJS.Ed(c)){var d=$CLJS.ae($CLJS.U,$CLJS.xg.h(a,c));return b.g?b.g(d):b.call(null,d)}return $CLJS.Pe(c)?(d=new $CLJS.gd(function(){var e=$CLJS.kb(c);return a.g?a.g(e):a.call(null,e)}(),function(){var e=$CLJS.lb(c);return a.g?a.g(e):a.call(null,e)}()),b.g?b.g(d):b.call(null,d)):$CLJS.Hh(c)?(d=UG($CLJS.xg.h(a,c)),b.g?b.g(d):b.call(null,d)):$CLJS.Yc(c)?(d=$CLJS.qd(function(e,f){return $CLJS.Gd.h(e,a.g?a.g(f):a.call(null,f))},c,c),b.g?b.g(d):b.call(null,d)):$CLJS.Uc(c)?(d=$CLJS.uf.h($CLJS.Oc(c),
$CLJS.xg.h(a,c)),b.g?b.g(d):b.call(null,d)):b.g?b.g(c):b.call(null,c)};dH=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yg.g(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return cH(function(c){return $CLJS.Xc(c)?$CLJS.uf.h($CLJS.ie,$CLJS.xg.h(b,c)):c},a)};
$CLJS.fH=function(a){var b=$CLJS.G([$CLJS.ah,!1]),c=$CLJS.je(b);c=$CLJS.K.h(c,$CLJS.ah);var d=$CLJS.p(c)?$CLJS.yg:$CLJS.q;return function g(f){return(null!=f?$CLJS.gc===f.Sd||(f.qc?0:$CLJS.Ma(RG,f)):$CLJS.Ma(RG,f))?SG(f,$CLJS.ae(eH,b)):$CLJS.Hh(f)?UG($CLJS.xg.h(g,f)):$CLJS.Pe(f)?new $CLJS.gd(g($CLJS.kb(f)),g($CLJS.lb(f))):$CLJS.Uc(f)?$CLJS.uf.j($CLJS.Oc(f),$CLJS.xg.g(g),f):$CLJS.Ka(f)?$CLJS.Mb($CLJS.qd(function(l,k){k=g(k);return $CLJS.Lb(l,k)},$CLJS.Kb($CLJS.Te),f)):$CLJS.Na(f)===Object?$CLJS.Mb($CLJS.qd(function(l,
k){return $CLJS.xf.j(l,d.g?d.g(k):d.call(null,k),g($CLJS.xa(f,k)))},$CLJS.Kb($CLJS.ie),$CLJS.wa(f))):f}(a)};hH=function(a,b,c){if($CLJS.p(!1)){var d=$CLJS.YE($CLJS.aF("metabase.mbql.normalize"));$CLJS.p(d)&&(a=$CLJS.K.h($CLJS.eF,a),b=new ("undefined"!==typeof $CLJS.ea&&"undefined"!==typeof $CLJS.hF&&"undefined"!==typeof $CLJS.hF.Yc?$CLJS.hF.Yc:gH)(a,b,"metabase.mbql.normalize"),$CLJS.p(c)&&(b.Fc=c),"undefined"!==typeof $CLJS.ea&&"undefined"!==typeof $CLJS.bF&&"undefined"!==typeof PG||d.de(b))}};
iH=function(a){var b=$CLJS.Wc(a);return b?(b=!$CLJS.Pe(a))?$CLJS.yh.h($CLJS.qm,$CLJS.km)($CLJS.A(a)):b:b};ZG=function(a){return $CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(a))?$CLJS.iE(a):a};jH=function(a,b){var c=iH(b);return $CLJS.p(c)?(b=ZG($CLJS.A(b)),$CLJS.Uc(a)?(a=$CLJS.ig(a),a.g?a.g(b):a.call(null,b)):$CLJS.E.h(a,b)):c};
mH=function(a){return $CLJS.K.j(new $CLJS.n(null,3,[$CLJS.bt,$CLJS.xd,$CLJS.xn,ZG,$CLJS.pv,ZG],null),a,function(b){var c=$CLJS.K,d=c.h;b=$CLJS.he([a,b]);b=kH.h?kH.h(b,lH):kH.call(null,b,lH);return d.call(c,b,a)})};
nH=function(a){a=$CLJS.uf.j($CLJS.ie,$CLJS.xg.g(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=ZG(c);var d=mH(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.E.h($CLJS.xn.g(a),$CLJS.qx)&&$CLJS.La($CLJS.pv.g(a))?$CLJS.Cg.j(a,$CLJS.pv,$CLJS.ku):a};
oH=function(a){return $CLJS.uf.j($CLJS.ie,$CLJS.xg.g(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.cD(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.Cg.j(nH(b),$CLJS.T,c)],null)}),a)};sH=function(a){return aH(aH($CLJS.qd(function(b,c){return aH(b,c,$CLJS.yg)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lj,$CLJS.$i,$CLJS.Xy,pH,$CLJS.qH,$CLJS.My],null)),rH,$CLJS.Ji.h(XG,kH)),$CLJS.Cq,dH)};tH=function(a){return $CLJS.dd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,a,null],null):a};vH=function(a){return uH.g(tH(a))};
wH=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.C(b);return $CLJS.gD($CLJS.uf.h(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.xg.h(uH,b)))};xH=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.C(b);b=$CLJS.A(c);c=$CLJS.C(c);return $CLJS.uf.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,vH(b)],null),$CLJS.xg.h(uH,c))};yH=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.p(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,vH(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
zH=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,vH(a)],null)};AH=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,uH.g(a)],null)};
XG=function(a){return BH(function(b){if($CLJS.Xc(b))return YG(b);if($CLJS.La(iH(b)))return b;try{return uH.g(b)}catch(f){if(f instanceof Error){var c=f;var d=$CLJS.YE($CLJS.aF("metabase.mbql.normalize")).isLoggable($CLJS.eF.g?$CLJS.eF.g($CLJS.Jh):$CLJS.eF.call(null,$CLJS.Jh));if($CLJS.p(d))if(d=$CLJS.G([$CLJS.bD("Invalid clause:"),b]),$CLJS.A(d)instanceof Error){var e=$CLJS.y(d);d=$CLJS.A(e);e=$CLJS.C(e);hH($CLJS.Jh,$CLJS.ae(CH,e),d)}else d=$CLJS.ae(CH,d),hH($CLJS.Jh,d,null);throw $CLJS.Go($CLJS.aD("Invalid MBQL clause: {0}",
$CLJS.G([TG(c)])),new $CLJS.n(null,1,[$CLJS.Wq,b],null),c);}throw f;}},a)};EH=function(a){return $CLJS.Ue($CLJS.ve(XG,DH(a)))};FH=function(a){for(;;)if($CLJS.p(iH(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.ke($CLJS.Ah.h(tH,a))};GH=function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.qx);return $CLJS.p(b)?$CLJS.xD.j(a,$CLJS.qx,uH):a};
HH=function(a){return $CLJS.uf.h($CLJS.ie,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);r=new $CLJS.P(null,2,5,$CLJS.Q,[r,GH(m)],null);l.add(r);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.Jd(new $CLJS.P(null,
2,5,$CLJS.Q,[l,GH(f)],null),d($CLJS.oc(e)))}return null}},null)}(a)}())};IH=function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.jq);return $CLJS.p(b)?$CLJS.xD.j(a,$CLJS.jq,HH):a};MH=function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.wx);b=$CLJS.La(a)?LH.g?LH.g(b):LH.call(null,b):b;return $CLJS.p(a)?IH(b):b};NH=function(a){return $CLJS.Uc(a)?$CLJS.y(a):null!=a};
PH=function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.Ux);var c=$CLJS.K.h(b,$CLJS.dr),d=$CLJS.K.h(b,$CLJS.Ks),e=$CLJS.K.h(b,$CLJS.wx);try{if($CLJS.p(d)){var f=$CLJS.je(b),g=$CLJS.K.h(f,$CLJS.Ks);var l=OH($CLJS.sh.h(f,$CLJS.Ks),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ux,$CLJS.Ks],null),g)}else l=b;var k=$CLJS.p(a)?$CLJS.xD.j(l,$CLJS.Ux,LH):l,m=$CLJS.p(c)?$CLJS.xD.j(k,$CLJS.dr,$CLJS.ui($CLJS.Ah,XG)):k,r=$CLJS.p(e)?$CLJS.xD.j(m,$CLJS.wx,IH):m;return XG(r)}catch(v){if(v instanceof Error)throw k=v,$CLJS.Go($CLJS.aD("Error canonicalizing query: {0}",
$CLJS.G([TG(k)])),new $CLJS.n(null,1,[$CLJS.Ux,a],null),k);throw v;}};
RH=function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.Ux),c=$CLJS.je(b);b=$CLJS.K.h(c,$CLJS.cw);c=$CLJS.K.h(c,$CLJS.xy);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.ig($CLJS.ud($CLJS.th,$CLJS.y($CLJS.Be($CLJS.Bo,function g(e,f){try{if($CLJS.Zc(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.O(l,$CLJS.px)){var k=$CLJS.F(f,1),m=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,k,$CLJS.sh.h(m,$CLJS.Pw)],null)],null)],null)}throw $CLJS.Z;
}catch(r){if(r instanceof Error){l=r;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw r;}else throw $CLJS.Z;}catch(r){if(r instanceof Error){l=r;if(l===$CLJS.Z)return $CLJS.ZC(g,e,f);throw l;}throw r;}}($CLJS.Te,b))))),QH.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ux,$CLJS.xy],null),$CLJS.Ji.h($CLJS.Ue,$CLJS.ui($CLJS.zh,b)))):a};
SH=function(a){try{return RH(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Go($CLJS.bD("Error performing whole query transformations"),new $CLJS.n(null,1,[$CLJS.Ux,a],null),b);}throw c;}};
UH=function(a,b){var c=$CLJS.uf.h($CLJS.Oc(a),function(){return function f(e){return new $CLJS.Ld(null,function(){for(var g=e;;)if(g=$CLJS.y(g)){if($CLJS.ad(g)){var l=$CLJS.Rb(g),k=$CLJS.D(l),m=$CLJS.Od(k);return function(){for(var x=0;;)if(x<k){var z=$CLJS.Pc(l,x),H=$CLJS.I(z,0,null);z=$CLJS.I(z,1,null);var N=$CLJS.Gd.h(b,H);z=TH.h?TH.h(z,N):TH.call(null,z,N);null!=z&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[H,z],null));x+=1}else return!0}()?$CLJS.Rd($CLJS.Td(m),f($CLJS.Sb(g))):$CLJS.Rd($CLJS.Td(m),null)}var r=
$CLJS.A(g),v=$CLJS.I(r,0,null),w=$CLJS.I(r,1,null);r=function(){var x=w,z=$CLJS.Gd.h(b,v);return TH.h?TH.h(x,z):TH.call(null,x,z)}();if(null!=r)return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[v,r],null),f($CLJS.oc(g)));g=$CLJS.oc(g)}else return null},null)}(a)}());return $CLJS.y(c)?c:null};WH=function(a,b){a=$CLJS.Ah.h(function(c){var d=$CLJS.Gd.h(b,VH);return TH.h?TH.h(c,d):TH.call(null,c,d)},a);return $CLJS.p($CLJS.se($CLJS.Bo,a))?a:null};
gH=class{constructor(a,b,c,d,e){this.Fc=void 0;this.reset(a||$CLJS.ma,b,c,d,e)}reset(a,b,c,d,e){this.Ed=d||Date.now();this.Uc=a;this.yd=b;this.vd=c;this.Fc=void 0;this.Cd="number"===typeof e?e:0}Dd(a){this.Uc=a}};eH=function eH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return eH.o(0<c.length?new $CLJS.lc(c.slice(0),0,null):null)};
eH.o=function(a){a=a instanceof $CLJS.lc&&0===a.H?a.m:VG(a);if(!$CLJS.Jo(a.length))throw Error(["No value supplied for key: ",$CLJS.q.g($CLJS.Nc(a))].join(""));return $CLJS.he(a)};eH.J=0;eH.I=function(a){return this.o($CLJS.y(a))};
var OH=function OH(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.C(e);if(f){e=$CLJS.Cg.j;var g=$CLJS.K.h(a,b);c=OH.j?OH.j(g,f,c):OH.call(null,g,f,c);a=e.call($CLJS.Cg,a,b,c)}else a=$CLJS.Cg.j(a,b,c);return a},QH=function QH(a){switch(arguments.length){case 3:return QH.j(arguments[0],arguments[1],arguments[2]);case 4:return QH.O(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return QH.ea(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return QH.qa(arguments[0],
arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return QH.o(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5],new $CLJS.lc(c.slice(6),0,null))}};QH.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.C(d))a=$CLJS.Cg.j(a,b,QH.j($CLJS.K.h(a,b),d,c));else{d=$CLJS.Cg.j;var e=$CLJS.K.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.Cg,a,b,c)}return a};
QH.O=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.C(e))a=$CLJS.Cg.j(a,b,QH.O($CLJS.K.h(a,b),e,c,d));else{e=$CLJS.Cg.j;var f=$CLJS.K.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.Cg,a,b,c)}return a};QH.ea=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.C(f))a=$CLJS.Cg.j(a,b,QH.ea($CLJS.K.h(a,b),f,c,d,e));else{f=$CLJS.Cg.j;var g=$CLJS.K.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.Cg,a,b,c)}return a};
QH.qa=function(a,b,c,d,e,f){var g=$CLJS.y(b);b=$CLJS.A(g);if(g=$CLJS.C(g))a=$CLJS.Cg.j(a,b,QH.qa($CLJS.K.h(a,b),g,c,d,e,f));else{g=$CLJS.Cg.j;var l=$CLJS.K.h(a,b);c=c.O?c.O(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.Cg,a,b,c)}return a};QH.o=function(a,b,c,d,e,f,g){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.C(l))?$CLJS.Cg.j(a,b,$CLJS.ee(QH,$CLJS.K.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.Cg.j(a,b,$CLJS.ee(c,$CLJS.K.h(a,b),d,e,f,$CLJS.G([g])))};
QH.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);var d=$CLJS.C(c);c=$CLJS.A(d);var e=$CLJS.C(d);d=$CLJS.A(e);var f=$CLJS.C(e);e=$CLJS.A(f);var g=$CLJS.C(f);f=$CLJS.A(g);g=$CLJS.C(g);return this.o(b,a,c,d,e,f,g)};QH.J=6;
var BH=function BH(a,b){return bH($CLJS.ui(BH,a),$CLJS.xd,a.g?a.g(b):a.call(null,b))},cH=function cH(a,b){return bH($CLJS.ui(cH,a),a,b)},CH=function CH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return CH.o(0<c.length?new $CLJS.lc(c.slice(0),0,null):null)};CH.o=function(a){return 1<$CLJS.D(a)?$CLJS.Ue(a):$CLJS.A(a)};CH.J=0;CH.I=function(a){return this.o($CLJS.y(a))};VH=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683);
XH=new $CLJS.M(null,"descending","descending",-24766135);$CLJS.qH=new $CLJS.M(null,"source","source",-433931539);YH=new $CLJS.M(null,"field-id","field-id",-353751335);rH=new $CLJS.M(null,"field_ref","field_ref",-1985640334);ZH=new $CLJS.M(null,"datetime-field","datetime-field",21731696);$H=new $CLJS.M(null,"joined-field","joined-field",-2048778268);aI=new $CLJS.M(null,"field-literal","field-literal",-1295883554);bI=new $CLJS.M(null,"viz-settings","viz-settings",256055379);
cI=new $CLJS.M(null,"form","form",-1624062471);dI=new $CLJS.M(null,"special-fn","special-fn",1290649344);eI=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941);lH=new $CLJS.M(null,"ignore-path","ignore-path",944069061);fI=new $CLJS.M(null,"named","named",-422393479);gI=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);pH=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);hI=new $CLJS.M(null,"path","path",-188191168);
iI=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738);jI=new $CLJS.M(null,"rows","rows",850049680);kI=new $CLJS.M(null,"as","as",1148689641);lI=new $CLJS.M(null,"ascending","ascending",-988350486);var mI,nI=$CLJS.vh.g($CLJS.ie),oI=$CLJS.vh.g($CLJS.ie),pI=$CLJS.vh.g($CLJS.ie),qI=$CLJS.vh.g($CLJS.ie),rI=$CLJS.K.j($CLJS.ie,$CLJS.YD,$CLJS.Rh.v?$CLJS.Rh.v():$CLJS.Rh.call(null));mI=new $CLJS.JC($CLJS.Fg.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ji.h(ZG,$CLJS.A),rI,nI,oI,pI,qI);$CLJS.KC(mI,$CLJS.xp,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xp,a instanceof $CLJS.M?$CLJS.cD(a):a],null)});
$CLJS.KC(mI,gI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?$CLJS.Gd.h(mI.g(new $CLJS.P(null,3,5,$CLJS.Q,[gI,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[gI,kH.h?kH.h(b,lH):kH.call(null,b,lH),ZG(c)],null)});
$CLJS.KC(mI,$CLJS.px,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=kH.h?kH.h(a,lH):kH.call(null,a,lH);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,function(){var d=$CLJS.p($CLJS.yy.g(c))?$CLJS.xD.j(c,$CLJS.yy,$CLJS.yg):c;d=$CLJS.p($CLJS.Pw.g(c))?$CLJS.xD.j(d,$CLJS.Pw,$CLJS.yg):d;return $CLJS.p($CLJS.pr.g(c))?$CLJS.xD.j(d,$CLJS.pr,function(e){return $CLJS.p($CLJS.Kr.g(e))?$CLJS.xD.j(e,$CLJS.Kr,$CLJS.yg):e}):d}()],null)});
$CLJS.KC(mI,aI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[aI,b instanceof $CLJS.M?$CLJS.cD(b):b,$CLJS.yg.g(a)],null)});$CLJS.KC(mI,ZH,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?new $CLJS.P(null,4,5,$CLJS.Q,[ZH,kH.h?kH.h(b,lH):kH.call(null,b,lH),kI,ZG(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[ZH,kH.h?kH.h(b,lH):kH.call(null,b,lH),ZG(c)],null)});
$CLJS.KC(mI,$CLJS.Jw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.p(a)?$CLJS.Gd.h(mI.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jw,b,c,d],null)),kH.h?kH.h(a,lH):kH.call(null,a,lH)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jw,kH.h?kH.h(b,lH):kH.call(null,b,lH),$CLJS.dd(c)?c:ZG(c),ZG(d)],null)});
$CLJS.KC(mI,$CLJS.iv,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iv,b,ZG(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iv,$CLJS.Cs],null)});$CLJS.KC(mI,$CLJS.sq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sq,b,ZG(a)],null)});
$CLJS.KC(mI,$CLJS.Ar,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,kH.h?kH.h(b,lH):kH.call(null,b,lH),c,ZG(a)],null)});$CLJS.KC(mI,$CLJS.wv,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wv,kH.h?kH.h(b,lH):kH.call(null,b,lH),c,ZG(a)],null)});
$CLJS.KC(mI,$CLJS.yv,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yv,kH.h?kH.h(b,lH):kH.call(null,b,lH),ZG(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yv,kH.h?kH.h(b,lH):kH.call(null,b,lH)],null)});
$CLJS.KC(mI,$CLJS.Qw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Qw,kH.h?kH.h(b,lH):kH.call(null,b,lH),ZG(c),ZG(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qw,kH.h?kH.h(b,lH):kH.call(null,b,lH),ZG(c)],null)});
$CLJS.KC(mI,$CLJS.Sw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sw,kH.h?kH.h(b,lH):kH.call(null,b,lH),kH.h?kH.h(c,lH):kH.call(null,c,lH),ZG(a)],null)});$CLJS.KC(mI,$CLJS.Yn,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,b,a],null)});
$CLJS.KC(mI,$CLJS.bt,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.C(b);return $CLJS.uf.j(new $CLJS.P(null,1,5,$CLJS.Q,[ZG(a)],null),$CLJS.xg.g(function(c){return kH.h?kH.h(c,lH):kH.call(null,c,lH)}),b)});
var sI=function sI(a){if($CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(a))){var c=ZG(a);var d=new $CLJS.R(null,new $CLJS.n(null,18,[$CLJS.qy,null,$CLJS.jt,null,$CLJS.sy,null,$CLJS.kt,null,$CLJS.qw,null,$CLJS.Tw,null,$CLJS.$q,null,$CLJS.kw,null,$CLJS.gu,null,$CLJS.Es,null,$CLJS.jv,null,$CLJS.wu,null,$CLJS.Sp,null,$CLJS.xu,null,$CLJS.gx,null,$CLJS.lx,null,$CLJS.cu,null,$CLJS.ly,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.p(c)?c:$CLJS.p(iH(a))?(a=$CLJS.A(a),sI.g?sI.g(a):sI.call(null,
a)):null},uI=new $CLJS.n(null,8,[$CLJS.xn,ZG,$CLJS.wx,function(a){a=$G(a);return $CLJS.y($CLJS.jq.g(a))?$CLJS.xD.j(a,$CLJS.jq,oH):a},$CLJS.Ux,new $CLJS.n(null,6,[$CLJS.Ds,function tI(a){if($CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(a)))return ZG(a);if($CLJS.p(jH(fI,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return $CLJS.uf.h(new $CLJS.P(null,2,5,$CLJS.Q,[fI,tI.g?tI.g(a):tI.call(null,a)],null),c)}return $CLJS.p($CLJS.p(iH(a))?sI($CLJS.Mc(a)):null)?$CLJS.Ah.h(tI,a):kH.h?kH.h(a,
lH):kH.call(null,a,lH)},$CLJS.Fu,function(a){return $CLJS.uf.h($CLJS.ie,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);r=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cD(r),kH.h?kH.h(m,lH):kH.call(null,m,lH)],null);l.add(r);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),
null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cD(l),kH.h?kH.h(f,lH):kH.call(null,f,lH)],null),d($CLJS.oc(e)))}return null}},null)}(a)}())},$CLJS.es,function(a){return $CLJS.Ue(function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k);m=$CLJS.p(iH(m))?mI.g(m):$CLJS.Hd(mI.g($CLJS.Hd(m)));l.add(m);
k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}l=$CLJS.A(e);return $CLJS.Jd($CLJS.p(iH(l))?mI.g(l):$CLJS.Hd(mI.g($CLJS.Hd(l))),d($CLJS.oc(e)))}return null}},null)}(a)}())},$CLJS.Fv,function(a){a=$G(a);a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.wx);if($CLJS.p(b))return a=$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.wx,$CLJS.Ux],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wx],null),a=kH.h?kH.h(a,b):kH.call(null,a,b),$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.Ux,$CLJS.wx],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ux],null);return kH.h?kH.h(a,b):kH.call(null,a,b)},$CLJS.Ks,new $CLJS.n(null,1,[VH,sH],null),$CLJS.hy,new $CLJS.n(null,1,[VH,function(a){a=kH.h?kH.h(a,$CLJS.Ux):kH.call(null,a,$CLJS.Ux);var b=$CLJS.je(a),c=$CLJS.K.h(b,$CLJS.Kr),d=$CLJS.K.h(b,$CLJS.xy);a=$CLJS.K.h(b,$CLJS.iw);b=$CLJS.p(c)?$CLJS.xD.j(b,$CLJS.Kr,ZG):b;d=$CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(d))?$CLJS.xD.j(b,$CLJS.xy,ZG):b;return $CLJS.p(a)?$CLJS.xD.j(d,$CLJS.iw,$CLJS.cD):d}],null)],null),
$CLJS.Lh,new $CLJS.n(null,1,[$CLJS.tu,$CLJS.xd],null),$CLJS.dr,new $CLJS.n(null,1,[VH,function(a){var b=$CLJS.je(a),c=$CLJS.K.h(b,$CLJS.xn);a=$CLJS.K.h(b,$CLJS.lq);var d=$CLJS.K.h(b,$CLJS.Ny);b=$CLJS.p(d)?$CLJS.xD.j(b,$CLJS.Ny,$CLJS.cD):b;c=$CLJS.p(c)?$CLJS.xD.j(b,$CLJS.xn,ZG):b;return $CLJS.p(a)?$CLJS.xD.j(c,$CLJS.lq,function(e){return kH.h?kH.h(e,lH):kH.call(null,e,lH)}):c}],null),$CLJS.Mx,function(a){return null==a?null:ZG(a)},$CLJS.Ks,new $CLJS.n(null,1,[VH,sH],null),bI,ZG],null),kH=function kH(a){for(var c=
[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return kH.o(arguments[0],1<c.length?new $CLJS.lc(c.slice(1),0,null):null)};
kH.o=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Ue(b);b=$CLJS.y(c)?$CLJS.qd($CLJS.K,uI,c):null;try{return $CLJS.mm(b)?b.g?b.g(a):b.call(null,a):$CLJS.Yc(a)?a:$CLJS.Xc(a)?$CLJS.uf.h($CLJS.ie,function(){return function g(f){return new $CLJS.Ld(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.ad(l)){var k=$CLJS.Rb(l),m=$CLJS.D(k),r=$CLJS.Od(m);a:for(var v=0;;)if(v<m){var w=$CLJS.Pc(k,v),x=$CLJS.I(w,0,null);w=$CLJS.I(w,1,null);x=ZG(x);
x=new $CLJS.P(null,2,5,$CLJS.Q,[x,kH.o(w,$CLJS.G([$CLJS.Gd.h($CLJS.Ue(c),x)]))],null);r.add(x);v+=1}else{k=!0;break a}return k?$CLJS.Rd($CLJS.Td(r),g($CLJS.Sb(l))):$CLJS.Rd($CLJS.Td(r),null)}k=$CLJS.A(l);r=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);r=ZG(r);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[r,kH.o(k,$CLJS.G([$CLJS.Gd.h($CLJS.Ue(c),r)]))],null),g($CLJS.oc(l)))}return null}},null)}(a)}()):$CLJS.p(iH(a))?mI.g(a):$CLJS.Wc(a)?$CLJS.Ah.h(function(e){return kH.o(e,$CLJS.G([$CLJS.Gd.h($CLJS.Ue(c),VH)]))},
a):a}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Go($CLJS.aD("Error normalizing form: {0}",$CLJS.G([TG(d)])),new $CLJS.n(null,3,[cI,a,hI,c,dI,b],null),d);}throw e;}};kH.J=1;kH.I=function(a){var b=$CLJS.A(a);a=$CLJS.C(a);return this.o(b,a)};
var uH=function(){var a=$CLJS.vh.g($CLJS.ie),b=$CLJS.vh.g($CLJS.ie),c=$CLJS.vh.g($CLJS.ie),d=$CLJS.vh.g($CLJS.ie),e=$CLJS.K.j($CLJS.ie,$CLJS.YD,$CLJS.Rh.v?$CLJS.Rh.v():$CLJS.Rh.call(null));return new $CLJS.JC($CLJS.Fg.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.p(iH(f))?$CLJS.A(f):null},e,a,b,c,d)}();$CLJS.KC(uH,$CLJS.bt,function(a){return a});
$CLJS.KC(uH,$CLJS.px,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.p(jH($CLJS.px,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return uH.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,c,$CLJS.ke($CLJS.Ko($CLJS.G([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,$CLJS.ke(a)],null)});$CLJS.KC(uH,YH,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.p(iH(a))?uH.g(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,a,null],null)});
$CLJS.KC(uH,aI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,new $CLJS.n(null,1,[$CLJS.yy,a],null)],null)});$CLJS.KC(uH,iI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=vH(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=vH(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,a,$CLJS.Cg.j(c,$CLJS.Vu,b)],null)});
$CLJS.KC(uH,$H,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=vH(a);return $CLJS.ce($CLJS.CD,a,$CLJS.Cg,$CLJS.G([$CLJS.Os,b]))});
$CLJS.KC(uH,ZH,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=vH(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.je(c);c=$CLJS.K.h(c,$CLJS.yy);return $CLJS.La(c)||$CLJS.zz.h(c,b)?$CLJS.ce($CLJS.CD,a,$CLJS.Cg,$CLJS.G([$CLJS.Pw,b])):a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),uH.g(new $CLJS.P(null,3,5,$CLJS.Q,[ZH,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.q.g(b)].join(""));}});$CLJS.KC(uH,gI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=vH(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,$CLJS.Cg.j(e,$CLJS.pr,$CLJS.Ko($CLJS.G([new $CLJS.n(null,1,[$CLJS.Kr,c],null),$CLJS.p(d)?$CLJS.he([c,d]):null,a])))],null)});
for(var vI=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qs,$CLJS.xq,$CLJS.bu],null)),wI=null,xI=0,yI=0;;)if(yI<xI){var zI=wI.ja(null,yI);$CLJS.KC(uH,zI,function(){return function(a){return wH(a)}}(vI,wI,xI,yI,zI));yI+=1}else{var AI=$CLJS.y(vI);if(AI){var BI=AI;if($CLJS.ad(BI)){var CI=$CLJS.Rb(BI),DI=$CLJS.Sb(BI),EI=CI,FI=$CLJS.D(CI);vI=DI;wI=EI;xI=FI}else{var GI=$CLJS.A(BI);$CLJS.KC(uH,GI,function(){return function(a){return wH(a)}}(vI,wI,xI,yI,GI,BI,AI));vI=$CLJS.C(BI);wI=null;xI=0}yI=0}else break}
$CLJS.KC(uH,$CLJS.gy,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.C(a);a=$CLJS.A(b);var c=$CLJS.C(b);b=$CLJS.A(c);c=$CLJS.C(c);return $CLJS.uf.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gy,vH(a),vH(b)],null),c)});$CLJS.KC(uH,$CLJS.Jw,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.C(a);a=$CLJS.A(b);b=$CLJS.C(b);var c=vH(a);a=$CLJS.p($CLJS.dD($CLJS.px,a))?$CLJS.CD.o(c,$CLJS.sh,$CLJS.G([$CLJS.Pw])):c;return $CLJS.uf.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jw,a],null),b)});
for(var HI=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.iu,$CLJS.Au,$CLJS.Xw,$CLJS.Tu,$CLJS.Su,$CLJS.Ct,$CLJS.zr,$CLJS.wq,$CLJS.Nu,$CLJS.pp,$CLJS.nu,$CLJS.rv,$CLJS.Hv,$CLJS.lt,$CLJS.Pq],null)),II=null,JI=0,KI=0;;)if(KI<JI){var LI=II.ja(null,KI);$CLJS.KC(uH,LI,function(){return function(a){return xH(a)}}(HI,II,JI,KI,LI));KI+=1}else{var MI=$CLJS.y(HI);if(MI){var NI=MI;if($CLJS.ad(NI)){var OI=$CLJS.Rb(NI),PI=$CLJS.Sb(NI),QI=OI,RI=$CLJS.D(OI);HI=PI;II=QI;JI=RI}else{var SI=$CLJS.A(NI);$CLJS.KC(uH,SI,function(){return function(a){return xH(a)}}(HI,
II,JI,KI,SI,NI,MI));HI=$CLJS.C(NI);II=null;JI=0}KI=0}else break}$CLJS.KC(uH,jI,function(){return null});$CLJS.KC(uH,$CLJS.xw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xw,uH.g(b),a],null)});
$CLJS.KC(uH,fI,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.C(a);var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);var d=$CLJS.C(c);c=uH.g;var e=$CLJS.Q;b=uH.g(b);d=$CLJS.I(d,0,null);d=$CLJS.je(d);a=!1===$CLJS.K.h(d,eI)?new $CLJS.n(null,1,[$CLJS.T,a],null):new $CLJS.n(null,1,[$CLJS.lw,a],null);return c.call(uH,new $CLJS.P(null,3,5,e,[$CLJS.xw,b,a],null))});
for(var TI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lx,$CLJS.Tw],null)),UI=null,VI=0,WI=0;;)if(WI<VI){var XI=UI.ja(null,WI);$CLJS.KC(uH,XI,function(){return function(a){return yH(a)}}(TI,UI,VI,WI,XI));WI+=1}else{var YI=$CLJS.y(TI);if(YI){var ZI=YI;if($CLJS.ad(ZI)){var $I=$CLJS.Rb(ZI),aJ=$CLJS.Sb(ZI),bJ=$I,cJ=$CLJS.D($I);TI=aJ;UI=bJ;VI=cJ}else{var dJ=$CLJS.A(ZI);$CLJS.KC(uH,dJ,function(){return function(a){return yH(a)}}(TI,UI,VI,WI,dJ,ZI,YI));TI=$CLJS.C(ZI);UI=null;VI=0}WI=0}else break}
for(var eJ=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.cu,$CLJS.Gx,$CLJS.gu,$CLJS.sy,$CLJS.ly,$CLJS.qy,$CLJS.gx,$CLJS.Sp,$CLJS.wu],null)),fJ=null,gJ=0,hJ=0;;)if(hJ<gJ){var iJ=fJ.ja(null,hJ);$CLJS.KC(uH,iJ,function(){return function(a){return zH(a)}}(eJ,fJ,gJ,hJ,iJ));hJ+=1}else{var jJ=$CLJS.y(eJ);if(jJ){var kJ=jJ;if($CLJS.ad(kJ)){var lJ=$CLJS.Rb(kJ),mJ=$CLJS.Sb(kJ),nJ=lJ,oJ=$CLJS.D(lJ);eJ=mJ;fJ=nJ;gJ=oJ}else{var pJ=$CLJS.A(kJ);$CLJS.KC(uH,pJ,function(){return function(a){return zH(a)}}(eJ,fJ,gJ,hJ,
pJ,kJ,jJ));eJ=$CLJS.C(kJ);fJ=null;gJ=0}hJ=0}else break}$CLJS.KC(uH,$CLJS.Es,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,vH(b),a],null)});
for(var qJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xu,$CLJS.kt],null)),rJ=null,sJ=0,tJ=0;;)if(tJ<sJ){var uJ=rJ.ja(null,tJ);$CLJS.KC(uH,uJ,function(){return function(a){return AH(a)}}(qJ,rJ,sJ,tJ,uJ));tJ+=1}else{var vJ=$CLJS.y(qJ);if(vJ){var wJ=vJ;if($CLJS.ad(wJ)){var xJ=$CLJS.Rb(wJ),yJ=$CLJS.Sb(wJ),zJ=xJ,AJ=$CLJS.D(xJ);qJ=yJ;rJ=zJ;sJ=AJ}else{var BJ=$CLJS.A(wJ);$CLJS.KC(uH,BJ,function(){return function(a){return AH(a)}}(qJ,rJ,sJ,tJ,BJ,wJ,vJ));qJ=$CLJS.C(wJ);rJ=null;sJ=0}tJ=0}else break}
$CLJS.KC(uH,$CLJS.$q,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,uH.g(b),uH.g(a)],null)});
$CLJS.KC(uH,$CLJS.Bw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?$CLJS.Gd.h(uH.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bw,b],null)),kH.o(a,$CLJS.G([lH]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bw,$CLJS.Ue(function(){return function e(d){return new $CLJS.Ld(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.ad(f)){var g=$CLJS.Rb(f),l=$CLJS.D(g),k=$CLJS.Od(l);a:for(var m=0;;)if(m<l){var r=$CLJS.Pc(g,m),v=$CLJS.I(r,0,null);r=$CLJS.I(r,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[uH.g(v),uH.g(r)],null);k.add(v);m+=1}else{g=!0;break a}return g?$CLJS.Rd($CLJS.Td(k),e($CLJS.Sb(f))):$CLJS.Rd($CLJS.Td(k),null)}g=$CLJS.A(f);k=$CLJS.I(g,0,null);g=$CLJS.I(g,1,null);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[uH.g(k),uH.g(g)],null),e($CLJS.oc(f)))}return null}},null)}(b)}())],null)});
var DH=function DH(a){return function f(d,e){try{if($CLJS.p(function(){var w=$CLJS.YC($CLJS.Hh);return w.g?w.g(e):w.call(null,e)}()))return f(d,$CLJS.Ue(e));throw $CLJS.Z;}catch(w){if(w instanceof Error)if(d=w,d===$CLJS.Z)try{if($CLJS.p(function(){var x=$CLJS.YC($CLJS.qm);return x.g?x.g(e):x.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.Zc(e)&&2<=$CLJS.D(e))try{var g=
$CLJS.NC(e,0,2);if($CLJS.Zc(g)&&2===$CLJS.D(g))try{var l=$CLJS.F(g,0);if($CLJS.p($CLJS.xh.h($CLJS.qm,$CLJS.ue(new $CLJS.R(null,new $CLJS.n(null,5,[$CLJS.jt,null,$CLJS.qw,null,$CLJS.kw,null,$CLJS.jv,null,fI,null],null),null)))(l)))try{var k=$CLJS.F(g,1);if($CLJS.p(sI(k)))return $CLJS.Ue($CLJS.ud($CLJS.th,$CLJS.xg.h(DH,a)));throw $CLJS.Z;}catch(z){if(z instanceof Error){var m=z;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error){m=z;if(m===$CLJS.Z)throw $CLJS.Z;
throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error){m=z;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error)if(m=z,m===$CLJS.Z)try{if($CLJS.Zc(e)&&1<=$CLJS.D(e))try{var r=$CLJS.NC(e,0,1);if($CLJS.Zc(r)&&1===$CLJS.D(r))try{if($CLJS.F(r,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw m;else throw z;}else throw d;else throw x;}else throw d;else throw w;}}($CLJS.Te,a)},CJ=function CJ(a){return function f(d,e){try{var g=$CLJS.YC($CLJS.Hh);var l=g.g?g.g(e):g.call(null,e);if($CLJS.p(l))return f(d,$CLJS.Ue(e));throw $CLJS.Z;}catch(z){if(z instanceof Error)if(l=z,l===$CLJS.Z)try{if($CLJS.Zc(e)&&2===$CLJS.D(e))try{var k=
$CLJS.F(e,1);if($CLJS.O(k,$CLJS.Bt)){var m=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,m],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{k=$CLJS.F(e,1);if($CLJS.O(k,$CLJS.Ps))return m=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,m],null));throw $CLJS.Z;}catch(N){if(N instanceof Error){var r=N;if(r===$CLJS.Z)try{k=$CLJS.F(e,1);if($CLJS.O(k,lI))return m=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,m],null));throw $CLJS.Z;}catch(Y){if(Y instanceof
Error)if(l=Y,l===$CLJS.Z)try{k=$CLJS.F(e,1);if($CLJS.O(k,XH))return m=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,m],null));throw $CLJS.Z;}catch(la){if(la instanceof Error)if(k=la,k===$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.O(v,lI))return m=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,m],null));throw $CLJS.Z;}catch(va){if(va instanceof Error)if(k=va,k===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.O(v,XH))return m=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,m],null));throw $CLJS.Z;
}catch(Ja){if(Ja instanceof Error)if(k=Ja,k===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.O(v,$CLJS.Bt))return m=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,vH(m)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error)if(k=Ea,k===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.O(v,$CLJS.Ps))return m=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,vH(m)],null);throw $CLJS.Z;}catch(cb){if(cb instanceof Error){m=cb;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw cb;}else throw k;else throw Ea;}else throw k;else throw Ja;
}else throw k;else throw va;}else throw k;else throw la;}else throw l;else throw Y;}else throw r;}else throw N;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.Zc(e)&&0<=$CLJS.D(e))try{var w=$CLJS.NC(e,0,0);if($CLJS.Zc(w)&&0===$CLJS.D(w))try{var x=$CLJS.OC(e,0);if($CLJS.E.h(x,a))return $CLJS.Ue($CLJS.Ii.g($CLJS.xg.h(CJ,a)));throw $CLJS.Z;}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)throw $CLJS.Z;throw r;}throw N;}else throw $CLJS.Z;
}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)throw $CLJS.Z;throw r;}throw N;}else throw $CLJS.Z;}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)return $CLJS.$C(f,d,e);throw r;}throw N;}else throw l;else throw H;}else throw l;else throw z;}}($CLJS.Te,a)},LH=$CLJS.Ji.h(XG,function(a){var b=NH($CLJS.Ds.g(a))?$CLJS.xD.j(a,$CLJS.Ds,EH):a;b=NH($CLJS.cw.g(a))?$CLJS.xD.j(b,$CLJS.cw,FH):b;b=NH($CLJS.xy.g(a))?$CLJS.xD.j(b,$CLJS.xy,$CLJS.ui($CLJS.Ah,tH)):b;b=NH($CLJS.es.g(a))?$CLJS.xD.j(b,$CLJS.es,
CJ):b;return NH($CLJS.Fv.g(a))?$CLJS.xD.j(b,$CLJS.Fv,MH):b}),DJ=new $CLJS.n(null,3,[$CLJS.wx,$CLJS.xd,$CLJS.Ux,new $CLJS.n(null,2,[$CLJS.Fv,function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.wx);if($CLJS.p(b))return a=$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.wx,$CLJS.Ux],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wx],null),a=TH.h?TH.h(a,b):TH.call(null,a,b),$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.Ux,$CLJS.wx],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ux],null);return TH.h?TH.h(a,b):TH.call(null,a,b)},
$CLJS.hy,new $CLJS.n(null,1,[VH,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ux],null);return TH.h?TH.h(a,b):TH.call(null,a,b)}],null)],null),bI,$CLJS.xd],null),TH=function TH(a){switch(arguments.length){case 1:return TH.g(arguments[0]);case 2:return TH.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};TH.g=function(a){return TH.h(a,$CLJS.Te)};
TH.h=function(a,b){try{var c=$CLJS.y(b)?$CLJS.qd($CLJS.K,DJ,b):null;return $CLJS.mm(c)?c.g?c.g(a):c.call(null,a):$CLJS.Yc(a)?a:$CLJS.Xc(a)?UH(a,b):$CLJS.Wc(a)?WH(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Go("Error removing empty clauses from form.",new $CLJS.n(null,2,[cI,a,hI,b],null),d);throw d;}};TH.J=2;
$CLJS.EJ=function(){var a=$CLJS.Ji.o(TH,SH,PH,$CLJS.G([kH]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Go($CLJS.aD("Error normalizing query: {0}",$CLJS.G([TG(c)])),new $CLJS.n(null,1,[$CLJS.Ux,b],null),c);}throw d;}}}();